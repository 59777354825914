<template>
    <div class="flex justify-between items-center panzoom-exclude">
        <div class="flex">
            <input
                @blur="$emit('onBlur', label)"
                v-model="label.title"
                class="font-poppins fw-400 text-black bg-transparent w-12 mr-2"
                :class="titleClass"
                type="text"
            >
            <input
                @blur="$emit('onBlur', label)"
                v-model="label.short_title"
                class="font-poppins fw-700 fs-8 text-black bg-transparent w-4"
                :class="shortTitleClass"
                type="text"
            >
        </div>
        <img
            v-if="canDelete(project.cans)"
            src="@/assets/images/icons/close_circle_outline.svg"
            alt="icon" class="settings-icon cursor-pointer"
             @click.prevent="$emit('onClickDelete', label.id)"
        />
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete, canRead } = usePermission()
</script>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'Label',
    props: {
        label: Object,
        titleClass: {
            type: String,
            default: 'fs-8'
        },
        shortTitleClass: {
            type: String,
            default: 'fs-8'
        },
    },
    computed: {
        ...mapGetters({
            project: 'project/project'
        }),
    }
}
</script>

<style scoped>
</style>
