var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative font-poppins h-full",class:{ 'w-12': !_vm.isExpanded }},[_c('div',{staticClass:"mt-3",staticStyle:{"height":"calc(100%)"}},[_c('div',{staticClass:"flex items-center h-3",class:{'gap-x-8': _vm.renderLimit !== 0}},[_c('div',{staticClass:"i-w-200 pl-2 flex items-center justify-between"},[_c(_setup.InputSelectWithVmodel,{attrs:{"label":"Select Module","selected":_vm.module.id,"isDisabled":!_setup.canEdit(_vm.project.cans),"items":_vm.association.getModules(_vm.activeRoadmapData),"label-classes":'text-secondary-two fs-14 fw-500',"classes":'px-1 rounded-sm fs-8 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown w-32'},on:{"change":_vm.updateWidgetSettings},model:{value:(_vm.column.associated_module_id),callback:function ($$v) {_vm.$set(_vm.column, "associated_module_id", $$v)},expression:"column.associated_module_id"}}),_c('div',{staticClass:"fs-16 fw-800 cursor-pointer",on:{"click":_vm.handleBarClick}},[_vm._v("+")])],1)]),_c('div',{staticClass:"bg-primary-two business-widget-padding",staticStyle:{"height":"calc(100% - 31px)","margin-top":"7px"}},_vm._l((_vm.association.getFilterCollectionWithAssociatedModules()),function(item,index){return _c('div',{key:item.id,staticClass:"flex parent",class:[_vm.getGap(_vm.flatData, item.filterCollection, index), _vm.renderLimit !== 0 ? 'gap-x-4' : '']},[(item.associatedModules.length)?[_vm._l((item.associatedModules),function(column,cIndex){return [(cIndex <= _vm.renderLimit)?_c('div',{key:column.id},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"px-2 rounded-sm flex relative items-center border-color-transparent cursor-pointer i-border-1 border-solid i-h-20 i-w-200 shadow-two",class:{'border-primary-one': _vm.activeData.id === column.id},style:({
                      background: _vm.getDotsBackgroundColor(column, _vm.association.getModule(_vm.association.getModules(_vm.activeRoadmapData),_vm.selectedModuleId)),
                    }),on:{"click":function($event){_setup.canEdit(_vm.project.cans) && _vm.setActiveScenarioCollection(
                        _vm.association.getModule(
                          _vm.association.getModules(_vm.activeRoadmapData),
                          _vm.selectedModuleId
                        ),
                        column
                    )}}},[_c('span',{staticClass:"truncate text-white ls-5 font-poppins fs-10 fw-400"},[_vm._v(" "+_vm._s(column.index_code + " " + column.title)+" ")])]),(_vm.showDropdown && _vm.renderLimit !== 0)?_c('div',{on:{"click":function($event){_setup.canEdit(_vm.project.cans) && _vm.removeAssociation(item.filterCollection, column)}}},[_c('XCircle',{staticClass:"w-3 h-3 cursor-pointer text-secondary-five i-ml-2"})],1):_vm._e()]),(_vm.showDropdown)?_c('div',{staticClass:"flex i-gap-9"},_vm._l((_vm.association.getSampleValues(
                      column.properties,
                      _vm.widget.parameters
                    )),function(value,vIndex){return _c('div',{key:vIndex},[_c('div',{staticClass:"flex i-gap-5"},_vm._l((5),function(property,propertyIndex){return _c(_setup.Parameter,{key:propertyIndex,attrs:{"custom-margin":"m-0","background":_vm.association.getParameterBg(value, _vm.widget.definition_style,propertyIndex)}})}),1)])}),0):_vm._e()])]):_vm._e()]})]:_vm._e(),(_vm.association.getAddModuleDisplayPermission(item.associatedModules.length,_vm.renderLimit,_vm.showDropdown))?_c('div',{staticClass:"px-1 shadow-two bg-primary-three rounded-sm flex relative items-center border-solid border-color-transparent cursor-pointer border i-border-1 i-h-20 i-w-200"},[_c(_setup.InputSelect,{attrs:{"label":"Select Module Content","items":_vm.moduleLastLabels,"label-classes":'text-secondary-two fs-14 fw-500',"classes":'p-0 -i-ml-5 i-w-200 rounded-sm fs-10 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown'},on:{"change":function($event){return _vm.onSelectAssociation($event, item.filterCollection)}}})],1):_vm._e()],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }