<template>
    <div class="flex">
        <div>
            <input 
                v-model="column.title" 
                class="w-20 fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude" 
                type="text" 
                @input="updateWidgetSettings(widget)"
            >
            <div class="bg-primary-two mt-1 business-widget-padding">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <div
                        @click="updateParameterValue(item, column.id, getParameterValue(item, column), handleParameterValueUpdate)"
                        style="height: 20px"
                        class="w-20 flex justify-center items-center rounded-sm border border-secondary-five fw-400 fs-12 text-secondary-one cursor-pointer" 
                        :class="[getTheGap(flatData, item, index)]"
                        :style="{backgroundColor: getParameterBg(getParameterValue(item, column), column.definition_style)}"
                    >
                        <p class="w-full truncate text-center">{{ getParameterTextDefinition(getParameterValue(item, column), column.definition_style) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="column.showNoteComment">
            <div class="fs-12 mt-2 invisible">+</div>
            <div class="bg-primary-two i-mt-5 business-widget-padding">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <NoteComment
                        :class="[getTheGap(flatData, item, index)]"
                        :index="index"
                        :note="getNote(column, item.id, column.id)"
                        :bg-color="getParameterBg(getParameterValue(item, column), column.definition_style)"
                        :comment-count="item.commentCount"
                        :collection-id="item.id"
                        @onType="updateWidgetSettings(widget)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { getTheGap, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services'
import useBusiness from '../useBusiness'
import useBusinessSetting from '../useBusinessSetting'
import NoteComment from './NoteComment';
import useDecisionParamUpdate from './useDecisionParamUpdate';

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})
const emit = defineEmits(['onUpdateParameterValue'])

const { getNote } =useBusiness()
const { updateWidgetSettings, getDataByIsolatedModules } = useBusinessSetting()
const { updateParameterValue, getParameterTextDefinition } = useDecisionParamUpdate()
const handleParameterValueUpdate = () => emit('onUpdateParameterValue')
</script>