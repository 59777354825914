<template>
  <div v-if="isShow"
       class="cntxt-menu fs-14 text-secondary-one shadow-two rounded bg-primary-three py-5 z-10 fixed fw-400 capitalize">
    <div class="ct-li flex justify-between items-center relative cursor-pointer i-mb-5 px-5" @click="setType(8)">
      <span class="w-full h-full block">System Content</span>
    </div>

    <div class="ct-li flex justify-between items-center relative cursor-pointer py-2 px-5 i-mb-5" @click="setType(1)">
      <span class="w-full h-full block">Metrics</span>
      <!--      <img src="/images/icons/build/arrow_side_inactive.svg" class="ml-5" />-->
      <!--      <div class="cntxt-menu cntxt-submenu shadow-two rounded bg-primary-three p-5 z-10 hidden absolute">-->
      <!--        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="setType(1)">Default</span>-->
      <!--        <span class="ct-li flex justify-between items-center cursor-pointer i-mb-15" @click="setType(2)">Compare</span>-->
      <!--        <span class="ct-li flex justify-between items-center cursor-pointer" @click="setType(3)">Definition</span>-->
      <!--      </div>-->
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer i-mb-15 px-5" @click="setType(4)">
      <span class="w-full h-full block">People</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15" @click="setType(5)">
      <span class="w-full h-full block">Dynamic Field</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15" @click="setType(6)">
      <span class="w-full h-full block">Logic Builder</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15" @click="setType(7)">
      <span class="w-full h-full block">Timeline</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer px-5 i-mb-15" @click="setType(9)">
      <span class="w-full h-full block">Associated Content</span>
    </div>
    <div class="ct-li flex justify-between items-center relative cursor-pointer px-5" @click="setType(10)">
      <span class="w-full h-full block">Coordinate</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuComponent',
  data: () => ({
    isShow: false
  }),
  methods: {
    toggleMenu() {
      this.isShow = !this.isShow
    },
    setType(type) {
      this.$emit("updateSettings", type);
      this.isShow = false;
    },
    hideMenu() {
      this.isShow = false;
    }
  }
};
</script>

<style>
.cntxt-menu {
  width: 185px;
}

.cntxt-menu .ct-li {
  opacity: .6;
  transition: background-color 0.2s ease-in;
}

.cntxt-menu .ct-li:hover {
  opacity: 1;
}

.cntxt-submenu {
  top: 0;
  left: 185px;
}

.ct-li:hover .cntxt-submenu {
  display: block;
}
</style>
