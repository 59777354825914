<template>
    <div class="flex">
        <div>
            <div class="flex items-center mt-1.5">
                <input
                    v-model="column.title"
                    class="fw-400 fs-8 bg-transparent text-secondary-one text-center panzoom-exclude"
                    type="text"
                    style="width: 90px;"
                    @input="updateWidgetSettings(widget)"
                >
                <div class="flex justify-between" :class="[definitionWidths[definitionWidthIndex]]">
                    <div @click="decreaseDefinitionWidth" :class="{'hidden': definitionWidthIndex<1}" class="fs-16 fw-800 cursor-pointer">-</div>
                    <div @click="increaseDefinitionWidth" :class="{'hidden': definitionWidthIndex>1}" class="fs-16 fw-800 cursor-pointer">+</div>
                </div>
            </div>
            <div class="bg-primary-two business-widget-padding i-mt-1">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <div class="flex items-center gap-x-3 h-5" :class="[getTheGap(flatData, item, index)]">
                        <div @click="updateParameterValue(item, column.id, getParameterValue(item, column), handleParameterValueUpdate)" class="flex gap-x-1.5 cursor-pointer click-bounce">
                            <span
                                v-for="(i, serialIndex) in 5" :key="serialIndex"
                                :index="index"
                                class="w-2.5 h-2.5 rounded-sm shadow-two bg-C9"
                                :style="{'background': getParameterBg(getParameterValue(item, column), widget.definition_style, serialIndex)}"
                            />
                        </div>

                        <p :class="[definitionWidths[definitionWidthIndex]]" class="fs-12 truncate">{{ getDefinition(getParameterValue(item, column), column, definitionSets) }}</p>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="column.showNoteComment">
            <div class="fs-12 mt-2 invisible">+</div>
            <div class="bg-primary-two i-mt-5 business-widget-padding">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <NoteComment
                        :class="[getTheGap(flatData, item, index)]"
                        :index="index"
                        :note="getNote(column, item.id, column.id)"
                        :bg-color="getParameterBg(getParameterValue(item, column), widget.definition_style)"
                        :comment-count="item.commentCount"
                        :collection-id="item.id"
                        @onType="updateWidgetSettings(widget)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import store from '@/store'
import NoteComment from './NoteComment';
import useBusiness from '../useBusiness'
import useBusinessSetting from '../useBusinessSetting'
import useSetting from '@/components/roadmap/canvas/widgets/useSetting'
import { prevIndex, nextIndex  } from '@/plugins/lodash'
import { getDefinition } from '@/store/services/traits/definition';
import { getTheGap, getParameterTextDefinition, getParameterValue} from '@/store/modules/roadmap/services'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})
const emit = defineEmits(['onUpdateParameterValue'])

const definitionWidthIndex = ref(0)
const definitionWidths = ['w-20', 'w-32', 'w-64']

const definitionSets = computed(() => store.getters['definition/definitionSets'])

const { getNote } =useBusiness()
const { getParameterBg } =useSetting()
const { updateWidgetSettings, getDataByIsolatedModules, updateParameterValue } =useBusinessSetting()

const handleParameterValueUpdate = () => emit('onUpdateParameterValue')
const increaseDefinitionWidth = () => definitionWidthIndex.value = nextIndex(definitionWidthIndex.value, definitionWidths.length)
const decreaseDefinitionWidth = () => definitionWidthIndex.value = prevIndex(definitionWidthIndex.value, definitionWidths.length)
</script>
