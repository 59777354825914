<template>
  <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins">
    <SettingsHeader
        class="mb-5"
        title="Dynamic Field Configuration "
        @onDelete="deleteWidget"
    />

    <div class="flex flex-col gap-5">
      <InputSelect
          v-model="dynamicField"
          :items="[{id: '', title: 'Dynamic Field'}]"
      />
      <InputSelect
          v-model="defaultStyle"
          :items="[{id: '', title: 'Default Style'}]"
      />
    </div>

    <hr class="border-secondary-four mt-5">

    <div class="space-y-2 mt-5">
      <div v-for="(parameter, pIndex) in widget.parameters" :key="pIndex">
          <SettingsAccordion
              :title="`Parameter ${pIndex + 1}`"
              v-model="parameter.active"
          >
              <template v-slot:action>
                  <img src="/images/icons/brainstormV2/plus_circle_outline.svg" v-if="(pIndex + 1) === widget.parameters.length" alt="icon" class="cursor-pointer i-w-14 i-h-14" @click="newParameter" />
                  <img src="/images/icons/brainstormV2/close.svg" alt="icon" class="i-w-14 i-h-14 ml-2 cursor-pointer" @click="deleteParameter(pIndex)" />
              </template>
              <div class="flex flex-col gap-2">
                <InputSelect
                    v-model="parameter.property_id"
                    label="Select Property Set"
                    :items="module.properties"
                />
                <InputSelect
                    v-if="parameter.property_id"
                    v-model="parameter.id"
                    label="Select Parameter"
                    :items="getPropertyCollection(parameter)"
                    @change="updateWidgetSettings()"
                />
              </div>
          </SettingsAccordion>
      </div>
    </div>

    <DefinitionColorPicker
      :widget="widget"
      :updateWidgetSettings="updateWidgetSettings"
    />
  </div>
</template>

<script>
import { deleteWidget } from '@/store/modules/roadmap/services';
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import InputSelect from '@/elements/inputs/InputSelect';
import DefinitionColorPicker from '@/components/roadmap/DefinitionColorPicker.vue'

export default {
  name: "DynamicFieldSettings",
  components: {
    InputSelect,
    SettingsHeader,
    SettingsAccordion,
    DefinitionColorPicker
  },
  props: ['widget', 'layout', 'module', 'moduleHeight'],
  created() {
    if(this.widget && (!this.widget.parameters || this.widget.parameters.length <= 0)) {
      this.widget.parameters = [{ property_id: null, id: null }];
    }
  },
  data() {
    return {
      dynamicField: '',
      defaultStyle: '',
      rangeDefinitions: ['0-2', '2-4', '4-6', '6-8', '8-10']
    }
  },
  methods: {
    updateWidgetSettings() {
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    newParameter() {
      this.widget.parameters.push({ property_id: null, id: null });
    },
    deleteParameter(index) {
      this.widget.parameters.splice(index, 1);
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      if(this.widget.parameters.length <= 0) {
        this.widget.parameters = [{ property_id: null, id: null }];
      }
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    getPropertyCollection(parameter) {
      let properties = this.module.properties.find(item => item.id === parameter.property_id)
      if(properties) {
        return properties.property_collections.filter(param => !param.option || param.option == 1)
      }
      return []
    }
  }
}
</script>

<style scoped>
.top-399 {
  top: 399px;
}
.l-80{
  left: 80px;
}
.settings-widget {
  width: 250px;
  min-height: 624px;
  max-height: 724px;
  overflow-y: auto;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
.range-section {
  width: 47px;
  height: 14px;
}
.definition-section {
  width: 47px;
}
.value-identifier {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: #8D8D8D;
}
.param-dropdown:focus {
  border: 1px solid #C9C9C9;
}
.delete-icon {
  width: 17px;
  height: 15px;
}
</style>
