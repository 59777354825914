<template>
    <div class="relative">
        <div
            style="right:-7px"
            :style="{height: getHeight(), top: getTop()}" 
            class="absolute h-5 border-r border-white"
        >
        </div>  
        <Datepicker
            v-model="date"
            ref="programaticOpen" 
            class="w-20 h-5 fs-10 bg-primary-three rounded-sm shadow-one text-secondary-one"
            :input-class="'assess-input-picker w-20 fw-400 fs-10-imp force-opaque text-center pt-1'"
            placeholder="dd-mm-yyyy"
            @selected="setPickerDate"
            :format="'dd.MM.yyyy'"
        />
        
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
    name: 'DatePickerButton',
    props: ['item', 'date', 'dateType', 'collectionItemIndex', 'collectionLength'],
    components: {
        Datepicker
    },
    methods: {
        setPickerDate(e) {
            this.item[this.dateType] = new Date(e).toISOString().split('T')[0]
            this.$emit('onDateChange')
        },
        getHeight() {
            if(this.collectionItemIndex+1 == this.collectionLength) return '20px'
            return '32px'
        },
        getTop() {
            if(this.collectionItemIndex+1 != this.collectionLength) return '-4px'
            return ''
        }
    }
}
</script>

<style>
.fs-10-imp {
    font-size: 10px !important;
}
.force-opaque::placeholder {
  opacity: 1;
  font-size: 10px;
}
</style>
