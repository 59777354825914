<template>
  <SettingsAccordion
      class="mt-5"
      title="Color Style"
      v-model="isOpen"
    >
    <div class="mt-6">
      <div class="flex i-mb-15">
        <h4 class="fs-10 fw-500 text-primary-one w-32">Levels</h4>
        <h4 class="fs-10 fw-500 text-primary-one px-1">Avg. Value</h4>
      </div>
      <div class="space-y-2 panzoom-exclude" v-click-outside="hidePicker">
        <div class="absolute color-picker-div" v-if="showPicker" style="left: -9px">
          <div class="flex flex-col items-center justify-center mt-3">
            <color-panel v-model="color" @change="onColorChange"></color-panel> </div>
        </div>
        <div v-for="(definitionStyle, index) in widget.definition_style" :key="index" class="flex justify-between items-center">
          <div @click.prevent="openColorPicker(definitionStyle, index)" class="range-number fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center cursor-pointer"
          :style="{'background': definitionStyle.bg}">{{ definitionStyle.range }}</div>
          <div class="flex justify-between">
            <div class="value-identifier shadow-two" :style="{'background': definitionStyle.bg}"></div>
            <div class="value-identifier shadow-two i-ml-5" :style="{'background': getMinRange(definitionStyle.range) >= 2 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two i-ml-5" :style="{'background': getMinRange(definitionStyle.range) >= 4 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two i-ml-5" :style="{'background': getMinRange(definitionStyle.range) >= 6 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two i-ml-5" :style="{'background': getMinRange(definitionStyle.range) >= 8 ? definitionStyle.bg : ''}"></div>
          </div>
          <div class="range-text fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center" :style="{'background': definitionStyle.bg}">
            <input type="text" class="bg-transparent definition-section text-center w-full" v-model="definitionStyle.definition" />
          </div>
        </div>
      </div>
    </div>
  </SettingsAccordion>
</template>

<script>
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
export default {
    name: 'DefinitionColorPicker',
    components: {
      SettingsAccordion
    },
    data: () => ({
        isOpen: 'true',
        color: '',
        showPicker: false,
        current: {},
        currentIndex: 0,
    }),
    props: {
        widget: {
            required: true,
            type: Object
        },
        updateWidgetSettings: {
            type: Function,
            required: true
        }
    },
    methods: {
        onColorChange(color)  {
            this.current.bg = color
        },
        openColorPicker(obj, index) {
            this.currentIndex = index
            this.current = obj
            this.showPicker = ! this.showPicker
        },
        getMinRange(rangeText) {
            let rangeArray = rangeText.split("-");
            if(rangeArray[0]) {
                return rangeArray[0];
            }
            return 0;
        },
        hidePicker() {
            if(this.showPicker) {
                this.updateWidgetSettings();
            }
            this.showPicker = false;
        },
    }
}
</script>

<style scoped>
.value-identifier {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  background: #C9C9C9;
}

.range-number {
  width: 50px;
  height: 20px;
}
.range-text {
  width: 80px;
  height: 20px;
}

</style>