<template>
    <div
        v-if="parameterId"
        style="margin-left: 5px; margin-top: 4px;"
        class="panzoom-exclude flex flex-wrap gap-x-3"
    >
        <div @click="$emit('onSelectDate', {date: item.start_date, type: 0, key: 'start_date', index: collectionItemIndex})" class="w-20 h-5 fs-12 bg-primary-three rounded-sm shadow-one cursor-pointer flex items-center justify-center">
            <span v-if="item.start_date" class="text-primary-one">{{ formatDate( new Date(item.start_date)) }}</span>
            <span v-else class="text-primary-one">dd-mm-yy</span>
        </div>
        <div @click="$emit('onSelectDate', {date: item.end_date, type: 1, key: 'end_date', index: collectionItemIndex})" class="w-20 h-5 fs-12 bg-primary-three rounded-sm shadow-one cursor-pointer flex items-center justify-center">
            <span v-if="item.end_date" class="text-primary-one">{{ formatDate(new Date(item.end_date)) }}</span>
            <span v-else class="text-primary-one">dd-mm-yy</span>
        </div>
        <div class="w-20 h-5 fs-12 bg-primary-three rounded-sm shadow-one cursor-pointer flex items-center justify-center">
            <input
                class="w-7 bg-transparent text-right pr-1"
                type="number"
                :disabled="!item.start_date"
                @input="e => $emit('onChangeDuration', {item: item, value: e.target.value})"
                :value="daysLabel"
            >
            <span v-if="daysLabel">{{ Number(daysLabel) > 1 ? 'days' : 'day' }}</span>
        </div>
        <StatusButton
            :collectionItemIndex="collectionItemIndex"
            :collectionLength="collectionLength"
            :bgColor="definition.bg"
            :text="definition.definition"
            :classes="['cursor-pointer', 'click-bounce']"
            :button="true"
            @updateParamValue="updateParamValue"
        />
        <StatusButton
            :collectionItemIndex="collectionItemIndex"
            :collectionLength="collectionLength"
            :bgColor="definition.bg"
            :text="sampleValeToText"
            :classes="['cursor-pointer', 'click-bounce']"
            :button="true"
            @updateParamValue="updateParamValue"
        />
        <template v-for="(assign, index) in assigns">
            <AssignButton
                :key="index"
                v-if="assign.name"
                :widgetId="widget.id"
                :index="index"
                :text="assign.name"
                :isAssigned="true"
                :collection-id="item.id"
                :bgColor="assign.color"
                :collectionItemIndex="collectionItemIndex"
                :collectionLength="collectionLength"
                :assignCount="assigns.length"
                @onClickAssign="onClickAssign"
            />
        </template>
    </div>
</template>

<script>
import AssignButton from '@/components/roadmap/canvas/widgets/timeline/component/AssignButton.vue'
import StatusButton from '@/components/roadmap/canvas/widgets/timeline/component/StatusButton.vue'
import DatePickerButton from '@/components/roadmap/canvas/widgets/timeline/component/DatePickerButton.vue'
import { mapGetters, mapActions } from 'vuex';
export default {
    name: 'TimelineRow',
    components: {
        AssignButton,
        StatusButton,
        DatePickerButton
    },

    props: {
        item: Object,
        widget: Object,
        collectionItemIndex:Number,
        collectionLength:Number,
        formatDate:Function,
    },

    computed: {
        ...mapGetters({
            parameterId: 'roadmap/parameterId',
            activeRoadmapData: 'roadmap/programmatic',
            activeData: "programmatic/activeScenarioCollection",
        }),
        assigns() {
            return this.widget.assigns
        },
        daysLabel() {
            let day = this.$lodash.dateDefer(this.item.start_date, this.item.end_date);
            if(!day) return '';

            return day
        },
        parameterId() {
            if(this.widget.parameters?.length){
                return this.widget.parameters[0].id
            }
            return null
        },
        parameter() {
            if(this.parameterId && this.item?.properties) {
                return this.item.properties.find(i => i.property_collection_id == this.parameterId)
            }
            return null
        },
        sampleValue() {
            if(this.parameter) return this.parameter.sample_value
            return null
        },
        sampleValeToText() {
            if( this.sampleValue == 0) return '0%'
            if(this.sampleValue) return (this.sampleValue * 10)+'%'
            return ''
        },
        definition() {
            let definition = {}
            if(this.widget.definition_style && this.sampleValue == 0) return this.widget.definition_style[0]
            if(this.widget.definition_style && this.sampleValue) {
                let sampleValue = this.sampleValue - .000001;
                this.widget.definition_style.map(def => {
                    if(sampleValue >= parseInt(def.range.split('-')[0]) && sampleValue <= parseInt(def.range.split('-')[1])) {
                        definition = def
                    }
                })
            }
            return definition
        },
    },

    methods: {
        ...mapActions({
            addAssign: 'timeline/addAssign'
        }),
        onClickAssign({index, status}) {
            this.addAssign({
                roadmap_widget_id: this.widget.id,
                assign_index: index,
                status: status,
                scenario_collection_id: this.item.id
            })
        },

        updateParamValue() {
          let obj = {};
          obj.sc_id = this.item.id;
          obj.scenario_id = this.activeRoadmapData.scenario_id;
          obj.project_id = this.$route.params.id;
          obj.property_collection_id = this.parameterId;
          obj.id = this.parameterId;
          obj.module_id = this.item.module_id;
          let value = this.sampleValue + 2;
          obj.value = Math.floor(value);
          if(value > 10) {
            obj.value = 1;
          }
          let itemParameter = this.item.properties.find(data => parseInt(data.property_collection_id) === parseInt(this.parameterId));
          if(itemParameter) {
            itemParameter.sample_value = obj.value;
          } else {
            this.item.properties.push({
              scenario_collection_id: this.item.id,
              sample_value: obj.value,
              property_collection_id: this.parameterId
            });
          }

          this.$store.dispatch("programmatic/updatePropertyItem", obj).then(response => {
            if(response && response.data && response.data.data && response.data.data.id) {
              if(!itemParameter) {
                itemParameter= this.item.properties.find(data => parseInt(data.property_collection_id) === parseInt(this.parameterId));
                if(itemParameter) {
                  itemParameter.id = response.data.data.id;
                }
              }
            }
            this.$emit("loadAModuleData");
          });

          if(this.activeData && this.activeData.id == this.item.id) {
            let dataObj = {
              property_collection_id: this.parameterId,
              sample_value: obj.value
            };
            this.$store.commit("programmatic/updateActiveItemParameterValue", dataObj);
          }
        }
    }

}
</script>
