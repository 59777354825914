
<template>
     <div>
        <div class="fs-16 mt-1 fw-800 flex" :class="[noteWidthIndex < 1 ? 'justify-center' : 'justify-between pl-2']">
            <input 
                v-model="column.title" 
                class="w-20 fw-400 fs-8 bg-transparent mt-3 text-secondary-one px-1 panzoom-exclude" 
                type="text"
                :class="{'hidden': noteWidthIndex<1}"
                @input="updateWidgetSettings(widget)"
            >
            <div @click="decreaseNoteWidth" :class="{'hidden': noteWidthIndex<1}" class="fs-16 fw-800 cursor-pointer">-</div>
            <div @click="increaseNoteWidth" :class="{'hidden': noteWidthIndex>1}" class="fs-16 fw-800 cursor-pointer">+</div>
        </div>
        <div class="bg-primary-two i-mt-3 business-widget-padding">
            <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                <div class="flex items-center gap-x-3 h-5" :class="[getTheGap(flatData, item, index)]">
                    <Note
                        :note="getNote(column, item.id)"
                        @onType="updateWidgetSettings(widget)"
                        :width-class="noteWidths[noteWidthIndex]"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { set, ref } from 'vue';
import { get } from 'lodash';
import { prevIndex, nextIndex  } from '@/plugins/lodash'
import useBusinessSetting from '../useBusinessSetting'
import Note from './Note.vue'
import { getTheGap, getParameterTextDefinition, getParameterValue, getParameterBg} from '@/store/modules/roadmap/services'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})
const { updateWidgetSettings, getDataByIsolatedModules } =useBusinessSetting()

const getNote = (column, collectionId) => {
    if(column.lastLevelNotes.constructor == Array){
        set(column, 'lastLevelNotes', {})
    }
    const note = get(column, `lastLevelNotes[${collectionId}]`)
    if(!note) {
        column.lastLevelNotes[collectionId] = {
            note: '' 
        }
    }
    return get(column, `lastLevelNotes[${collectionId}]`)
}


const noteWidthIndex = ref(0)
const noteWidths = ['w-0', 'w-64', 'w-96']

const increaseNoteWidth = () => noteWidthIndex.value = nextIndex(noteWidthIndex.value, noteWidths.length)
const decreaseNoteWidth = () => noteWidthIndex.value = prevIndex(noteWidthIndex.value, noteWidths.length)

</script>