<template>
  <div>
    <div class="relative i-border-1 border-secondary-two rounded w-full i-mt-30 i-h-50">
      <input
          type="text"
          class="focus:outline-none py-2 font-poppins ws-search-box bg-transparent text-secondary-one placeholder-secondary-two i-h-50"
          placeholder="Search"
      />
    </div>

    <div class="mt-8 brainstorm-navigation overflow-y-auto pb-10">
      <div class="flex flex-col pl-10 pr-10"  v-for="(module, mIndex) in getModuleData" :key="mIndex" :class="{'mt-8': mIndex > 0}">
        <div class="relative flex flex-row shadow-one i-h-50 w-full rounded">
          <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three cursor-pointer"
              @click="toggleModuleSelection(module.id, mIndex)"
              @dblclick="moduleIsolation(module.id)"
              :class="{'shadow-two hover:bg-rgb-primary-four': module.id === selectedModule}"
              :style="{'background': selectedModule === module.id ? getModuleBg(module) : ''}">
            <div class="flex items-center pr-left-content w-full bld-level-w">
              <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title"
                    :class="{'text-primary-three': module.id === selectedModule}">{{ module.title }}</span>
            </div>
            <div
                class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModule(module)">
              <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': module.expanded}">
            </div>
          </div>
        </div>

        <!--level 1-->
        <div class="flex flex-col mt-8" v-for="(l1, l1Index) in getData(module.collections, module.id)" :key="l1Index" v-if="module.expanded">
          <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(module.id, l1)">
            <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-solid border-transparent"
                 :style="{'background': getBg(module, l1, 1)}"
                 :class="{'border-primary-one': l1.id === activeScenarioCollection.id}">
              <div class="flex items-center pr-left-content w-full bld-level-w cursor-pointer" @click="setActiveData(l1)">
                <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                  {{ getIndexId(module, 1) }}{{ getL1Indexing(module, l1Index, 1) }} {{ l1.title }}
                </span>
              </div>
              <div
                  class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleExpand(l1)">
                <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l1.expanded}">
              </div>
            </div>
          </div>

          <!--level 2-->
          <div class="flex flex-col mt-8" v-for="(l2, l2Index) in getData(l1.child, module.id)" :key="l2Index" v-if="l1.expanded">
            <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(module.id, l2, [l1.id])">
              <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-solid border-transparent"
                   :class="{'border-primary-one': l2.id === activeScenarioCollection.id}"
                   :style="{'background': getBg(module, l2, 2)}">
                <div class="flex items-center pr-left-content w-full cursor-pointer" @click="setActiveData(l2)">
                  <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                    {{ getIndexId(module, 2) }}{{ getSubLevelIndexing(module, l2Index, getL1Indexing(module, l1Index, 1), 2) }} {{ l2.title }}
                  </span>
                </div>
                <div
                    class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleExpand(l2)">
                  <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l2.expanded}">
                </div>
              </div>
            </div>

            <!--level 3-->
            <div class="flex flex-col mt-8" v-for="(l3, l3Index) in getData(l2.child, module.id)" :key="l3Index" v-if="l2.expanded">
              <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(module.id, l3, [l1.id, l2.id])">
                <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-solid border-transparent"
                     :class="{'border-primary-one': l3.id === activeScenarioCollection.id}"
                     :style="{'background': getBg(module, l3, 3)}">
                  <div class="flex items-center pr-left-content w-full cursor-pointer" @click="setActiveData(l3)">
                    <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                      {{ getIndexId(module, 3) }}{{ getSubLevelIndexing(module, l3Index, getSubLevelIndexing(module, l2Index, getL1Indexing(module, l1Index, 1), 2), 3) }} {{ l3.title }}
                    </span>
                  </div>
                  <div
                      class="absolute pr-hover-div w-10 i-h-50 cursor-pointer items-center justify-center rounded-br rounded-tr" @click="toggleModuleExpand(l3)">
                    <img src="/images/icons/chevron_down_black.svg" alt="icon" :class="{'rotate-180': l3.expanded}">
                  </div>
                </div>
              </div>

              <!--level 4-->
              <div class="mt-8" v-for="(l4, l4Index) in getData(l3.child, module.id)" :key="l4Index" v-if="l3.expanded">
                <div class="relative flex flex-row shadow-one i-h-50 w-full rounded" @dblclick="isolation(module.id, l4, [l1.id, l2.id, l3.id])">
                  <div class="flex items-center bld-level-w i-h-50 bg-primary-three hover:bg-secondary-five relative pr-item rounded w-full bg-primary-three i-border-1 border-solid border-transparent"
                       :class="{'border-primary-one': l4.id === activeScenarioCollection.id}"
                       :style="{'background': getBg(module, l4, 4)}">
                    <div class="flex items-center pr-left-content w-full cursor-pointer" @click="setActiveData(l4)">
                      <span class="font-poppins fw-600 text-primary-one fs-14 px-4 ellipse prg-lft-title text-primary-three">
                        {{ getIndexId(module, 4) }}{{ getSubLevelIndexing(module, l4Index, getSubLevelIndexing(module, l3Index, getSubLevelIndexing(module, l2Index, getL1Indexing(module, l1Index, 1), 2), 3), 4) }} {{ l4.title }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ScenarioItemList",
  props: ['isolatedModules', 'isolatedModuleId'],
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
      colorCodes: "global/colorCodes",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
      selectedModule: "programmatic/selectedModule",
      projectData: "project/project",
    }),
    getModuleData() {
      if(this.activeRoadmapData && this.activeRoadmapData.id) {
        if(this.isolatedModuleId) {
          return this.activeRoadmapData.modules.filter(module => module.id === this.isolatedModuleId);
        }
        return this.activeRoadmapData.modules;
      }
      return [];
    },
  },
  methods: {
    toggleModule(item) {
      item.expanded = !item.expanded;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getModuleBg(module) {
      let collectionSettings = JSON.parse(module.collection_settings);
      if(collectionSettings.collections[0].custom_bg) {
        return collectionSettings.collections[0].custom_bg;
      }
      let colorCodes = this.getLevelWiseColorCode(1);
      return colorCodes[collectionSettings.collections[0].color];
    },
    getBg(module, item, level) {
      if(item.background_color) {
        return item.background_color;
      } else {
        let collectionSettings = JSON.parse(module.collection_settings);
        if(collectionSettings && collectionSettings.collections.length > 0) {
          if(collectionSettings.collections[level].custom_bg) {
            return collectionSettings.collections[level].custom_bg;
          }
          let colorCodes = this.getLevelWiseColorCode(level);
          return colorCodes[collectionSettings.collections[level].color];
        }
      }
      return '';
    },
    getL1Indexing(module, index, selfLevel) {
      let settings = JSON.parse(module.collection_settings);
      if(settings) {
        let indexing = '';
        let collection = settings.collections[selfLevel];
        if(settings.index_number) {
          indexing = parseFloat(collection.index_number) + index;
        }
        return indexing;
      }
      return '';
    },
    getSubLevelIndexing(module, index, currentData, selfLevel) {
      let settings = JSON.parse(module.collection_settings);
      return currentData + '.' + (index + 1);
    },
    getIndexId(module, selfLevel) {
      let settings = JSON.parse(module.collection_settings);
      if(settings) {
        if(settings.index_id) {
          let collection = settings.collections[selfLevel];
          return collection.index_id;
        }
      }
    },
    toggleModuleExpand(item) {
      item.expanded = !item.expanded;
    },
    setActiveModule() {
      if(this.activeBrainstormData && this.activeBrainstormData.modules && this.activeBrainstormData.modules.length > 0) {
        this.toggleModuleSelection(this.activeBrainstormData.modules[0].id, 0);
      }
    },
    toggleModuleSelection(id, index) {
      this.$store.dispatch("programmatic/toggleModuleSelection", id);
      this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", id)
      this.$emit("toggleModuleSelection", id, index);

      this.$store.dispatch("workspace/updateProjectData", {
        id: this.projectData.id,
        active_module_id: id,
        workspace_id: this.projectData.workspace_id,
        name: this.projectData.name
      });
    },
    moduleIsolation(moduleId) {
      this.$emit("moduleIsolation", moduleId);
    },
    setActiveData(item) {
      this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item)
    },
    getData(items, moduleId) {
      let itemIndex = items.findIndex(data => data.root);
      if(itemIndex >= 0) {
        items.splice(itemIndex, 1);
      }

      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === moduleId);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      }
      return items.filter(data => !data.root);
    },
    isolation(moduleId, item, parents = []) {
      item.module_id = moduleId;
      item.parents = parents;
      this.$emit("toggleIsolation", item);
    },
  }
}
</script>

<style scoped>

</style>
