<template>
    <div v-click-outside="() => isShow = false">
        <slot name="trigger" v-bind="{ toggleDropdown }"></slot>
        <transition name="dropdown">
            <div v-if="isShow && canCreate(project.cans)" class="absolute bg-white py-4 px-5 rounded-sm shadow-two z-20" style="width: 225px;">
                <div class="flex justify-between mb-4">
                    <h3 class="fw-400 text-gray-700 fs-11">Assign people</h3>
                    <avatar  />
                </div>
                <div class="space-y-3">
                    <InputSelect :items="users" v-model="form.user_id" label="Select User">
                        <template #default="{ item }">
                            <option :value="item.id"> {{ fullName(item) }} </option>
                        </template>
                    </InputSelect>
                    <InputText
                        v-model="form.email"
                        class="name-input fw-500 fs-10 px-1 text-secondary-one bg-transparent w-full i-border-1 border-secondary-five rounded-sm py-1"
                        placeholder="Type name"
                    />
                    <div v-if="form.email" class="mt-5 flex gap-6 items-center">
                        <label class=" flex gap-3 items-center cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="15px" fill="#000000">
                                <path d="M0 0h24v24H0V0z" fill="none"/>
                                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/>
                            </svg>
                            <span class="fs-12">File Upload</span>
                            <input @input="onChange" hidden type="file" accept="image/x-png,image/jpeg" />
                        </label>
                        <img v-if="previewURL" class="w-8 h-8 ml-auto rounded-full border-2 border-gray-300 object-cover" :src="previewURL" />
                    </div>
                    <InputSelect :items="labels" v-model="form.roadmap_widget_label_id" label="Select label" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js';
  const { canCreate, canEdit, canDelete, canRead } = usePermission();
</script>

<script>
import Avatar from "./Avatar.vue";
import { InputSelect, InputText } from '@/elements/inputs';
import { fullName } from '@/plugins/lodash';
import { mapGetters, mapActions } from "vuex";
export default {
    name: 'AddPeopleDropdown',
    components: {
        Avatar,
        InputText,
        InputSelect
    },
    props: {
        users: Array,
        labels: Array
    },
    data() {
        return {
            isShow: false,
            form: {
                user_id: null,
                roadmap_widget_label_id: null,
                email: null
            },
            previewURL: null,
            image: null
        };
    },
    watch: {
        form: {
            handler(value) {
                if((value.user_id || value.email ) && value.roadmap_widget_label_id) {
                    this.$emit('addPeople', {
                        form: value,
                        toggleDropdown: this.toggleDropdown,
                        image: this.image
                    });
                }
            },
            deep: true
        },
        isShow(value) {
            if(!value) {
                Object.keys(this.form).forEach(key => this.form[key] = null);
            }
        }
    },
    computed: mapGetters({
        project: "project/project"
    }),
    methods: {
        onChange(e) {
            let file = e.target.files[0];
            this.image = file;
            this.previewURL = URL.createObjectURL(file);
        },
        fullName(...arg) {
            return fullName(...arg)
        },
        toggleDropdown() {
            this.isShow = !this.isShow;
        }
    }
}
</script>

<style scoped>
.pr-select-dropdown:focus {
    border: 1px solid #C9C9C9;
}
.dropdown-enter-active,
.dropdown-leave-active {
  transition: all .5s;
}
.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.name-input::placeholder {
  color: var(--color-secondary-one)
}
</style>
