<template>
    <div class="flex">
        <div>
            <input
                v-model="column.title"
                class="fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude"
                type="text"
                style="width: 110px;"
                @input="updateWidgetSettings(widget)"
            >
            <div class="bg-primary-two mt-1 business-widget-padding">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <div class="flex items-center gap-x-3 h-5" :class="[getTheGap(flatData, item, index)]">
                        <div @click="updateParameterValue(item, column.id, getParameterValue(item, column), handleParameterValueUpdate)" class="flex gap-x-1.5 cursor-pointer click-bounce">
                            <span
                                v-for="(i, index) in 5" :key="index"
                                :index="index"
                                class="w-2.5 h-2.5 rounded-sm shadow-two bg-C9"
                                :style="{'background': getParameterBg(getParameterValue(item, column), widget.definition_style, index)}"
                            ></span>
                        </div>

                        <span class="fs-12">{{ getParameterValue(item, column) }}</span>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="column.showNoteComment">
            <div class="fs-12 mt-2 invisible">+</div>
            <div class="bg-primary-two mt-1 business-widget-padding">
                <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                    <NoteComment
                        :class="[getTheGap(flatData, item, index)]"
                        :index="index"
                        :note="getNote(column, item.id, column.id)"
                        :bg-color="getParameterBg(getParameterValue(item, column), widget.definition_style)"
                        :comment-count="item.commentCount"
                        :collection-id="item.id"
                        @onType="updateWidgetSettings(widget)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import NoteComment from './NoteComment';
import useBusiness from '../useBusiness'
import useSetting from '@/components/roadmap/canvas/widgets/useSetting'
import useBusinessSetting from '../useBusinessSetting'
import { getTheGap, getParameterTextDefinition, getParameterValue} from '@/store/modules/roadmap/services'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})
const emit = defineEmits(['onUpdateParameterValue'])

const { getNote } =useBusiness()
const { getParameterBg } =useSetting()
const { updateWidgetSettings, getDataByIsolatedModules, updateParameterValue } =useBusinessSetting()

const handleParameterValueUpdate = () => emit('onUpdateParameterValue')
</script>
