import {useDate} from '@/composable/useDate'
import { ref, computed } from 'vue'
import store from '@/store'

const { formatDate, addDate } = useDate()
const showModal = ref(false)
const setToAll = ref(false)
const modalData = ref({})
let processedFilterColls = []
const activeRoadmapData = computed(() => {
      return store.getters['roadmap/programmatic']
})

export default function useTimeline() {
    const load = (data) => {
        processedFilterColls = data
    }
    const updateDate = () => {
      let data = modalData.value
      let newDate, oldDate

      if(data.newDate) {
        newDate = formatDate(data.newDate, 'yyyy-MM-dd')
      }

      if(data.date) {
        oldDate = formatDate(new Date(data.date), 'yyyy-MM-dd')
      }

      let date = newDate || oldDate

      if(date && setToAll.value) {
        fillAllEmptyDate(processedFilterColls, date, data.key)
      }

      if(newDate && !setToAll.value) {
        processedFilterColls[data.index][data.key] = newDate
        onDateChange(processedFilterColls[data.index])
      }

      setToAll.value = false
      showModal.value = false
    }

    const fillAllEmptyDate = (data, date, key) => {
      let ids = []
      data.forEach(item => {
        if(item[key] == null) {
          item[key] = date
          ids.push(item.id)
        }
      })
      if(ids.length) {
        bulkUpdateDate(date, key, ids)
      }
    }

    const openDateSelectionModal = (data) => {
      modalData.value = data
      showModal.value = true
    }

    const setDate = (e) => {
      modalData.value.newDate = e
    }

    const updateEndDate = ({item, value}) => {
      if(value) {
        const nextDate = addDate(new Date(item.start_date), value)
        item.end_date = formatDate(nextDate, 'yyyy-MM-dd')
        onDateChange(item)
      }
    }

    const onDateChange = (item) => {
      store.dispatch("programmatic/updateCollection", {
          id: item.id,
          start_date: item.start_date,
          end_date: item.end_date,
          scenario_id: activeRoadmapData.value?.scenario_id
      })
    }

    const bulkUpdateDate = (date, key ,ids) => {
      let formData = new FormData
      formData.append('date', date)
      formData.append('column', key)
      ids.forEach((id, index)=> {
        formData.append(`collection_id[${index}]`, id)
      })

      store.dispatch('programmatic/updateCollectionDate', formData)
    }

    return {
      formatDate,
      updateDate,
      showModal,
      setToAll,
      modalData,
      openDateSelectionModal,
      setDate,
      updateEndDate,
      onDateChange,
      activeRoadmapData,
      load
    }
  }