<template>
    <modal name="roadmap-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
        <div class="p-10">
            <div class="flex i-border-b-1 border-secondary-four">
                <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer ws-modal-item-selector i-border-b-1 border-primary-one opacity-100">
                    Roadmaps</h3>
                <!-- <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': is_creating}"
                    @click="is_creating = true, showList = false">
                    Create new</h3> -->

            </div>
            <div class="i-mt-30">
                <div class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" style="height: 200px">
                    <div v-for="(item, index) in programmatics" :key="index"
                         class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 ws-update-item"
                         :class="{'rounded i-border-1 border-secondary-two': selected == item.id}"
                         @click="selected = item.id">
                        {{ item.title }}
                    </div>
                </div>
                <div class="flex i-mt-30">
                    <!-- <button v-if="is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                            :class="{'pointer-events-none cursor-not-allowed': !activeBrainstormData.module_id && !activeBrainstormData.scenario_id}"
                            :disabled="loading || !conceptObj.title || (!activeBrainstormData.module_id && !activeBrainstormData.scenario_id)" @click="save()">
                        Create
                    </button> -->
                    <button
                        @click="navigate"
                        class="fs-14 mr-5 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one">
                        Open
                    </button>
                    <button class="focus:outline-none fs-14 fw-600 font-poppins i-pr-35 i-border-1 border-secondary-one rounded text-primary-one h-10 w-40"
                            @click="closeModal()">Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name : 'RoadmapNavModal',

    data: () => ({
        selected: null
    }),

    props: ['dModal'],

    watch: {
        dModal: function (val) {
            if (val) {
                this.openModal();
            } else {
                this.closeModal();
            }
        },

    },

     computed: {
        ...mapGetters({
               programmatics: "roadmap/initialProgrammatics",
        })
    },

    methods: {
        closeModal() {
            this.$modal.hide('roadmap-modal');
            this.$emit('toggleModalMode', false);
        },
        openModal() {
            this.$modal.show('roadmap-modal');
        },
        navigate() {
            if(!this.selected) return
            this.$router.push({ name: 'roadmap.show', params: { id: this.$route.params.id, roadmap_id: this.selected }})
            this.closeModal();
            this.$emit('onRouteChange')
        }
    },

    mounted() {
        this.selected = this.$route.params.roadmap_id
    }
}
</script>
