<template>
  <div class="flex">
    <TimelineInput
        :module="module"
        :filteredCollections="filteredCollections"
        :layout="layout"
        :widget="widget"
        :flatData="flatData"
        :isolatedModules="isolatedModules"
        class="mr-5"
        @loadAModuleData="loadAModuleData"
    />
    <Timeline
      :module="module"
      :filteredCollections="filteredCollections"
      :layout="layout"
      :widget="widget"
      :flatData="flatData"
      :isolatedModules="isolatedModules"
    />
  </div>
</template>

<script>
import Timeline from '@/components/roadmap/canvas/widgets/timeline/component/Timeline.vue'
import TimelineInput from '@/components/roadmap/canvas/widgets/timeline/component/TimelineInput.vue'
export default {
  name: "TimelineIndex",
  props: ['module', 'filteredCollections', 'layout', 'widget', 'flatData', 'isolatedModules'],
  components: {
    TimelineInput,
    Timeline
  },
  created() {
      this.$store.commit('roadmap/setWidget', this.widget)
  },
  methods: {
    loadAModuleData() {
      this.$emit("loadAModuleData");
    }
  }
};
</script>
