<template>
  <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins">
    <div class="flex items-center justify-between">
      <SettingsHeader
        class="mb-5"
        title="Logic Builder Configuration"
        @onDelete="deleteWidget"
      />
    </div>

    <div class="space-y-5">
      <InputSelect
          class="m-0"
          v-model="logicBuilder"
          :items="[{id: '', title: 'Logic Builder'}]"
      />
      <InputSelect
          v-model="defaultStyle"
          :items="[{id: '', title: 'Default Style'}]"
      />
    </div>
    <hr class="border-secondary-four mt-4">

    <div class="space-y-2 mt-5">
      <div  v-for="(parameter, pIndex) in widget.parameters" :key="pIndex">
          <SettingsAccordion
            :title="`Parameter ${pIndex + 1}`"
            v-model="parameter.active"
        >
            <template v-slot:action>
              <img src="/images/icons/brainstormV2/plus_circle_outline.svg" v-if="(pIndex + 1) === widget.parameters.length" alt="icon" class="cursor-pointer i-w-14 i-h-14" @click="newParameter" />
              <img src="/images/icons/brainstormV2/close.svg" alt="icon" class="i-w-14 i-h-14 ml-2 cursor-pointer" @click="deleteParameter(pIndex)" />
            </template>

              <div class="space-y-2">
<!--                <InputSelect-->
<!--                  v-model="parameter.custom_param"-->
<!--                  @input="setupItemValues(parameter)"-->
<!--                  :items="[-->
<!--                    {id: '2', title: 'System Parameter'},-->
<!--                    {id: '1', title: 'Custom for All'},-->
<!--                    {id: '3', title: 'Custom for Individual'},-->
<!--                  ]"-->
<!--                />-->
<!--                <div class="space-y-2" v-if="parameter.custom_param && (parameter.custom_param == 1 || parameter.custom_param == 3)">-->
<!--                  <input type="text"-->
<!--                    class="bg-transparent fs-10 i-border-1 border-secondary-five px-1 py-1 w-full placeholder-secondary-five rounded-sm"-->
<!--                    placeholder="Custom Parameter"-->
<!--                    v-model="parameter.custom_title"-->
<!--                    @change="updateWidgetSettings()"-->
<!--                  />-->
<!--                  <InputSelect-->
<!--                    v-model="parameter.custom_type"-->
<!--                    label="Select Property Set"-->
<!--                    :items="[-->
<!--                      {id: '1', title: 'Currency'},-->
<!--                      {id: '2', title: 'Number'},-->
<!--                      {id: '3', title: 'Percentage'},-->
<!--                    ]"-->
<!--                  />-->
<!--                </div>-->
                <div class="space-y-2">
                  <InputSelect
                    v-model="parameter.property_id"
                    label="Select Property Set"
                    :items="module.properties"
                  />

                  <InputSelect
                    v-if="parameter.property_id"
                    v-model="parameter.id"
                    label="Select Parameter"
                    :items="module.properties.find(item => item.id === parameter.property_id).property_collections"
                  />
                </div>
                <InputSelect
                    v-model="parameter.parenthesis"
                    :items="[
                      {id: 'null', title: 'Parenthesis None'},
                      {id: '1', title: 'Parenthesis Start'},
                      {id: '2', title: 'Parenthesis End'},
                    ]"
                  />

              </div>
          </SettingsAccordion>
      </div>
    </div>
    <div class="mt-6 hidden">
      <div class="flex justify-between">
        <h4 class="fs-10 fw-500 text-primary-one">Levels</h4>
        <h4 class="fs-10 fw-500 text-primary-one">Avg. Value</h4>
      </div>
      <div class="" v-click-outside="hidePicker">
        <div :style="{top : pickerPositionOffset+'px'}" class="absolute color-picker-div l-80" v-if="showPicker">
          <div class="flex flex-col items-center justify-center mt-3">
            <color-panel v-model="color" @change="onColorChange"></color-panel> </div>
        </div>
        <div v-for="(definitionStyle, index) in widget.definition_style" :key="index" class="flex justify-between items-center mt-2" :class="{'mt-4': index > 0}" >
          <div @click.prevent="openColorPicker(definitionStyle, index)" class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center mr-5 cursor-pointer"
               :style="{'background': definitionStyle.bg}">{{ definitionStyle.range }}</div>
          <div class="flex justify-between">
            <div class="value-identifier shadow-two" :style="{'background': definitionStyle.bg}"></div>
            <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 2 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 4 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 6 ? definitionStyle.bg : ''}"></div>
            <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 8 ? definitionStyle.bg : ''}"></div>
          </div>
          <div class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center ml-5" :style="{'background': definitionStyle.bg}">
            <input type="text" class="bg-transparent definition-section text-center" v-model="definitionStyle.definition" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteWidget } from '@/store/modules/roadmap/services';
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import InputSelect from '@/elements/inputs/InputSelect';

export default {
  name: "LogicBuilderSettings",
  props: ['widget', 'layout', 'module', 'moduleHeight', 'filteredCollections'],
  components: {
    InputSelect,
    SettingsHeader,
    SettingsAccordion
  },
  created() {
    if(this.widget && (!this.widget.parameters || this.widget.parameters.length <= 0)) {
      this.widget.parameters = [
          {
            property_id: null,
            id: null,
            custom_param: 2,
            custom_title: null,
            custom_type: 1,
            custom_param_hints: null,
            custom_field_value: 0,
            parenthesis: "null"
          }
      ];
    }
  },
  data() {
    return {
      logicBuilder: '',
      defaultStyle: '',
      color: '',
      showPicker: false,
      current: {},
      currentIndex: 0,
      rangeDefinitions: ['0-2', '2-4', '4-6', '6-8', '8-10']
    }
  },
  computed: {
    pickerPositionOffset () {
      return 399 + (30 * this.currentIndex)
    }
  },
  methods: {
    openColorPicker(obj, index) {
      this.currentIndex = index
      this.current = obj
      this.showPicker = !this.showPicker
    },
    hidePicker() {
      this.showPicker = false;
      this.updateWidgetSettings();
    },
    onColorChange(color)  {
      this.current.bg = color
    },
    getMinRange(rangeText) {
      let rangeArray = rangeText.split("-");
      if(rangeArray[0]) {
        return rangeArray[0];
      }
      return 0;
    },
    updateWidgetSettings() {
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    newParameter() {
      this.widget.parameters.push(
          {
            property_id: null,
            id: null,
            custom_param: 2,
            custom_title: null,
            custom_type: 1,
            custom_param_hints: null,
            custom_field_value: 0,
            item_values: []
          }
      );
      this.widget.operator.push('+');
    },
    setupItemValues(parameter) {
      if(parameter.custom_param == 3) {
        parameter.item_values = this.filteredCollections.map(item => {
          return {
            id: item.id,
            sample_value: 0
          };
        });
      } else {
        parameter.item_values = [];
      }
    },
    deleteParameter(index) {
      this.widget.parameters.splice(index, 1);
      this.widget.operator.splice(index, 1);
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      if(this.widget.parameters.length <= 0) {
        this.widget.parameters = [{ property_id: null, id: null, custom_param: 2 }];
      }
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    }
  }
}
</script>

<style scoped>
.top-399 {
  top: 399px;
}
.l-80{
  left: 80px;
}
.settings-widget {
  width: 250px;
  min-height: 624px;
  max-height: 724px;
  overflow-y: auto;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
.range-section {
  width: 47px;
  height: 14px;
}
.definition-section {
  width: 47px;
}
.value-identifier {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: #8D8D8D;
}
.param-dropdown:focus {
  border: 1px solid #C9C9C9;
}
.delete-icon {
  width: 17px;
  height: 15px;
}
</style>
