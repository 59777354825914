<template>
  <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins overflow-y-auto">
    <SettingsHeader
        class="mb-5"
        title="People Configuration"
        @onDelete="canDelete(project.cans) && deleteWidget"
    />
    <div class="space-y-5">
      <InputSelect
          v-model="peopleWidget"
          :items="[{id: '', title: 'People'}]"
      />
      <InputSelect
          v-model="currentComponentName"
          :items="[
            {id: 'FiveLevelPeople', title: '5 Level People Metrics'},
            {id: 'FourZonePeople', title: '4 Zone People'}
          ]"
      />
    </div>

    <hr class="border-secondary-four mt-4">

    <InputSelect
        class="i-mt-14 mb-3"
        label="Select People"
        outer-label
        :items="peoplesDropdown"
        return-type="index"
        v-model="currentPeopleId"
    />
    <div v-if="currentPeople && !currentPeople.user_id" class="my-5 flex gap-6 items-center">
        <label class=" flex gap-3 items-center cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" height="15px" viewBox="0 0 24 24" width="15px" fill="#000000">
                <path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z"/>
            </svg>
            <span class="fs-12">File Upload</span>
            <input @input="onChange" hidden type="file" accept="image/x-png,image/jpeg" />
        </label>
        <img class="w-8 h-8 ml-auto rounded-full border-2 border-gray-300 object-cover" :src="getUserAvatar(currentPeople)" alt="">
    </div>
    <div v-for="(parameter, pIndex) in localWidgetParameters" :key="pIndex">
        <SettingsAccordion
            v-if="currentPeopleId"
            :title="`Field ${pIndex + 1}`"
            v-model="parameter.active"
            class="mb-2"
        >
            <template v-slot:action>
                <img src="/images/icons/brainstormV2/plus_circle_outline.svg" alt="icon" class="cursor-pointer i-w-14 i-h-14"
                    @click="()=>{canEdit(project.cans) && newParameter()}" />
                <img src="/images/icons/brainstormV2/close.svg" alt="icon" class="i-w-14 i-h-14 ml-1 cursor-pointer"
                    @click="()=>{canDelete(project.cans) && deleteParameter(pIndex)}" />
            </template>
            <div class="space-y-2">
                <InputSelect
                v-model="parameter.property_id"
                label="Select Property Set"
                :items="module.properties"
                />
                <InputSelect
                v-if="parameter.property_id"
                v-model="parameter.id"
                label="Select Parameter"
                :items="getPropertyCollection(parameter)"
                @input="() =>  {canEdit(project.cans) && updateWidgetSettings() }"
                />
            </div>
        </SettingsAccordion>
    </div>
    <hr class="mt-4 mb-2">
    <div>
      <component
        :is="currentComponent"
        :widget="widget"
        :layout="layout"
        :module="module"
        :moduleHeight="moduleHeight"
        @onClickOutsideColorPicker="hidePicker"
        @onColorChange="canEdit(project.cans) && onColorChange"
        :openColorPicker="openColorPicker"
      />
    </div>
    <hr class="mt-4 mb-2">
    <PeopleRole :widgetId="widget.id" />
  </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js';
  import usePeople from '@/store/modules/people/usePeople';
  const { getUserAvatar } = usePeople();
  const { canCreate, canEdit, canDelete, canRead } = usePermission();
</script>

<script>
import FourZonePeople from './component/FourZonePeople'
import FiveLevelPeople from './component/FiveLevelPeople'
import PeopleRole from './component/PeopleRole'
import { deleteWidget, peopleFullName } from '@/store/modules/roadmap/services';
import InputSelect from '@/elements/inputs/InputSelect';
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import { isEmpty, uniqBy, set } from 'lodash'
import {mapGetters,mapActions} from "vuex";
export default {
  name: "PeopleWidgetSettings",
  components: {
    FourZonePeople,
    FiveLevelPeople,
    PeopleRole,
    InputSelect,
    SettingsHeader,
    SettingsAccordion
  },
  props: ['widget', 'layout', 'module', 'moduleHeight', 'peoples'],

  data: () => ({
    peopleWidget: '',
    selectedUser: null,
    currentComponentName: 'FiveLevelPeople',
    current: {},
    currentIndex: 0,
    currentPeopleId: null,
    rangeDefinitions: ['0-2', '2-4', '4-6', '6-8', '8-10'],
    sectionArr: [{name: '5 Level People Metrics', value: 1}],
    localPeoplesData: [],
    form: {
        id: '',
        parameters: []
    },
    emptyData: [{ property_id: null, id: null, active: true }],
    localWidgetParameters: [],
    currentPeople: {}
  }),

  computed: {
    ...mapGetters({
        project: "project/project"
    }),
    pickerPositionOffset () {
      return 399 + (30 * this.currentIndex)
    },
    peoplesDropdown() {
        if(!isEmpty(this.peoples)) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.currentPeopleId = this.peoples[0].id;
        }
        return this.peoples.map(item => ({
            id: item.id,
            title: peopleFullName(item),
            parameters: this.module.properties
        }))
    },
    currentComponent () {
      return this.currentComponentName
    }
  },
  watch: {
    currentPeopleId() {
      this.fillCurrentPeopleParameters()
    }
  },
  methods: {
    async onChange(e) {
        let file = e.target.files[0];
        set(this.currentPeople, 'user.image.file_path', URL.createObjectURL(file));
        let formData = new FormData;
        formData.append('id', this.currentPeople.id);
        formData.append('image', file);
        await this.$store.dispatch("people/uploadPeopleImage", formData);
    },
    openColorPicker(obj, index) {
      this.currentIndex = index
      this.current = obj
    },
    hidePicker() {
      this.showPicker = false;
      this.updateWidgetSettings();
    },
    onColorChange(color)  {
      this.current.bg = color
    },
    getMinRange(rangeText) {
      let rangeArray = rangeText.split("-");
      if(rangeArray[0]) {
        return rangeArray[0];
      }
      return 0;
    },
    updateWidgetSettings() {
        if(this.currentPeopleId) {
            let indexValue = this.widget.parameters.findIndex(item => item.people_id == this.currentPeopleId);
            if(indexValue != -1) {
                this.widget.parameters[indexValue].parameters = this.localWidgetParameters;
            }else {
                this.widget.parameters.push({
                    people_id: this.currentPeopleId,
                    parameters: this.localWidgetParameters
                });
            }
        }
       this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    newParameter() {
        if(this.peoplesDropdown && this.peoplesDropdown.length > 0) {
          this.localWidgetParameters.push({ property_id: null, id: null, active: true });
        }
    },
    deleteParameter(index) {
      this.localWidgetParameters.splice(index, 1);
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      if(this.localWidgetParameters.length <= 0) {
        this.localWidgetParameters = [{ property_id: null, id: null }];
      }
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        // deleteWidget(this.widget.id, this.layout);
      });
    },
    fillCurrentPeopleParameters() {
        let people = this.peoples.find(people => people.id == this.currentPeopleId);
        if(people) this.currentPeople = people
        let currentPeopleParameters = this.widget.parameters.find(item => item.people_id == this.currentPeopleId);
        if(currentPeopleParameters && !isEmpty(currentPeopleParameters.parameters)) {
          this.localWidgetParameters = currentPeopleParameters.parameters;
        }else  {
          this.localWidgetParameters = [{ property_id: null, id: null, active: true }];
        }
    },
    getPropertyCollection(parameter) {
      let properties = this.module.properties.find(item => item.id === parameter.property_id)
      if(properties) {
        return properties.property_collections.filter(param => !param.option || param.option == 1)
      }
      return []
    }
  },

  created() {
    this.fillCurrentPeopleParameters()
}
}
</script>
