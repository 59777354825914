<template>
    <div :id="module.title" class="font-poppins flex">
      <!-- <p class="absolute">{{ $store.state.moduleHeights }}</p> -->
        <div
            class="flex"
            v-for="(layout, layoutIndex) in module.layouts"
            :key="layoutIndex"
            :class="{'ml-5': layoutIndex > 0}"
        >
            <div class="flex flex-col">
                <template v-if="!layout.rotate">
                    <div v-if="layout.expanded || (!layout.expanded && layoutIndex === 0)"
                        class="flex justify-between items-center layout rounded px-4 mb-2"
                        :class="[{'expanded-layout-width': layout.expanded}, {'collapsed-first-layout-width': !layout.expanded && layoutIndex === 0}]"
                        :style="[{'background': getModuleBg()}]"
                    >
                        <div class="flex w-full cursor-pointer" :class="{'mr-5': layout.expanded}" @click="collapseLayout(layout)">
                            <div class="flex" v-if="layout.expanded || (!layout.expanded && layoutIndex === 0)">
                                <div class="flex items-center">
                                    <h4 class="fs-14 text-primary-three fw-700 module-title-width ellipse">{{ module.title }}</h4>
                                    <img src="/images/roadmap/widget_load_white.svg" alt="icon" @click.stop="openModuleSelectionModal(layout)" class="pr-4 i-border-r-1 border-primary-three cursor-pointer ml-4" />
                                </div>
                                <InputText
                                    v-model="layout.title"
                                    class="fs-14 text-primary-three fw-500 pl-4 bg-transparent placeholder-secondary-two truncate w-100"
                                    @input="updateLayout(layout)"
                                    :disabled="!canEdit(project.cans)"
                                    @click.native.stop
                                />
                            </div>
                            <div class="flex ml-auto">
                                <img
                                  src="@/assets/images/roadmap/color_toggler.svg"
                                  alt="icon"
                                  class="cursor-pointer w-3"
                                  :class="!canEdit(project.cans)&&'pointer-events-none'"
                                  @click.stop="colorToggler(layout)" v-if="activeBg(layout)" />
                                <img
                                  src="@/assets/images/roadmap/color_off.svg"
                                  alt="icon"
                                  class="cursor-pointer w-3"
                                  :class="!canEdit(project.cans)&&'pointer-events-none'"
                                  @click.stop="colorToggler(layout)" v-else />
                                <img
                                  src="@/assets/images/roadmap/duplicate_layout.svg"
                                  alt="icon"
                                  class="ml-4 cursor-pointer w-3"
                                  :class="!canCreate(project.cans)&&'pointer-events-none'"
                                  @click.stop="duplicateLayout(layout.id)" />
                                <img
                                  src="@/assets/images/roadmap/delete_layout.svg"
                                  alt="icon"
                                  class="ml-4 cursor-pointer w-3"
                                  :class="!canDelete(project.cans)&&'pointer-events-none'"
                                  @click.stop="deleteLayout(module, layout.id, layoutIndex)" />
                            </div>
                        </div>
                    </div>
                    <MiniLayoutHeader
                        v-if="!layout.expanded && layoutIndex !== 0"
                        :background="getModuleBg()"
                        :label="`${moduleIndex + 1}.${layoutIndex + 1}`"
                        @onClick="collapseLayout(layout)"
                    />
                    <div class="flex relative" v-show="layout.expanded">
                        <div :id="'group_data_' + module.id">
                            <group-data
                                :toggleShowContentTitle="toggleShowContentTitle"
                                :module="module"
                                :layout="layout"
                                :defaultWidget="true"
                                :flatData="flatData"
                                :filteredCollections="filteredData"
                                :isolatedModules="isolatedModules"
                                @toggleToPropertyMode="toggleToPropertyMode"
                                @loadAModuleData="loadAModuleData"
                            />
                            <div v-if="showContentTitle" class="absolute widget-content-post">
                                <!-- <h4 class="fs-10 fw-500 text-secondary-two">Module Content: <span class="text-primary-one">System Content</span></h4> -->
                            </div>
                        </div>

                        <draggable
                          handle=".handler"
                          draggable=".item"
                          :disabled="!canEdit(project.cans)"
                          class="flex"
                          :group="{ name: 'area' }"
                          v-model="layout.widgets"
                          @change="log"
                        >
                            <div :identifier-id="widget.id"  v-for="(widget, wIndex) in layout.widgets"  :key="wIndex" class="item flex">
                                <component class="ml-5"
                                    v-if="templateRender && widget.type"
                                    :key="widget.id"
                                    :is="getWidget(widget.type)"
                                    :ref="widgetTypes[widget.type - 1]"
                                    :module="module"
                                    :layout="layout"
                                    :widget="widget"
                                    :filteredCollections="filteredData"
                                    :flatData="flatData"
                                    :isolatedModules="isolatedModules"
                                    :rootHeight="setParentHeight()"
                                    @toggleToPropertyMode="toggleToPropertyMode"
                                    @loadAModuleData="loadAModuleData"
                                />
                                <empty-widget v-else
                                    class="mt-6"
                                    :module="module"
                                    :layout="layout"
                                    :widget="widget"
                                    :filteredCollections="filteredData"
                                    @reRenderEmptyWidget="reRenderEmptyWidget"
                                />
                            </div>
                        </draggable>
                        <div
                          class="add-widget bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm ml-5 mt-6"
                          :class="!canCreate(project.cans)&&'pointer-events-none'"
                          @click="createWidget(layout)">
                            <div class="panzoom-exclude add-group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five handler">
                                <h4 class="text-center fs-14 fw-bold">+</h4>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-else>
                  <RotateLayoutHeader
                    :module-title="module.title"
                    :layout-title="layout.title"
                    :label="`${moduleIndex + 1}.${layoutIndex + 1}`"
                    :background="getModuleBg()"
                    :class="!canEdit(project.cans)&&'pointer-events-none'"
                    @removeRotation="removeRotation(layout)"
                  >
                    <template v-slot:action>
                        <img
                          :class="!canEdit(project.cans)&&'pointer-events-none'"
                          src="@/assets/images/roadmap/color_toggler.svg"
                          alt="icon"
                          class="cursor-pointer w-3"
                          @click.stop="colorToggler(layout)" v-if="activeBg(layout)" />
                        <img
                          :class="!canEdit(project.cans)&&'pointer-events-none'"
                          src="@/assets/images/roadmap/color_off.svg"
                          alt="icon"
                          class="cursor-pointer w-3"
                          @click.stop="colorToggler(layout)" v-else />
                        <img
                          :class="!canCreate(project.cans)&&'pointer-events-none'"
                          src="@/assets/images/roadmap/duplicate_layout.svg"
                          alt="icon"
                          class="ml-4 cursor-pointer w-3"
                          @click.stop="duplicateLayout(layout.id)" />
                        <img
                          :class="!canDelete(project.cans)&&'pointer-events-none'"
                          src="@/assets/images/roadmap/delete_layout.svg"
                          alt="icon"
                          class="ml-4 cursor-pointer w-3"
                          @click.stop="deleteLayout(module, layout.id, layoutIndex)" />
                    </template>
                  </RotateLayoutHeader>
                </template>
            </div>
        </div>
        <MiniLayoutHeader
          :class="{'ml-5': module.layouts.length > 0}" :background="getModuleBg()"
          @onClick="()=>{canCreate(project.cans)&&createLayout(module)}" />
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete, canRead } = usePermission()
</script>

<script>
import resizeObserver from '@/store/services/resizeObserver.js'
import GroupData from './GroupData.vue';
import {mapGetters,mapActions} from "vuex";
import People from "../widgets/people/Index";
import AssociatedContent from "../widgets/AssociatedContent/Index";
import EmptyWidget from "../widgets/EmptyWidget";
import InputText from '@/elements/inputs/InputText';
import Timeline from "../widgets/timeline/Index.vue";
import LogicBuilder from "../widgets/LogicBuilder/Index";
import DynamicField from "../widgets/DynamicField/Index";
import MiniLayoutHeader from './component/MiniLayoutHeader';
import CompareParameter from "../widgets/metrics/compare/Index";
import basicParameter from '../widgets/metrics/basic/index.vue';
import RotateLayoutHeader from './component/RotateLayoutHeader';
import DefinitionParameter from "../widgets/metrics/definition/Index";
import BusinessWidget from "../widgets/Business/Index";
export default {
  name: "ModuleData",
  props: ['module', 'moduleIndex', 'isolatedModules'],
  components: {
    People,
    Timeline,
    GroupData,
    InputText,
    EmptyWidget,
    LogicBuilder,
    DynamicField,
    basicParameter,
    MiniLayoutHeader,
    CompareParameter,
    RotateLayoutHeader,
    DefinitionParameter,
    AssociatedContent,
    BusinessWidget
  },
   mounted() {
    const d = new resizeObserver(document.getElementById(this.module.title), this.module.title)
    d.observer()
    const self = this;
    self.getItems();
    self.prepareModuleParameterValue();
    setTimeout(() => {
      self.templateRender = true;
    }, 4000);
  },
  data() {
    return {
      list: '',
      showContentTitle: true,
      filteredData: [],
      templateRender: false,
      widgetTypes: ['basic-parameter', 'compare-parameter', 'definition-parameter', 'People', 'dynamic-field', 'logic-builder', 'Timeline', 'group-data', 'associated-content', 'BusinessWidget'],
      flatData: []
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      colorCodes: "global/colorCodes",
      reload: "roadmap/reload",
      activeRoadmapData: "roadmap/programmatic",
      getModuleHeight: "getModuleHeight",
      project: "project/project",
    }),
    moduleHeight() {
      return this.getModuleHeight(this.module.title.toLowerCase())
    },
    getModuleSettings() {
      let module = this.modules.find(item => parseInt(item.id) === parseInt(this.module.id));
      if (module) {
        return JSON.parse(module.collection_settings);
      }
      return null;
    },
  },
  watch: {
    reload: function (val) {
      if(val) {
        let innerReload = true;
        this.getItems(innerReload);
      }
    },
    module: {
      handler: function (val) {
        this.flatData = [];
        this.filteredData = [];
        this.getItems(false)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      orderWidget: 'roadmap/orderWidget',
      loadModuleData: "roadmap/loadModuleData"
    }),
    removeRotation(layout) {
       this.$set(layout, 'rotate', false);
       this.$set(layout, 'expanded', true);
    },
    collapseLayout(layout) {
        layout.expanded = !layout.expanded;
        let index =  this.module.layouts.findIndex(item => item.expanded);
        if(index !== -1) {
            for (let i = 0; i < index; i++) {
                this.$set(this.module.layouts[i], 'rotate', true);
            }
        }else {
            this.module.layouts.forEach(item => this.$set(item, 'rotate', false))
        }
    },
    openModuleSelectionModal(layout) {
      this.$emit('onClickLoad', layout.id)
      this.$emit('setLayoutModule', layout.module_id, 'target');
    },
    async log(e) {
      let reorderedWidgetArr = []
      const layoutId = e.moved.element.roadmap_layout_id;
      const layout = this.module.layouts.find(layout => layout.id == layoutId)
      layout.widgets.map(w => reorderedWidgetArr.push(w.id))
      let res = await this.orderWidget(reorderedWidgetArr)
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    toggleShowContentTitle () {
      this.showContentTitle = !this.showContentTitle
    },
    getModuleBg(level = 1) {
      let collectionSettings = this.getModuleSettings;
      if(collectionSettings && collectionSettings.collections.length > 0) {
        if(collectionSettings.collections[0].custom_bg) {
          return collectionSettings.collections[0].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collectionSettings.collections[level].color];
      }
      return null;
    },
    prepareModuleParameterValue() {
      const self = this;
      self.module.temp_properties_value = [];
      let properties = self.module.properties.map(property => property.property_collections);
      if(properties.length > 0) {
        let parameters = [];
        properties.forEach(item => {
          parameters = [...parameters, ...item.map(data => data)];
        });

        let paramHighestValue = 0;
        if(parameters && parameters.length > 0) {
          parameters.forEach(param => {
            paramHighestValue = 0;

            let parameterSampleValue = 0;
            self.module.collections.forEach(item => {
              let paramObj = item.properties?.find(paramValue => paramValue.property_collection_id === param.id);
              if(paramObj) {
                parameterSampleValue += paramObj.sample_value;
              }
              if(param.option == 2 && param.same_value_apply_to_all) {
                if(paramObj.sample_value > paramHighestValue) {
                  paramHighestValue = paramObj.sample_value;
                }
              }
            });

            let sample_value = 0;
            if(param.option == 2 && param.same_value_apply_to_all) {
              sample_value = paramHighestValue;
            } else {
              sample_value = !param.option || param.option == 1 ? parameterSampleValue / self.module.collections.length : parameterSampleValue;
            }

            self.module.temp_properties_value.push({
              module_collection_id: self.module.id,
              property_collection_id: param.id,
              sample_value: sample_value
            });
          });
        }
      }
    },
    getItems(innerReload = false) {
      let data = this.module.collections.slice();
      data.forEach((item, iIndex) => {
        this.getChildData(innerReload, item, iIndex);
      });
    },
    getChildData(innerReload, item, iIndex) {
      item.siblings_index = iIndex;
      if(!innerReload) {
        this.flatData.push(item);
      }
      this.module.flat_data = this.flatData;
      // this.getRecursiveChild(item);
      if (item.child && item.child.length > 0) {
        item.child.forEach((child, index) => {
          this.getChildData(innerReload, child, index);
        });
      } else {
        if(!innerReload) {
          this.filteredData.push(item);
        }
      }
    },
    getRecursiveChild(item) {
      let count = 0;
      if(item.child.length > 0) {
        item.child.forEach(child => {
          count += this.getRecursiveChild(child);
        });
      } else {
        count = 1;
      }
      item.child_count = count;
      return count;
    },
    setParentHeight() {
      let rootDivHeight = 0;
      setTimeout(() => {
        rootDivHeight = document.getElementById(`group_data_${this.module.id}`).clientHeight;
      }, 0);
      return rootDivHeight;
    },
    createLayout(module) {
      let data = {
        roadmap_id: this.$route.params.roadmap_id,
        module_id: module.id,
        title: 'Default',
        settings: { color: true }
      };

      this.$Progress.start();
      this.$store.dispatch("roadmap/storeLayout", data).then(response => {
        this.$Progress.finish();
        if(response.data.data) {
          response.data.data.widgets = [];
          module.layouts.push(response.data.data);
        }
      });
    },
    createWidget(layout) {
      let data = {
        roadmap_id: this.$route.params.roadmap_id,
        roadmap_layout_id: layout.id,
        title: 'Default',
        order: layout.widgets.length > 0 ? layout.widgets[layout.widgets.length - 1].order + 1 : 1,
        definition_style: this.prepareDefaultDefinitionStyle(),
        parameters: [],
        operator: []
      };

      this.$Progress.start();
      this.$store.dispatch("roadmap/storeWidget", data).then(response => {
        this.$Progress.finish();
        if(response.data.data) {
          layout.widgets.push(response.data.data);
        }
      });
    },
    prepareDefaultDefinitionStyle() {
      return [
        { range: '0-2', bg: '#AD2B81', definition: 'Very Low' },
        { range: '2-4', bg: '#ABA62B', definition: 'Low' },
        { range: '4-6', bg: '#8D8D8D', definition: 'Medium' },
        { range: '6-8', bg: '#2BAD7E', definition: 'High' },
        { range: '8-10', bg: '#2B80AD', definition: 'Extreme' }
      ];
    },
    getWidget(type) {
      return this.widgetTypes[type - 1];
    },
    colorToggler(layout) {
      if(layout.settings) {
        layout.settings.color = !layout.settings.color;
      } else {
        layout.settings.color = true;
      }
      this.updateLayout(layout);
    },
    updateLayout(layout) {
      this.$store.dispatch("roadmap/updateLayout", layout);
    },
    activeBg(layout) {
      return !!(layout && layout.settings && layout.settings.color);
    },
    deleteLayout(module, id, layoutIndex) {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteLayout", {
        layout_id: id,
        roadmap_id: this.$route.params.roadmap_id,
        project_id: this.$route.params.id
      }).then(response => {
        this.$Progress.finish();
        module.layouts.splice(layoutIndex, 1);
      });
    },
    duplicateLayout(id) {
      this.$Progress.start();
      this.$store.dispatch("roadmap/duplicateLayout", {
        layout_id: id,
        roadmap_id: this.$route.params.roadmap_id,
        project_id: this.$route.params.id
      }).then(response => {
        this.$Progress.finish();
      });
    },
    toggleToPropertyMode() {
      this.$emit("toggleToPropertyMode");
    },
    reRenderEmptyWidget() {
      this.templateRender = false;
      this.templateRender = true;
    },
    loadAModuleData() {
      this.loadModuleData({
        scenario_id: this.activeRoadmapData.scenario_id,
        module_id: this.module.id
      }).then(response => {
        if(response && response.data && response.data.status && response.data.data && response.data.data.id) {
          this.module.collections = response.data.data.collections;
          this.flatData = [];
          this.filteredData = [];
          this.getItems();
          this.prepareModuleParameterValue();
        }
      });
    },
  }
}
</script>

<style scoped>
.group-data {
  min-width: 460px;
}
.layout {
  height: 50px;
  /*min-width: 546px;*/
}
.group-shadow-bg {
  height: 50px;
}
.group-setting {
  height: 40px;
}
.widget-setting-icon {
  width: 13px;
  height: 13px;
}
.add-widget {
  height: 50px;
  width: 50px;
}
.add-group-setting {
  height: 40px;
}
.collapsed-layout-width {
  width: 50px;
}
.collapsed-first-layout-width {
  min-width: 620px;
}
.expanded-layout-width {
  min-width: 546px !important;
}
.module-title-width {
  min-width: 265px;
}
.lyt-w-230 {
  width: 230px;
}
.lyt-w-230-inner {
  width: 85%;
}
.w-290px {
  width: 290px;
}
</style>
