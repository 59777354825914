<template>
  <div>
    <div v-if="root">
      <Accordion :title="`${collection.title}`" :bg="getModuleBg(collection)">
        <template v-slot:default="{ visibility }">
          <BarChart
            :break-the-title="true"
            v-if="visibility"
            :chart-data="prepareChartData(collection, collection.temp_properties_value)"
            :styles="barStyles"
        />
        </template>
      </Accordion>
    </div>
    <div v-for="(item, index) in collection.child" :key="index">
      <Accordion :title="`${item.index_code}  ${item.title}`" :bg="getBg(item)">
        <template v-slot:default="{ visibility }">
          <BarChart
            :break-the-title="true"
            v-if="visibility"
            :chart-data="prepareChartData(item, item.properties)"
            :styles="barStyles"
        />
        </template>
      </Accordion>

      <div v-if="item.child.length > 0 && hasTitle(item)">
        <SystemData
            :module="module"
            :layout="layout"
            :widget="widget"
            :collection="item"
            :root="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "../BarChart.js";
import {mapGetters} from "vuex";
import { getModuleBg } from '@/store/modules/global/services';
import  { has }  from 'lodash'
import Accordion from "../../../../../elements/atom/Accordion";
export default {
  name: "SystemData",
  components: {Accordion, BarChart},
  props: ['module', 'layout', 'widget', 'collection', 'root'],
  data() {
    return {
      barStyles: {
        height: '150px'
      },
      minRange: [0, 2, 4, 6, 8],
      maxRange: [2, 4, 6, 8, 10],
    }
  },
  computed: {
    ...mapGetters({
      colorCodes: "global/colorCodes"
    }),
    getModuleSettings() {
      if (this.module) {
        return JSON.parse(this.module.collection_settings);
      }
      return null;
    },
  },
  methods: {
    hasTitle(item){
        return has(item, 'title');
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    prepareChartData(collection, properties) {
      let data = [];
      let labels = [];
      let backgrounds = [];

      if(this.widget.type === 6) {
        return this.logicBuilderChartData(collection, properties);
      } else {
        this.widget.parameters.forEach((param, index) => {
          labels.push(this.parameterTitle(param.id));
          let itemParameter = properties?.find(data => data.property_collection_id === param.id);
          if(itemParameter) {
            data.push(itemParameter.sample_value ? itemParameter.sample_value.toFixed(2) : 0);
            backgrounds.push(this.getParameterBg(itemParameter.sample_value));
          } else {
            data.push(0);
          }
          backgrounds.push(this.getParameterBg(itemParameter.sample_value));
        });
      }

      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgrounds.length > 0 ? backgrounds : 'rgba(141, 141, 141, 1)',
          borderWidth: 0,
          barThickness: 24,
        }],
      };
    },
    logicBuilderChartData(collection, properties) {
      let data = [];
      let labels = [];
      let backgrounds = [];
      let result = 0;
      let formulaString = '';

      this.widget.parameters.forEach((param, index) => {
        let value = '';
        if(index > 0) {
          formulaString = formulaString + ' ' + this.widget.operator[index - 1];
        }

        if(param.parenthesis && param.parenthesis == 1) {
          formulaString = formulaString + ' ' + '(';
        }

        if(param.custom_param && (param.custom_param == 1 || param.custom_param == 3)) {
          labels.push(`${param.custom_title} ${param.custom_type == 3 ? '%' : ''}`);
          if(param.custom_param == 1) {
            value = param.custom_field_value;
          } else {
            value = this.getIndividualParameterValue(collection, param);
          }
          backgrounds.push("#2B80AD");
          value = param.custom_type == 3 ? value / 100 : value;
          data.push(value);
        } else {
          labels.push(this.parameterTitle(param.id));

          let itemParameter = properties?.find(data => data.property_collection_id === param.id);
          if(itemParameter) {
            data.push(itemParameter.sample_value ? itemParameter.sample_value.toFixed(2) : 0);
            backgrounds.push(this.getBgFromParameter(param.id));

            value = itemParameter.sample_value ? itemParameter.sample_value.toFixed(2) : 0;
          } else {
            data.push(0);
            value = 0;
          }
        }

        let systemParameter = this.getSystemParameter(param);
        if(systemParameter && systemParameter.format && systemParameter.format == 2) {
          value = value * 10;
        }
        formulaString = formulaString + ' ' + value;

        if(param.parenthesis && param.parenthesis == 2) {
          formulaString = formulaString + ' ' + ')';
        }
      });

      result = eval(formulaString);
      result = result.toFixed(2);
      labels.push('Result');
      data.push(result);
      backgrounds.push("#2B80AD");

      return {
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: backgrounds.length > 0 ? backgrounds : 'rgba(141, 141, 141, 1)',
          borderWidth: 0,
          barThickness: 24,
        }],
      };
    },
    getIndividualParameterValue(item, parameter) {
      let itemObj = parameter.item_values.find(data => data.id === item.id);
      if(itemObj) {
        return itemObj.sample_value;
      }
      return 0;
    },
    parameterTitle(parameterId) {
      let property = this.module.properties.find(prop => prop.property_collections.find(param => param.id === parameterId));
      if(property) {
        let parameter = property.property_collections.find(param => param.id === parameterId);
        if(parameter) {
          return parameter.title
        }
      }
      return 'N/A';
    },
    getSystemParameter(parameterId) {
      let property = this.module.properties.find(prop => prop.property_collections.find(param => param.id === parameterId));
      if(property) {
        let parameter = property.property_collections.find(param => param.id === parameterId);
        if(parameter) {
          return parameter;
        }
      }
      return 'N/A';
    },
    getBgFromParameter(parameterId) {
      let property = this.module.properties.find(prop => prop.property_collections.find(param => param.id === parameterId));
      if(property) {
        let parameter = property.property_collections.find(param => param.id === parameterId);
        if(parameter && parameter.bg_color) {
          return parameter.bg_color;
        }
      }
      return "#2B80AD";
    },
    getModuleBg(module, level = 1) {
      return getModuleBg(module, level);
    },
    getBg(item) {
      if(!item) return;
      if (item && item.background_color) {
        return item.background_color;
      }
      let collectionSettings = this.getModuleSettings;
      if(collectionSettings && collectionSettings.collections.length > 0) {
        if(collectionSettings.collections[item.level].custom_bg) {
          return collectionSettings.collections[item.level].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(item.level);
        return colorCodes[collectionSettings.collections[item.level].color];
      }
      return "#2B80AD";
    },
    getParameterBg(value) {
      let definitionStyles = this.widget.definition_style;
      let valueIndex = Math.floor(value / 2);
      if(valueIndex > 5) {
        valueIndex = 4;
      }
      return definitionStyles[valueIndex].bg;
    },
  }
}
</script>

<style>

</style>
