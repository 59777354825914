<template>
  <div class="relative font-poppins h-full" :class="{'w-12': !isExpanded}">
    <div v-if="widget && widget.title" class="h-6">
      <WidgetTitle
          name="Module Content"
          v-show="isExpanded"
          v-model="widget.title"
          @updateWidgetTitle="$store.dispatch('roadmap/updateWidgetSettings', widget)"
      >
        <img src="/images/roadmap/delete_widget.svg" alt="icon"
             class="cursor-pointer"
             content="Delete Widget"
             v-tippy="{ placement : 'top',  arrow: true }"
             @click="() => { canEdit(project.cans) && onDeleteWidget() }"
        />
      </WidgetTitle>
    </div>
    <div v-else class="h-6 px-1">
      <div v-show="isExpanded" class="flex justify-between items-center">
        <h4 class="fs-10 fw-500 text-secondary-two">Module Content:
          <span class="text-primary-one" v-if="visibleLevel > 1">System Content</span>
        </h4>
        <div style="width: 255px; margin-right: 19px;" v-if="isExpanded && visibleLevel > 3">
          <RangeSlider
              :min="0"
              :max="10"
              :step="1"
              :ruler="false"
              :label="false"
              :minValue="0"
              :maxValue="10"
              @input="UpdateValues"
          />
        </div>
      </div>
    </div>

    <div
        class="w-full group-shadow-bg bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm"
        :class="{'w-12': !isExpanded}"
    >
      <CollapseHandle :isExpanded="isExpanded" class="absolute" :handler="handleCollapse"/>
      <div @click="handleLevelVisibility()" class="panzoom-exclude group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five handler">
        <img v-if="isExpanded" src="@/assets/images/roadmap/settings_icon.svg" alt="icon" class="widget-setting-icon" />
      </div>
    </div>

    <CollapsedBar
        :is-expanded="isExpanded"
        :module-id="module.id"
        title="Module Content:"
        sub-title="System content"
        :widget="widget"
    />

    <!-- <div
      v-if="!isExpanded"
      class="empty-widget-bar relative w-12 bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm mt-8 m-h-220 minimized-bar"
      :id="'collapsed_module_' + module.id"
      >
      <div class="h-full w-full bg-white shadow-two rounded-sm border-secondary-five border border-solid">
         <VerticalText
          title="Module Content:"
          :subTitle="widget && widget.title ? widget.title : 'System Content'"
        />
      </div>
    </div> -->

    <div class="flex justify-end mt-3">
<!--      <p v-if="!settings && isExpanded" class="truncate fs-8 fw-400  px-2">{{ getScenarioTitle }}</p>-->
      <p class="truncate fs-8 fw-400 px-2" style="visibility: hidden">Hidden</p>
    </div>

    <div v-if="!settings && isExpanded" class="relative flex" :id="'div_content_' + layout.id + '_' + module.id"
         :class="{'bg-primary-two': activeBg}"
         :style="{'min-width': visibleLevel > 3 ? '567px' : 'auto'}"
    style="margin-top: 5px;margin-left: 0;padding-right: 6px;padding-bottom: 12px;padding-top: 7px;">
      <div class="relative flex i-ml-6 module-level">
<!--        <div class="absolute inset-0 l0 outer-overlay rounded-sm" :class="{'bg-primary-two': activeBg}"></div>-->
<!--        <div class="absolute inset-0 l1 outer-overlay rounded-sm" :class="{'bg-primary-two': activeBg}"></div>-->
<!--        <div class="absolute inset-0 l2 outer-overlay rounded-sm" :class="{'bg-primary-two': activeBg}"></div>-->
<!--        <div class="absolute inset-0 l3 outer-overlay rounded-sm" :class="{'bg-primary-two': activeBg}"></div>-->
<!--        <div class="absolute inset-0 l4 rounded-sm" :class="{'bg-primary-two': activeBg}"></div>-->

        <div class="relative bg-primary-four rd-item rounded-sm border-solid shadow-two border-color-transparent cursor-pointer"
             :class="[getLayerClass(0), getAlignmentClass(0), getBorder(module)]"
             @click="()=>{canEdit(project.cans)&&setActiveModule()}"
             :style="{'background': getModuleBg()}">
          <div class="absolute view-icons" :class="[getLayerClass(0)]">
            <div class="flex items-center" :class="{'justify-center': layerOptions[0].layer === 1, 'justify-between': layerOptions[0].layer !== 1}">
              <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[0].layer !== 1" @click="previousLayer(0)">-</h1>
              <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[0].layer !== 3" @click="nextLayer(0)">+</h1>
            </div>
          </div>

          <VerticalText2
              :title="module.collections.length > 1 ? getIndexTitle() : ''"
              :full-title="module.title"
              v-if="layerOptions[0].layer === 1"
          />

          <div class="px-2 w-full" v-if="layerOptions[0].layer !== 1">
            <input type="text"
                   class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-white ls-5 font-poppins fs-13 fw-400 truncate"
                   :class="{'fw-600': getModuleChildCount > 1}"
                   @keyup="()=>updateModule(module)"
                   v-model="module.title" />
            <div v-if="getModuleChildCount > 2"
                 contenteditable="true"
                 @input="(event) => updateModule(module, event)"
                 class="bg-transparent text-white fs-13 fw-300 panzoom-exclude w-full"
                placeholder="Type description here">{{ module.description }}</div>
          </div>
        </div>
      </div>

      <div class="relative first-level" v-if="visibleLevel > 0">
        <div class="absolute view-icons i-ml-28" :class="[getLayerClass(1)]">
          <div class="flex items-center" :class="{'justify-center': layerOptions[1].layer === 1, 'justify-between': layerOptions[1].layer !== 1}">
            <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[1].layer !== 1" @click="previousLayer(1)">-</h1>
            <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[1].layer !== 3" @click="nextLayer(1)">+</h1>
          </div>
        </div>

        <div v-for="(l1, l1Index) in getData(module.collections)"
             :key="l1Index"
             class="flex l1child"
             :id="`l${l1.level}_${layout.id}_${l1.id}`"
             :style="{'min-height': l1.own_height + 'px'}"
             :class="[l1Index !== 0 ? getFirstLevelGap(l1) : '']">
          <div class="rd-item rounded-sm flex relative shadow-two border-color-transparent cursor-pointer i-ml-30 border-solid"
            :style="{'background': appearance === 0 && l1.appearance === 0 ? getBg(1, l1, [module.collections.length]) : 'transparent'}"
            :id="'l1_item_' + l1.id"
            :class="{
                'opacity-0': l1.fromParent,
                'i-border-1': activeData.id === l1.id || l1.appearance === 1,
                'text-white textarea-primary-three shadow-two' : l1.appearance === 0,
                'text-primary-one textarea-secondary-one' : l1.appearance !== 0,
                'border-secondary-five' : l1.appearance === 1 && activeData.id !== l1.id,
                'border-primary-one i-border-1 active' : activeData && activeData.id === l1.id,
                'layer-2': layerOptions[1].layer === 2,
                'layer-3': layerOptions[1].layer === 3,
                'items-center': layerOptions[1].layer !== 1,
                'items-baseline': layerOptions[1].layer === 1
            }"
            @click="()=>{setActiveScenarioCollection(l1)}"
          >

            <div class="action_items grid">
              <button @click="menuHandler($event, l1)" title="Information">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                  <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                </svg>
              </button>
              <button @click="canCreate(project.cans)&&createNewItem(l1)" title="Create">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                </svg>
              </button>
              <button title="Delete" @click="canDelete(project.cans) && deleteLevelItem(l1.id)">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                </svg>
              </button>
            </div>
            <VerticalText2
                :title="getTitle(l1, l1Index)"
                :full-title="`${l1.index_code + ' ' + l1.title}`"
                :text-color="l1.appearance === 0 ? 'text-white' : 'text-primary-one'"
                v-if="layerOptions[1].layer === 1"
            />

            <div class="px-2 w-full" v-if="layerOptions[1].layer !== 1">
              <div class="flex">
                <span
                  class="text-white ls-5 font-poppins fs-13 fw-400 mr-1" v-if="indexTitle"
                  :class="{'text-primary-one textarea-secondary-one' : l1.appearance !== 0, 'fw-600': l1.child_count > 1}">{{ l1.index_id }}{{ l1.index_code }}</span>
                <input type="text"
                      class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-white ls-5 font-poppins fs-13 fw-400 truncate"
                      :class="{'fw-600': l1.child_count > 1}"
                      @keyup="()=>regularUpdate(l1)"
                      v-model="l1.title" />
              </div>
              <div v-if="l1.child_count > 2" :data-child-count="l1.child_count" :style="{maxHeight: l1.child_count * 20 + 'px'}" contenteditable="true" @blur="(event) => regularUpdate(l1, event)" class="bg-transparent text-white fs-13 fw-300 panzoom-exclude w-full overflow-auto whitespace-pre-wrap" placeholder="Type description here" v-html="l1.description"></div>
            </div>
          </div>
          <jumped-data v-if="l1.child.length <= 0"
                       :layout="layout"
                       :layer-options="layerOptions"
                       @contextmenu.prevent="menuHandler($event, l1)"
                       :item="l1"
                       :level="1"
                       :index-title="indexTitle"
                       :description="description"
                       :appearance="appearance"
                       :module-settings="getModuleSettings"
                       @regularUpdate="() => { canEdit(project.cans) && regularUpdate(l1) }"
                       @deleteItem="canDelete(project.cans)&&deleteItem"
                       @menuHandler="menuHandler"
                       @setActiveScenarioCollection="setActiveScenarioCollection" />
          <div class="relative second-level" v-if="visibleLevel > 1">
            <div v-for="(l2, l2Index) in getData(l1.child, 1)" :key="l2Index" class="flex"
                 :id="`l${l2.level}_${layout.id}_${l2.id}`"
                 :style="{'min-height': l2.own_height + 'px'}"
                 :class="[l2Index !== 0 ? checkForGap(l1, l2, l2Index) : '']">
              <div class="rd-item rounded-sm flex relative shadow-two border-color-transparent cursor-pointer i-ml-30 border-solid"
                   :style="{'background': appearance === 0 && l2.appearance === 0 ? getBg(2, l2, [module.collections.length, l1.child_count]) : 'transparent'}"
                   :class="{
                      'opacity-0': l2.fromParent,
                      'i-border-1': activeData.id === l2.id || l2.appearance === 1,
                      'text-white textarea-primary-three shadow-two' : l2.appearance === 0,
                      'text-primary-one textarea-secondary-one' : l2.appearance !== 0,
                      'border-secondary-five' : l2.appearance === 1 && activeData.id !== l2.id,
                      'border-primary-one i-border-1 active' : activeData && activeData.id === l2.id,
                      'layer-2': layerOptions[2].layer === 2,
                      'layer-3': layerOptions[2].layer === 3,
                      'items-center': layerOptions[2].layer !== 1,
                      'items-baseline': layerOptions[2].layer === 1
                    }"
                    :id="'l2_item_' + l2.id"
                    @click="()=>{setActiveScenarioCollection(l2)}"
                >
                  <div class="action_items grid">
                    <button @click="menuHandler($event, l2)" title="Information">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                        <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                      </svg>
                    </button>
                    <button @click="canCreate(project.cans)&&createNewItem(l2)" title="Create">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                      </svg>
                    </button>
                    <button title="Delete" @click="canDelete(project.cans) && deleteLevelItem(l2.id)">
                      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                      </svg>
                    </button>
                  </div>

                  <VerticalText2
                      :title="getTitle(l2, l2Index)"
                      :full-title="`${l2.index_code + ' ' + l2.title}`"
                      :text-color="l2.appearance === 0 ? 'text-white' : 'text-primary-one'"
                      v-if="layerOptions[2].layer === 1"
                  />

                <div class="px-2 w-full" v-if="layerOptions[2].layer !== 1">
                  <div class="flex">
                    <span class="text-white ls-5 font-poppins fs-13 fw-400 mr-1" v-if="indexTitle"
                              :class="{'text-primary-one textarea-secondary-one' : l2.appearance !== 0, 'fw-600': l2.child_count > 1}">{{ l2.index_id }}{{ l2.index_code }}</span>
                    <input type="text"
                           class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-white ls-5 font-poppins fs-13 fw-400 truncate"
                           :class="{'fw-600': l2.child_count > 1}"
                           v-model="l2.title" @keyup="()=>regularUpdate(l2)" />
                  </div>
                  <div v-if="l2.child_count > 2" :data-child-count="l2.child_count" :style="{maxHeight: l2.child_count * 20 + 'px'}" contenteditable="true" @blur="(event) => regularUpdate(l2, event)" class="bg-transparent text-white fs-13 fw-300 panzoom-exclude w-full overflow-auto whitespace-pre-wrap" placeholder="Type description here" v-html="l2.description"></div>
                </div>
              </div>
              <jumped-data v-if="l2.child.length <= 0"
                :layout="layout"
                :layer-options="layerOptions"
                @contextmenu.prevent="menuHandler($event, l2)"
                :item="l2"
                :level="2"
                :index-title="indexTitle"
                :description="description"
                :appearance="appearance"
                :module-settings="getModuleSettings"
                @regularUpdate="() => { canEdit(project.cans) && regularUpdate(l2) }"
                @deleteItem="canDelete(project.cans)&&deleteItem"
                @menuHandler="menuHandler"
                @setActiveScenarioCollection="setActiveScenarioCollection" />
              <div class="relative third-icons third-level" v-if="visibleLevel > 2">
                <div v-for="(l3, l3Index) in getData(l2.child, 2)" :key="l3Index"
                     class="flex"
                     :style="{'min-height': l3.own_height + 'px'}"
                     :id="`l${l3.level}_${layout.id}_${l3.id}`"
                     :class="[l3Index !== 0 ? checkForGap(l1, l2, l2Index) : '']">
                  <div class="rd-item rounded-sm flex relative shadow-two border-color-transparent cursor-pointer i-ml-30"
                    :style="{ 'background': appearance === 0 && l3.appearance === 0 ? getBg(3, l3, [module.collections.length, l1.child_count, l2.child_count]) : 'transparent' }"
                    :id="'l3_item_' + l3.id"
                    :class="{
                      'opacity-0': l3.fromParent,
                      'i-border-1': activeData.id === l3.id || l3.appearance === 1,
                      'text-white textarea-primary-three shadow-two' : l3.appearance === 0,
                      'text-primary-one textarea-secondary-one' : l3.appearance !== 0,
                      'border-secondary-five' : l3.appearance === 1 && activeData.id !== l3.id,
                      'border-primary-one i-border-1 active' : activeData.id === l3.id,
                      'layer-2': layerOptions[3].layer === 2,
                      'layer-3': layerOptions[3].layer === 3,
                      'items-center': layerOptions[3].layer !== 1,
                      'items-baseline': layerOptions[3].layer === 1
                    }"
                    @click="()=>{setActiveScenarioCollection(l3)}"
                  >
                    <div class="action_items grid">
                      <button @click="menuHandler($event, l3)" title="Information">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                          <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                        </svg>
                      </button>
                      <button @click="canCreate(project.cans)&&createNewItem(l3)" title="Create">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                        </svg>
                      </button>
                      <button title="Delete" @click="canDelete(project.cans) && deleteLevelItem(l3.id)">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                        </svg>
                      </button>
                    </div>
                    <VerticalText2
                        :title="getTitle(l3, l3Index)"
                        :full-title="`${l3.index_code + ' ' + l3.title}`"
                        :text-color="l3.appearance === 0 ? 'text-white' : 'text-primary-one'"
                        v-if="layerOptions[3].layer === 1"
                    />

                    <div class="px-2 w-full" v-if="layerOptions[3].layer !== 1">
                      <div class="flex">
                              <span class="text-white ls-5 font-poppins fs-13 fw-400 mr-1" v-if="indexTitle"
                                    :class="{'text-primary-one textarea-secondary-one' : l3.appearance !== 0, 'fw-600': l3.child_count > 1}">{{ l3.index_id }}{{ l3.index_code }}</span>
                        <input type="text"
                               @keyup="()=>regularUpdate(l3)"
                               class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-white ls-5 font-poppins fs-13 fw-400 truncate"
                               :class="{'fw-600': l3.child_count > 1}"
                               v-model="l3.title" />
                      </div>
                      <div v-if="l3.child_count > 2" :data-child-count="l3.child_count" :style="{maxHeight: l3.child_count * 20 + 'px'}" contenteditable="true" @blur="(event) => regularUpdate(l3, event)" class="bg-transparent text-white fs-13 fw-300 panzoom-exclude w-full overflow-auto whitespace-pre-wrap" placeholder="Type description here" v-html="l3.description"></div>
                    </div>
                  </div>

                  <jumped-data
                    v-if="l3.child.length <= 0"
                    :layout="layout"
                    :layer-options="layerOptions"
                    @contextmenu.prevent="menuHandler($event, l3)"
                    :item="l3"
                    :level="3"
                    :index-title="indexTitle"
                    :description="description"
                    :appearance="appearance"
                    :module-settings="getModuleSettings"
                    @regularUpdate="() => { canEdit(project.cans) && regularUpdate(l3) }"
                    @deleteItem="canDelete(project.cans)&&deleteItem"
                    @menuHandler="menuHandler"
                    @setActiveScenarioCollection="setActiveScenarioCollection"
                  />

                  <div :class="{
                    'layer-1': layerOptions[4].layer === 1,
                    'layer-2': layerOptions[4].layer === 2,
                    'layer-3': layerOptions[4].layer === 3,
                  }" class="i-ml-30" v-if="getData(l3.child).length > 0 && visibleLevel > 3">
                    <div v-for="(l4, l4Index) in getData(l3.child, 3)" :key="l4Index" :id="`l${l4.level}_${layout.id}_${l4.id}`"  class="flex" :class="[l4Index !== 0 ? getLastLevelGap(l4) : '']">
                      <div
                        class="px-2 rd-item rounded-sm flex relative items-center border-color-transparent cursor-pointer border"
                        :style="{
                            'background': appearance === 0 && l4.appearance === 0 ? getBg(4, l4, [module.collections.length, l1.child_count, l2.child_count, l3.child_count]) : 'transparent',
                            visibility: getItemVisibility(layout, l4) ? 'visible' : 'hidden'
                          }"
                        :id="'l4_item_' + l4.id"
                        :class="{
                          'i-border-1': activeData.id === l4.id || l4.appearance === 1,
                          'text-white textarea-primary-three shadow-two' : l4.appearance === 0,
                          'text-primary-one textarea-secondary-one' : l4.appearance !== 0,
                          'border-secondary-five' : l4.appearance === 1 && activeData.id !== l4.id,
                          'border-primary-one i-border-1 active' : activeData.id === l4.id,
                          'layer-2': layerOptions[4].layer === 2,
                          'layer-3': layerOptions[4].layer === 3,
                        }"
                        @click="setActiveScenarioCollection(l4)"
                      >
                        <div class="action_items grid">
                          <button @click="menuHandler($event, l4)" title="Information">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333Z" fill="#8D8D8D"/>
                              <path d="M6.50431 5.072C6.26431 5.072 6.06698 5.00267 5.91231 4.864C5.76298 4.72 5.68831 4.544 5.68831 4.336C5.68831 4.12267 5.76298 3.94667 5.91231 3.808C6.06698 3.664 6.26431 3.592 6.50431 3.592C6.73898 3.592 6.93098 3.664 7.08031 3.808C7.23498 3.94667 7.31231 4.12267 7.31231 4.336C7.31231 4.544 7.23498 4.72 7.08031 4.864C6.93098 5.00267 6.73898 5.072 6.50431 5.072ZM7.18431 5.536V10H5.81631V5.536H7.18431Z" fill="#8D8D8D"/>
                            </svg>
                          </button>
                          <button @click="canCreate(project.cans)&&createNewItem(l4)" title="Create">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.000650114 6.66667C0.000650275 2.98477 2.98542 -4.5235e-07 6.66732 -2.91409e-07C10.3492 -1.30468e-07 13.334 2.98477 13.334 6.66667C13.334 10.3486 10.3492 13.3333 6.66732 13.3333C2.98709 13.3293 0.00469163 10.3469 0.000650114 6.66667ZM6.55265 1.33333C3.61849 1.36488 1.26095 3.76074 1.27665 6.69503C1.2925 9.62931 3.67565 11.9997 6.60999 11.9997C9.54431 11.9997 11.9275 9.62931 11.9433 6.69503C11.959 3.76074 9.60148 1.36488 6.66732 1.33333L6.55265 1.33333ZM3.33398 7.33333L3.33398 6L6.00065 6L6.00065 3.33333L7.33398 3.33333L7.33398 6L10.0007 6L10.0007 7.33333L7.33398 7.33333L7.33398 10L6.00065 10L6.00065 7.33333L3.33398 7.33333Z" fill="#8D8D8D"/>
                            </svg>
                          </button>
                          <button title="Delete" @click="canDelete(project.cans)&&deleteItem(l4.id)">
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.000650114 6.66691C0.000650275 2.98501 2.98542 0.000243688 6.66732 0.000243849C10.3492 0.00024401 13.334 2.98501 13.334 6.66691C13.334 10.3488 10.3492 13.3336 6.66732 13.3336C2.98709 13.3295 0.00469163 10.3471 0.000650114 6.66691ZM6.55265 1.33358C3.61849 1.36512 1.26095 3.76098 1.27665 6.69527C1.2925 9.62956 3.67565 11.9999 6.60999 11.9999C9.54431 11.9999 11.9275 9.62956 11.9433 6.69527C11.959 3.76098 9.60148 1.36512 6.66732 1.33358L6.55265 1.33358ZM3.33398 7.33358L3.33398 6.00024L6.00065 6.00024L7.33398 6.00024L10.0007 6.00024L10.0007 7.33358L7.33398 7.33358L6.00065 7.33358L3.33398 7.33358Z" fill="#8D8D8D"/>
                            </svg>
                          </button>
                        </div>

                        <VerticalText2
                            :title="getTitle(l4, l4Index)"
                            :full-title="`${l4.index_code + ' ' + l4.title}`"
                            :text-color="l4.appearance === 0 ? 'text-white' : 'text-primary-one'"
                            v-if="layerOptions[4].layer === 1"
                        />

                        <div class="flex" v-else>
                          <span class="text-white ls-5 font-poppins fs-12 fw-400" v-if="indexTitle" :class="{'text-primary-one textarea-secondary-one' : l4.appearance !== 0,}">{{ l4.index_id }}{{ l4.index_code }}</span>
                          <input
                              type="text"
                              class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer truncate text-white ls-5 font-poppins fs-12 fw-400"
                              :disabled="!canEdit(project.cans)"
                              v-model="l4.title"
                              :class="{'ml-1': indexTitle, 'text-primary-one textarea-secondary-one' : l4.appearance !== 0}"
                              @change="()=>regularUpdate(l4)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="absolute second-view-icons i-ml-28" v-if="visibleLevel > 1 && levelDataExistence(2)" :style="{'left': getIconPosition(2)}" :class="[getLayerClass(2)]">
        <div class="flex items-center" :class="{'justify-center': layerOptions[2].layer === 1, 'justify-between': layerOptions[2].layer !== 1}">
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[2].layer !== 1" @click="previousLayer(2)">-</h1>
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[2].layer !== 3" @click="nextLayer(2)">+</h1>
        </div>
      </div>

      <div class="absolute third-view-icons i-ml-28" v-if="visibleLevel > 2 && levelDataExistence(3)" :style="{'left': getIconPosition(3)}" :class="[getLayerClass(3)]">
        <div class="flex items-center" :class="{'justify-center': layerOptions[3].layer === 1, 'justify-between': layerOptions[3].layer !== 1}">
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[3].layer !== 1" @click="previousLayer(3)">-</h1>
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[3].layer !== 3" @click="nextLayer(3)">+</h1>
        </div>
      </div>

      <div class="absolute fourth-view-icons i-ml-28" v-if="visibleLevel > 3 && levelDataExistence(4)" :style="{'left': getIconPosition(4)}" :class="[getLayerClass(4)]">
        <div class="flex items-center" :class="{'justify-center': layerOptions[4].layer === 1, 'justify-between': layerOptions[4].layer !== 1}">
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[4].layer !== 1" @click="previousLayer(4)">-</h1>
          <h1 class="text-primary-one fs-16 fw-800 cursor-pointer" v-if="layerOptions[4].layer !== 3" @click="nextLayer(4)">+</h1>
        </div>
      </div>
    </div>

    <context-menu v-click-outside="closeMenuHandler"
                  :item="contextmenuItem"
                  :menu="getMenu"
                  :id="'context-menu-'+module.id"
                  v-show="contextmenu" class="absolute"
                  @appearance="applyAppearance"
                  @appearanceSelf="applyAppearanceSelf"
                  @indexTitle="applyIndexTitle"
                  @background="applyBackground"
                  @description="applyDescription"
                  @addNew="addNew"
                  @saveIsolation="saveIsolation"
                  @removeIsolation="removeIsolation"
                  @deleteLevelItem="deleteLevelItem" />
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'

const { canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
// import VerticalText from '@/components/roadmap/canvas/widgets/VerticalText'
import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
import VerticalText2 from '@/components/roadmap/canvas/widgets/VerticalText2'
import ContextMenu from '@/components/programmatic/ContextMenu'
import {mapActions, mapGetters} from 'vuex';
import CollapseHandle from '@/components/roadmap/canvas/widgets/CollapseHandle'
import { deleteWidget } from '@/store/modules/roadmap/services'
import WidgetTitle from '@/components/roadmap/WidgetTitle.vue'
import DualRangeSlider from "@/components/programmatic/DualRangeSlider";
import { getItemVisibility } from '@/store/modules/roadmap/services.js'
import JumpedData from "@/components/roadmap/canvas/Container/JumpedData";
import RangeSlider from '@/components/roadmap/RangeSlider.vue'
import Collection from "@/store/services/traits/collection";
import {cloneDeep, uniqBy} from "lodash";

export default {
  name: "GroupData",
  components: {
    JumpedData,
    CollapseHandle,
    CollapsedBar,
    VerticalText2,
    ContextMenu,
    WidgetTitle,
    DualRangeSlider,
    RangeSlider
  },
  props: ['module', 'layout', 'defaultWidget', 'widget', 'toggleShowContentTitle', 'flatData', 'filteredCollections', 'isolatedModules'],
  data() {
    return {
      ownFlatData: [],
      isExpanded: true,
      settings: false,
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      layerOptions: [
        { layer: 1 },
        { layer: 1 },
        { layer: 1 },
        { layer: 1 },
        { layer: 2 },
      ],
      hasChildInLevel: [false, false, false, false, false],
      visibleLevel: 4,
      visibleMode: 'desc',
      l3Existence: [],
      dataLevelExistence: [
        { exist: false },
        { exist: false },
        { exist: false },
        { exist: false }
      ],
      collectionInstance: new Collection
    }
  },
  computed: {
    ...mapGetters({
      modules: "programmatic/projectModules",
      colorCodes: "global/colorCodes",
      activeData: "programmatic/activeScenarioCollection",
      activeRoadmapData: "roadmap/programmatic",
      project: "project/project"
    }),
    getModuleSettings() {
      let module = this.modules.find(item => parseInt(item.id) == parseInt(this.module.id));
      if (module) {
        return JSON.parse(module.collection_settings);
      }
      return null;
    },
    activeBg() {
      return !!(this.layout && this.layout.settings && this.layout.settings.color);
    },
    getScenarioTitle() {
      if(this.activeRoadmapData && this.activeRoadmapData.scenario && this.activeRoadmapData.scenario.title) {
        return this.activeRoadmapData.scenario.title;
      }
      return 'N/A';
    },
    getMaxLevelVisibility() {
      let data = this.filteredCollections.map(item => item.level);
      return Math.max(...data);
    },
    getLevelData() {
      if(this.module) {
        return this.module.collections;
      }
      return [];
    },
    getMenu() {
      let menu = {
        description: false,
        isolation: true,
        remove_isolation: false
      };
      if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
        menu.remove_isolation = true;
      }
      return menu;
    },
    getModuleChildCount() {
      let count = 0;
      this.getData(this.module.collections).forEach(item => {
        count += item.child_count > 1 ? item.child_count : 1;
      });
      return count;
    }
  },
  watch: {
    isolatedModules: {
      handler: function (val, oldVal) {
        this.flatData.forEach(item => {
          item.own_height_generated = false;
          item.own_height = 0;
        });
        this.ownFlatData = [];
        setTimeout(() => {
          this.calculateOwnElementHeight();
        }, 2000);
      },
      deep: true
    },
    layout: {
      handler: function(val, oldVal) {
        if(val.expanded) {
          setTimeout(() => {
            this.calculateOwnElementHeight();
          }, 1000);
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      storeItem: "roadmap/storeScenarioCollectionItem",
      deleteData: "programmatic/deleteLevelData"
    }),
    handleLevelVisibility() {
      if(this.visibleLevel === 4) {
        this.visibleLevel -= 1;
        this.visibleMode = 'desc';
      } else if(this.visibleLevel === 1) {
        this.visibleLevel += 1;
        this.visibleMode = 'asc';
      } else {
        if(this.visibleMode === 'desc') {
          this.visibleLevel -= 1;
          this.visibleMode = 'desc';
        } else {
          this.visibleMode = 'asc';
          this.visibleLevel += 1;
        }
      }
    },
    getBorder(module) {
      if(this.activeData && this.activeData.id && this.activeData.is_module && this.activeData.id == module.id) {
        return 'i-border-1 border-primary-one';
      }
    },
    getL3Existence(index) {
      return false;
    },
    getLayerClass(index) {
      return 'layer-' + this.layerOptions[index].layer;
    },
    getSecondLevelIconPosition() {
      if(this.layerOptions[3].layer > 1) {
        return 'second-view-icons-expand-l3-' + this.layerOptions[3].layer;
      }
      return "";
    },
    getIconPosition(level) {
      let left = 0;
      let leftMargins = [0, 40, 275, 550];
      let dataSelfMargins = [6, 30, 30, 30];
      this.layerOptions.forEach((item, index) => {
        if(level > index) {
          left += leftMargins[this.layerOptions[index].layer] + dataSelfMargins[index];
        }
      });
      return left + 'px';
    },
    getAlignmentClass(index) {
      if(this.layerOptions[index].layer !== 1) {
        return "flex items-center";
      }
      return "";
    },
    nextLayer(index) {
      if(this.layerOptions[index].layer !== 3) {
        this.layerOptions[index].layer = this.layerOptions[index].layer + 1;
      }
    },
    previousLayer(index) {
      if(this.layerOptions[index].layer !== 1) {
        this.layerOptions[index].layer = this.layerOptions[index].layer - 1;
      }
    },
    updateModule(objData, event = null) {
      let data = {
        id: objData.id,
        title: objData.title,
        description: objData.description
      }
      if(event) {
        data.description = event.target.innerText;
      }
      this.$store.dispatch("build/updateModule", data);
    },
    UpdateValues(e) {
      let range = this.layout.propertyRange
      if(!range) {
        this.$set(this.layout, 'propertyRange', {min: 0, max: 10})
      }
      if(range) {
        range.min = e.minValue
        range.max = e.maxValue
      }
    },
    getData(items, parentLevel = 0) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      let levelItems = items;
      if(moduleInIsolation && moduleInIsolation.isolate) {
        levelItems = items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      } else if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
        levelItems = items.filter(data => this.layout.isolated_ids.includes(data.id) && !data.root);
      }
      if(parentLevel > 0) {
        if(levelItems.length > 0) {
          this.hasChildInLevel[parentLevel] = true;
        }
      }
      if(parentLevel === 3) {
        if(levelItems.length > 0) {
          this.hasChildInLevel[4] = true;
        }
      }
      return levelItems;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    handleCollapse() {
      this.isExpanded = !this.isExpanded
      // this.toggleShowContentTitle()
    },
    getBg(level, item, parentChildArray) {
      item.parent_child_array = parentChildArray;
      if(!item) return
      if (item && item.background_color) {
        return item.background_color;
      }
      let settings = cloneDeep(this.module);
      let collectionSettings = JSON.parse(settings.collection_settings);
      if(collectionSettings && collectionSettings.collections.length > 0) {
        if(collectionSettings.collections[level] && collectionSettings.collections[level].custom_bg) {
          return collectionSettings.collections[level].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collectionSettings.collections[level].color];
      }
      return null;
    },
    getIndexTitle(parentCodeIndex = 0) {
      let moduleSettings = this.getModuleSettings;
      if(moduleSettings && moduleSettings.collections) {
        return parseInt(moduleSettings.collections[parentCodeIndex].index_number);
      } else {
        return '';
      }
    },
    setActiveScenarioCollection(item) {
      let data = this.flatData.find(data => data.id === item.id);
      this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", data);
      this.$store.commit("build/setModulePropertySet", this.module.properties);
      this.$emit("toggleToPropertyMode");
    },
    setActiveModule() {
      let module = this.module;
      module.is_module = true;
      this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", module);
      this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", this.module.id);
      this.$emit("toggleToPropertyMode");
    },
    getModuleBg(level = 1) {
      let collectionSettings = this.getModuleSettings;
      if(collectionSettings && collectionSettings.collections.length > 0) {
        if(collectionSettings.collections[0].custom_bg) {
          return collectionSettings.collections[0].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collectionSettings.collections[level].color];
      }
      return null;
    },
    onDeleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    checkForGap(parent, item, selfIndex) {
      if(selfIndex > 0) {
        let lastItem = parent.child[selfIndex - 1];
        if(lastItem) {
          if(lastItem.child_count > 1) {
            item.gap = 'mt-8';
            return 'mt-8';
          } else {
            if(item.child_count > 1) {
              item.gap = 'mt-8';
              return 'mt-8';
            } else {
              item.gap = 'mt-3';
              return 'mt-3';
            }
          }
        }
        item.gap = 'mt-3';
        return 'mt-3';
      } else {
        if(item.child_count > 1) {
          item.gap = 'mt-8';
          return 'mt-8';
        } else {
          item.gap = 'mt-3';
          return 'mt-3';
        }
      }
    },
    getFirstLevelGap(item) {
      item.gap = 'mt-8';
      return 'mt-8';
    },
    getLastLevelGap(item) {
      item.gap = 'mt-3';
      return 'mt-3';
    },
    regularUpdate(obj, event = null) {
        if(event) {
            obj.description = event.target.innerText;
        }
      this.$store.dispatch("programmatic/updateCollection", obj);
    },
    getTitle(item, index) {
      if(item.child_count >= 3) {
        return `${this.indexTitle ? item.index_code : '' + ' ' + item.title}`;
      } else {
        return item.child_count >= 2 ? `${this.indexTitle ? item.index_code : '' }` : (index + 1)
      }
    },
    menuHandler(e, item) {
      this.contextmenu = true
      this.contextmenuItem = item
      let top = e.clientY - this.$el.getBoundingClientRect().y
      let left = e.clientX - this.$el.getBoundingClientRect().x
      let context = document.getElementById('context-menu-' + item.module_id)
      context.style.left = left+'px'
      context.style.top = top+'px'
    },
    closeMenuHandler(e) {
      let module_id = this.module.id
      let context = document.getElementById('context-menu-' + module_id);
      if (context && !context.contains(e.target) && !e.target.closest('.action_items')) {
        this.contextmenu = false
      }
    },
    applyAppearance(value) {
      this.appearance = value
      let data = this.getLevelData
      this.setAppearanceToAll(data, value)
      this.contextmenu = false
    },
    setAppearanceToAll(data, value) {
      data.forEach(el => {
        el.appearance = value
        if (el.child && el.child.length > 0) {
          this.setAppearanceToAll(el.child, value)
        }
      });
    },
    applyAppearanceSelf(value) {
      this.contextmenuItem.appearance = value;
      this.contextmenu = false
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyDescription(value) {
      this.description = value
      this.contextmenu = false
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = {... this.contextmenuItem}
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.module.id) {
        this.$store.dispatch("build/regularUpdate", obj)
      }
      if (this.activeRoadmapData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false
    },
    saveIsolation() {
      let isolatedData = this.layout.isolated_ids;
      let active_isolated_id = null;
      let isolatedModule = this.isolatedModules.find(data => data.module_id == this.module.id);
      if(isolatedModule && isolatedModule.isolatedIds) {
        isolatedData = isolatedModule.isolatedIds;
        active_isolated_id = isolatedModule.isolatedItemId;
      }
      this.contextmenu = false;
      this.layout.isolated_ids = isolatedData;
      this.layout.active_isolated_id = active_isolated_id;
      let layout = cloneDeep(this.layout);
      delete layout.widgets;
      this.$Progress.start();
      this.$store.dispatch("roadmap/updateLayout", layout).then(response => {
        this.$Progress.finish();
      });
    },
    removeIsolation() {
      this.layout.isolated_ids = [];
      this.layout.active_isolated_id = null;
      let layout = cloneDeep(this.layout);
      this.contextmenu = false;
      this.$Progress.start();
      this.$store.dispatch("roadmap/updateLayout", layout).then(response => {
        this.$Progress.finish();
      });
    },
    addNew(value) {
      let parent = this.contextmenuItem
      let chcount = parent.child.length
      let order = 1;
      if (chcount > 0) {
        order = chcount + 1
      }
      let obj = {};
      obj.parent_id = parent.id;
      obj.title = this.getModuleSettings.collections[parent.level + 1].title;
      obj.level = parent.level + 1;
      obj.item_order = order;
      obj.order = order;
      obj.module_id = parent.module_id;
      obj.scenario_id = parent.scenario_id;
      this.$Progress.start();
      this.createNewItem(obj);
      this.contextmenu = false;
    },
    createNewItem(item) {
      let data = cloneDeep(item);
      data.project_id = this.$route.params.id;
      data.title = this.getModuleSettings.collections[data.level].title;
      this.$Progress.start();
      this.storeItem(data).then(response => {
        this.$Progress.finish();
        let module = this.isolatedModules.find(module => module.module_id == data.module_id);
        if(module) {
          let collection = module.isolatedIds.find(collection => collection == item.parent_id);
          if(collection) {
            if(response.data.data.id) {
              module.isolatedIds.push(response.data.data.id);
            }
          }
        }

        if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
          let activeItem = this.layout.isolated_ids.includes(data.parent_id);
          if(activeItem) {
            this.layout.isolated_ids.push(response.data.data.id);
            let layout = cloneDeep(this.layout);
            delete layout.widgets;
            this.$store.dispatch("roadmap/updateLayout", layout)
          }
        }

        this.$emit("loadAModuleData");
      })
    },
    deleteItem(id) {
      if(confirm("Are you sure want to delete this?")) {
        this.$Progress.start();
        this.deleteData({
          project_id: this.$route.params.id,
          scenario_id: this.activeRoadmapData.scenario_id,
          level_id: id
        }).then(response => {
          this.$Progress.finish();
          this.$emit('loadAModuleData');
        });
      }
    },
    deleteLevelItem(levelId=null) {
      if(confirm("Are you sure want to delete this?")) {
        this.$Progress.start();
        this.deleteData({
          project_id: this.$route.params.id,
          scenario_id: this.activeRoadmapData.scenario_id,
          level_id: levelId ? levelId : this.contextmenuItem.id
        }).then(response => {
          this.$Progress.finish();
          this.contextmenu = false;
          this.$emit('loadAModuleData');
        });
      }
    },
    getItemVisibility(item) {
      return getItemVisibility(this.layout, item)
    },
    calculateOwnElementHeight() {
      if(this.flatData.length !== this.ownFlatData.length && this.layout.expanded) {
        this.flatData.forEach(item => {
          if(!item.own_height_generated) {
            item.own_height_generated = true;
            let element = document.getElementById(`l${item.level}_${this.layout.id}_${item.id}`);
            if(element && element.clientHeight) {
              item.own_height = element.clientHeight;
            } else {
              item.own_height = (item.child_count * 20) + ((item.child_count - 1) * 12);
            }
            this.ownFlatData.push(item);
          }
        });
      }
    },
    collectionFlatten(items) {
      let flatten = (arr) => {
        let data = [];
        if(!arr) {
          return
        }
        arr.forEach(recursiveFlatten);
        function recursiveFlatten(item) {
          data.push(item);
          if (item.child && item.child.length > 0) {
            item.child.forEach((child) => {
              recursiveFlatten(child);
            });
          }
        }
        return data;
      }
      return uniqBy(flatten(items), 'id');
    },
    levelDataExistence(level = 1) {
      // let dataIndex = this.flatData.findIndex(item => parseInt(item.level) === parseInt(level));
      // return dataIndex >= 0;
      return this.dataLevelExistence[level - 1].exist;
    },
    checkLevelExistence(level, collections) {
      if(collections.length > 0) {
        if(level <= 4) {
          this.dataLevelExistence[level - 1].exist = true
        }

        level++;
        collections.forEach(item => {
          this.checkLevelExistence(level, item.child)
        })
      }
    }
  },
  created() {
    const self = this;
    self.checkLevelExistence(1, this.module.collections)
    setTimeout(() => {
      self.calculateOwnElementHeight();
    }, 2000);
  },
  mounted()  {
    this.moduleHeight = document.getElementById(`div_content_${this.layout.id}_${this.module.id}`).clientHeight;
  }
}
</script>

<style scoped>
.action_items{
  gap: 5px;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 100%;
  padding: 0 5px;
  z-index: 20;
}
div:is(.rd-item, .rd-last-item):is(.active) .action_items{
  visibility: visible;
}
.custom-height {
  height: calc(100% - 110px);
}
.inner {
  font-size: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.rotate {
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
}

.mb-24 {
  margin-bottom: 24px;
}
.i-mb-8 {
  margin-bottom: 8px;
}

.outer-overlay {
  width: 130%;
  height: calc(100% + 11px);
  top: -5px;
}
.l0 {
  left: -6px;
  width: 130%;
  height: calc(100% + 11px);
  top: -5px;
}

.l1 {
  left: 64px;
}
.l2 {
  left: 134px;
}
.l3 {
  left: 204px;
}
.l4 {
  left: 274px;
  width: 718%;
  height: calc(100% + 11px);
  top: -5px;
}

.rd-item {
  width: 40px;
  min-height: 20px;
}
.rd-last-item {
  height: 20px;
  min-width: 275px;
}
.group-shadow-bg {
  height: 50px;
}
.group-setting {
  height: 40px;
}
.widget-setting-icon {
  width: 13px;
  height: 13px;
}
.module-content-width {
  width: 470px;
}
.view-icons {
  top: -32px;
  left: 0;
  width: inherit;
}
.second-view-icons {
  top: -26px;
  left: 118px;
  width: inherit;
}
.second-view-icons-expand-l3-2 {
  right: 610px;
}
.second-view-icons-expand-l3-3 {
  right: 885px;
}
.third-view-icons {
  top: -26px;
  left: 188px;
  width: inherit;
}
.fourth-view-icons {
  top: -26px;
  left: 248px;
  /*right: 305px;*/
  width: inherit;
}
.layer-1 {
  width: 40px;
}
.layer-2 {
  width: 275px;
}
.layer-3 {
  width: 550px;
}
</style>
