import useSetting from '@/components/roadmap/canvas/widgets/useSetting.js'
import { initialData } from './column.data';

export default function useBusinessSettings() {
    const {
        definitionSets,
        rangeDefinitions,
        updateWidgetSettings,
        pickerPositionOffset,
        getDataByIsolatedModules,
        updateParameterValue
    } = useSetting()

    const addItem = (columns) => {
        columns.push(JSON.parse(JSON.stringify(initialData)))
    }
    const removeItem = (columns, index, widget) => {
        columns.splice(index, 1)
        updateWidgetSettings(widget)
    }

    return {
        definitionSets,
        rangeDefinitions,
        updateWidgetSettings,
        pickerPositionOffset,
        addItem,
        removeItem,
        getDataByIsolatedModules,
        updateParameterValue
    }
}