<template>
    <div
        @click="$emit('onChange')"
        class="dots rounded-full cursor-pointer relative after:h-16"
        :style="{
            backgroundColor: isSelected ? background : null
        }"
        :class="[
            {
                'bg-secondary-four border-secondary-four': !isSelected,
                'bl-after-none': config.borderLeft
            }
        ]"
    >
        <div v-show="config.borderTopLeft && isSelected && config.hideBorder" class="border-top-right"></div>
        <div v-show="(config.isOpen  && isSelected)" class="border-top-left"></div>
    </div>
</template>
<script>
    export default {
        props: {
            isSelected: {
                type: Boolean,
                default: true
            },
            background: {
                type: String
            },
            config: {
                type: Object
            }
        }
    }
</script>
<style scoped>
.dots{
    width: 10px;
    height: 10px;
}
.border-top-right {
    position: absolute;
    top: 4px;
    left: 10px;
    width: 54px;
    border-top: 1px dashed var(--color-secondary-five);
    cursor: default;
}
.border-top-left {
    position: absolute;
    top: 4px;
    left: -16px;
    width: 16px;
    border-top: 1px dashed var(--color-secondary-five);
    cursor: default;
}
.hide-border {
    border-color: blue !important;
}
.dots::after {
    content: "";
    position: absolute;
    left: 4px;
    width: 0px;
    border-left: 1px dashed;
    border-left-color: var(--color-secondary-five);
    cursor: default;
}
.bl-after-none.dots::after {
    border-left: none;
    content: none;
}
.mt-3 > .dots::after {
    height: 18px;
    top: -20px;
}
.mt-8 > .dots::after {
    height: 41px;
    top: -42px;
}


.template > .dots::after {
    height: 45px;
    top: -49px;
}
</style>
