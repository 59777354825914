var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",staticStyle:{"display":"none"}},[_c('div',{staticClass:"w-275",staticStyle:{"visibility":"hidden"},style:({'margin-left': _vm.getLeftMargin()})},[_c('div',{staticClass:"px-2 rd-last-item rounded-sm flex relative items-center border-color-transparent cursor-pointer",class:{
          'i-border-1': _vm.activeData.id === _vm.item.id || _vm.item.appearance === 1,
          'text-white textarea-primary-three shadow-two' : _vm.item.appearance === 0,
          'text-primary-one textarea-secondary-one' : _vm.item.appearance !== 0,
          'border-secondary-five' : _vm.item.appearance === 1 && _vm.activeData.id !== _vm.item.id,
          'border-primary-one i-border-1' : _vm.activeData.id === _vm.item.id
        },staticStyle:{"visibility":"hidden"},style:({
          'background': _vm.appearance === 0 && _vm.item.appearance === 0 ? _vm.getBg(_vm.level, _vm.item) : 'transparent',
        }),attrs:{"id":'l4_item_' + _vm.item.id},on:{"click":function($event){return _vm.$emit('setActiveScenarioCollection', _vm.item)}}},[(_vm.indexTitle)?_c('span',{staticClass:"text-white ls-5 font-poppins fs-13 fw-400",class:{'text-primary-one textarea-secondary-one' : _vm.item.appearance !== 0,}},[_vm._v(" "+_vm._s(_vm.item.index_code)+" ")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.title),expression:"item.title"}],staticClass:"panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-white ls-5 font-poppins fs-13 fw-400 truncate",class:{'ml-1': _vm.indexTitle, 'text-primary-one textarea-secondary-one' : _vm.item.appearance !== 0},attrs:{"type":"text"},domProps:{"value":(_vm.item.title)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"]))return null;return _vm.$emit('deleteItem', $event, _vm.item.id)},"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.item, "title", $event.target.value)},function($event){return _vm.regularUpdate()}]}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }