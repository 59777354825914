import format from 'date-fns/format'
import add from 'date-fns/add'

export function useDate() {
    const formatDate = (date, formatStr="dd.MM.yyyy") => {
        return format(date, formatStr)
    }

    const addDate = (startDate, day) => {
        return add(startDate, {
            days: day
        })
    }

    return {
        formatDate,
        addDate
    }
}