<template>
    <div class="i-w-50 rounded relative"  :style="{'background': background, height: 'calc(100% - 6px)'}" :title="`${moduleTitle}, ${layoutTitle}`">
        <div class="inner-container flex">
            <div class="flex items-center">
                <h4 @click="$emit('removeRotation')" class="fs-14 text-primary-three fw-700 cursor-pointer  whitespace-no-wrap" v-if="moduleTitle">
                    {{ label }}
<!--                    <small class="fs-11 ml-2 font-normal" v-if="layoutTitle"> {{ layoutTitle }} </small>-->
                </h4>
<!--                <img src="/images/roadmap/widget_load_white.svg" alt="icon" class="pr-4 i-border-r-1 border-primary-three cursor-pointer ml-4 mr-16" />-->
            </div>
<!--            <div class="flex">-->
<!--                <slot name="action"></slot>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            moduleTitle: {
                type: String,
                required: true
            },
            layoutTitle: {
                type: String,
                required: true
            },
            background: {
                required: true
            },
            label: {
              type: String,
              required: true
            }
        }
    }
</script>

<style scoped>
    .inner-container {
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        font-size: 13px;
        position: absolute;
        top: 50%;
        left: 50%;
    }
</style>
