<template>
    <div>
        <input 
            v-model="column.title" 
            class="w-20 fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude" 
            type="text"
            @input="updateWidgetSettings(widget)"
        >
        <div class="bg-primary-two mt-1 business-widget-padding panzoom-exclude">
            <div v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                <input 
                    style="height: 20px"
                    @input="updateSampleValue($event, item, column, {
                        id: column.id,
                        module_id: module.id,
                        project_id: $route.params.id,
                        property_collection_id: column.id,
                        sc_id: item.id,
                        scenario_id: item.scenario_id,

                    })"
                    type="text"
                    class="w-20 flex text-center justify-center items-center bg-white rounded-sm border border-secondary-five fw-400 fs-12 text-secondary-one" 
                    :class="[getTheGap(flatData, item, index)]"
                    :value="getFieldParameterValue(item, column)"
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import { getTheGap } from "@/store/modules/roadmap/services";
import useBusinessSetting from '../useBusinessSetting'
import useBusiness from '../useBusiness'

const props = defineProps({
    column: Object,
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array
})

const { updateWidgetSettings, getDataByIsolatedModules } = useBusinessSetting()
const { getFieldParameterValue,  updateSampleValue} = useBusiness()
</script>