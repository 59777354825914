<template>
  <div class="relative font-poppins">
    <div class="h-6">
      <WidgetTitle
        name="Metric"
        v-show="isExpanded"
        v-model="widget.title"
        @updateWidgetTitle="$store.dispatch('roadmap/updateWidgetSettings', widget)"
      >
        <img src="/images/roadmap/delete_widget.svg" alt="icon"
          class="cursor-pointer"
          content="Delete Widget"
          v-tippy="{ placement : 'top',  arrow: true }"
          @click="() => { canEdit(project.cans) && onDeleteWidget() }"
        />
      </WidgetTitle>
    </div>
    <div
      :class="[isExpanded ? 'm-w-260' : 'w-12']"
      class="group-shadow-bg bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm"
    >
      <CollapseHandle :isExpanded="isExpanded" class="absolute" :handler="handleCollapse"/>
      <div  class="panzoom-exclude group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five handler"
        @click="toggleWidgetType()"
        @contextmenu="toggleWidgetSettings($event)"
      >
        <img v-show="isExpanded" src="@/assets/images/roadmap/settings_icon.svg" alt="icon" class="widget-setting-icon" />
      </div>
    </div>

    <CollapsedBar
      :is-expanded="isExpanded"
      title="Metric:"
      :widget="widget"
    />

    <!-- <div
      v-if="!isExpanded"
      class="empty-widget-bar w-12 bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm mt-8 border-secondary-five m-h-220 minimized-bar">
      <div class="h-full w-full relative bg-white shadow-two border-secondary-five border border-solid rounded-sm">
        <VerticalText
            title="Metric:"
            :subTitle="widget.title"
        />
      </div>
    </div> -->

    <template v-if="!settings && !widget.settings && isExpanded">
      <div v-if="widget.parameters && widget.parameters.length > 0" class="flex px-2 mt-3 mb-2">
        <p
          v-for="(param, paramIndex) in parameterTitles"
          :key="paramIndex"
           @click="setParameter(param)"
          :class="[param.propertyCollectionId == layout.selectedPropertiesId ? 'text-primary-one' : 'text-secondary-two']"
          class="truncate fs-8 fw-400 pr-5 text-center cursor-pointer"
          :style="{width : paramIndex == 0 ? '115px' : paramIndex+1<widget.parameters.length ? '14em' : '12em'}"
        >
          {{ param.title }}
        </p>
      </div>
    </template>

    <div
      v-if="!settings && !widget.settings && isExpanded"
      style="height: calc(100% -110px)"
      class="relative rounded-sm dashed-border pt-3 w-full"
      :class="{'no-border': widget && widget.parameters.length > 0 && widget.parameters[0].id, 'bg-primary-two': activeBg, 'mt-5': widget.parameters.length <= 0 || !widget.parameters[0].id}"
    >
      <template v-for="(item, index) in getData(filteredCollections)">
        <div
            v-if="widget && widget.parameters && widget.parameters.length > 0"
            class="i-pl-5 i-pr-5"
            :style="{'height': index === 0 ? '18px': '20px', visibility: _getItemVisibility(item) ? 'visible' : 'hidden'}"
            :class="getTheGap(item, index)"
            :key="index"
            :title="`${item.index_code} ${item.title}`"
        >
          <template v-for="(parameter, pIndex) in getParameters(widget.parameters)">
            <div
            @click="updateParameterValue(item, parameter.id, getParameterValue(item, parameter))"
            class="box-container flex items-center cursor-pointer click-bounce"
              v-if="parameter && parameter.id"
              :key="pIndex"
              :class="{'i-ml-25': pIndex > 0}"
            >
              <span v-for="(i, serialIndex) in 5" :key="serialIndex" class="parameter-outline shadow-two bg-C9"
                :style="{'background': getParameterBg(getParameterValue(item, parameter), widget.definition_style, serialIndex)}" />
              <!-- <div class="absolute value-text-pos"> -->
                <span class="float-left i-ml-5 fs-10 fw-400 text-primary-one">{{ getParameterValue(item, parameter) }}</span>
              <!-- </div> -->
            </div>
          </template>
        </div>
      </template>
    </div>

    <div v-show="isExpanded">
      <basic-settings class="mt-8 mb-8 absolute z-10"
        v-if="(settings || widget.settings)"
        :widget="widget"
        :layout="layout"
        :moduleHeight="moduleHeight"
        :module="module"
        @setSettingsTrue="setSettingsTrue"
      />
    </div>
  </div>
</template>

<script setup>
import usePermission from '@/composable/usePermission.js'
const { canEdit, canCreate, canDelete } = usePermission()
</script>
<script>
import { mapGetters } from 'vuex'
import WidgetTitle from '@/components/roadmap/WidgetTitle.vue'
import { parameterNextValue, getParameterTitles, getValidParameters,  getPropertyCollection, getItemVisibility} from '@/store/modules/roadmap/services.js'
// import VerticalText from '@/components/roadmap/canvas/widgets/VerticalText';
import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
import BasicSettings from "./settings";
import CollapseHandle from '@/components/roadmap/canvas/widgets/CollapseHandle';
import { deleteWidget } from '@/store/modules/roadmap/services';

export default {
  name: "basicParameter",
  components: {
    BasicSettings,
    CollapseHandle,
    // VerticalText,
    CollapsedBar,
    WidgetTitle
  },
  props: ['module', 'filteredCollections', 'layout', 'widget', 'flatData', 'isolatedModules'],
  created() {
    this.moduleHeight = document.getElementById(`div_content_${this.layout.id}_${this.module.id}`).clientHeight;
  },
  data() {
    return {
      isExpanded: true,
      parentHeight: 0,
      settings: false,
      minRange: [0, 2, 4, 6, 8],
      maxRange: [2, 4, 6, 8, 10],
      moduleHeight: 132
    };
  },
  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
      activeData: "programmatic/activeScenarioCollection",
      project: "project/project"
    }),
    activeBg() {
      return !!(this.layout && this.layout.settings && this.layout.settings.color);
    },
    parameterTitles() {
      return getParameterTitles(this.widget.parameters, this.module.properties)
    }
  },
  methods: {
    setSettingsTrue() {
      this.settings = true;
    },
    getData(items) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      } else if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
        return items.filter(data => this.layout.isolated_ids.includes(data.id) && !data.root);
      }
      return items;
    },
    handleCollapse() {
      this.isExpanded = !this.isExpanded
    },
    getParameters(parameters) {
      if(parameters && parameters.length > 0) {
        return parameters;
      }
      return [];
    },
    getParameterValue(item, parameter) {
      let value = 0;
      if(item && item.properties && item.properties.length > 0 && parameter && parameter.id) {
        let param = item.properties?.find(data => data.property_collection_id === parameter.id);
        if(param) {
          return param.sample_value ? parseFloat(param.sample_value).toFixed(2) : value.toFixed(2);
        }
        return value.toFixed(2);
      }
      return value.toFixed(2);
    },
    getWidth() {
      let params = getValidParameters(this.widget.parameters)
      if(params && params.length > 0) {
        let width =  (params.length * 97) + ((params.length - 1) * 20) + 10;
        if(width > 260) {
          return width;
        }
      }
      return 260;
    },
    toggleWidgetSettings(e) {
      e.preventDefault();
      this.widget.settings = false;
      if(this.widget.settings || this.settings) {
        this.settings = false;
      } else {
        this.settings = !this.settings;
      }
    },
    toggleWidgetType() {
      this.widget.settings = false;
      if(this.widget.type === 3) {
        this.widget.type = 1;
      } else {
        this.widget.type++;
      }

      this.$store.dispatch("roadmap/updateWidgetSettings", {
        id: this.widget.id,
        title: this.widget.title,
        type: this.widget.type
      });
    },
    getParameterBg(value, definitionStyles, serialIndex) {
      if(value > 0) {
        value = value - .000001;
      }
      let valueIndex = Math.floor(value / 2);
      if(valueIndex > 4) {
        valueIndex = 4;
      }

      if(this.minRange[serialIndex] < value) {
        return definitionStyles[valueIndex].bg;
      }
      return null;
    },
    onDeleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    getTheGap(item, itemIndex) {
      if(itemIndex > 0) {
        let indexInFlat = this.flatData.findIndex(data => data.id === item.id);
        if(indexInFlat >= 0) {
          if(this.flatData[indexInFlat].gap) {
            return this.flatData[indexInFlat].gap;
          } else {
            return this.getParentGap(item.parent_id);
          }
        }
      }
    },
    getParentGap(id) {
      let itemIndex = this.flatData.findIndex(data => data.id === id);
      if(itemIndex >= 0) {
        if(this.flatData[itemIndex].gap) {
          return this.flatData[itemIndex].gap;
        } else {
          return this.getParentGap(this.flatData[itemIndex].parent_id);
        }
      }
      return 'mt-4';
    },
    setParameter(param) {
      if(param.propertyCollectionId) {
        // this.$set(this.layout, 'selectedPropertiesId', param.propertyCollectionId)
        let collection = getPropertyCollection(param.propertyCollectionId, this.module.properties)
        // console.log(collection)
        if(collection) {
          this.$set(this.layout, 'selectedPropertiesId', collection.id)
        }
      }
    },
    _getItemVisibility(item) {
      return getItemVisibility(this.layout, item)
    },
    updateParameterValue(item, parameterId, value) {
    let collectionParam = parameterNextValue(
      item,
      parameterId,
      parseInt(value),
      this.activeRoadmapData
    );
    let itemParameter = item.properties?.find(
      (data) =>
        parseInt(data.property_collection_id) === parseInt(parameterId)
    );
    if (itemParameter) {
      itemParameter.sample_value = collectionParam.value;
    } else {
      item.properties.push({
        scenario_collection_id: item.id,
        sample_value: collectionParam.value,
        property_collection_id: parameterId
      });
    }

    this.$store.dispatch("programmatic/updatePropertyItem", collectionParam).then(response => {
      if(response && response.data && response.data.data && response.data.data.id) {
        if(!itemParameter) {
          itemParameter= item.properties.find(data => parseInt(data.property_collection_id) === parseInt(parameterId));
          if(itemParameter) {
            itemParameter.id = response.data.data.id;
          }
        }

        this.$emit("loadAModuleData");
      }
    });

      if(this.activeData && this.activeData.id == item.id) {
        let dataObj = {
          property_collection_id: parameterId,
          sample_value: collectionParam.value
        };
        this.$store.commit("programmatic/updateActiveItemParameterValue", dataObj);
      }
  },
  }
};
</script>

<style>
.parameter-outline {
  float: left;
  width: 8px;
  margin-left: 5px;
  height: 8px;
  border-radius: 2px;
}
.box-container {
  float: left;
  width: 90px;
  height: 8px;
}
.default-style {
  /*min-width: 250px;*/
  width: 440px;
}
.group-shadow-bg {
  height: 50px;
}
.group-setting {
  height: 40px;
}
.widget-setting-icon {
  width: 13px;
  height: 13px;
}
.value-text-pos {
  top: -18px;
  left: 4px;
}
.dashed-border {
  border: 1px dashed #C9C9C9;
}
.no-border {
  border: none;
}
.fs-6 {
  font-size: 6px;
}
.fs-7 {
  font-size: 7px;
}
.-i-mt-3 {
  margin-top: -3px;
}
</style>
