<template>
    <div class="relative font-poppins h-full" :class="{ 'w-12': !isExpanded }">
      <div style="height: calc(100%)" class="mt-3">
        <div class="flex items-center h-3" :class="{'gap-x-8': renderLimit !== 0}">
          <div class="i-w-200 pl-2 flex items-center justify-between">
            <!-- <input 
                v-model="column.title" 
                class="w-20 fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude" 
                type="text" 
            > -->
              <InputSelectWithVmodel
                  v-model="column.associated_module_id"
                  label="Select Module"
                  :selected="module.id"
                  :isDisabled="!canEdit(project.cans)"
                  @change="updateWidgetSettings"
                  :items="association.getModules(activeRoadmapData)"
                  :label-classes="'text-secondary-two fs-14 fw-500'"
                  :classes="'px-1 rounded-sm fs-8 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown w-32'"
              />
              <div @click="handleBarClick" class="fs-16 fw-800 cursor-pointer">+</div>
          </div>
          <!-- <div v-if="showDropdown" class="flex i-pt-7">
            <p
              :title="item.title"
              @click="selectedParameterId = item.propertyCollectionId"
              :class="[
                selectedParameterId == item.propertyCollectionId
                  ? 'text-primary-one'
                  : 'text-secondary-two',
              ]"
              class="fs-8 i-w-61 truncate mr-2 cursor-pointer"
              v-for="(item, index) in association.parameterTitles(
                widget.parameters,
                association.getModules(activeRoadmapData),
                selectedModuleId
              )"
              :key="index"
            >
              {{ item.title }}
            </p>
          </div> -->
        </div>
        <div
          class="bg-primary-two business-widget-padding"
          style="height: calc(100% - 31px); margin-top: 7px"
        >
          <div
            v-for="(item, index) in association.getFilterCollectionWithAssociatedModules()"
            :key="item.id"
            class="flex parent"
            :class="[getGap(flatData, item.filterCollection, index), renderLimit !== 0 ? 'gap-x-4' : '']"
          >
            <template v-if="item.associatedModules.length">
              <template v-for="(column, cIndex) in item.associatedModules">
                <div v-if="cIndex <= renderLimit" :key="column.id">
                  <div class="flex items-center">
                    <div class="flex items-center">
                      <div
                        :style="{
                          background: getDotsBackgroundColor(column, association.getModule(association.getModules(activeRoadmapData),selectedModuleId)),
                        }"
                        @click="canEdit(project.cans) && setActiveScenarioCollection(
                            association.getModule(
                              association.getModules(activeRoadmapData),
                              selectedModuleId
                            ),
                            column
                        )"
                        class="px-2 rounded-sm flex relative items-center border-color-transparent cursor-pointer i-border-1 border-solid i-h-20 i-w-200 shadow-two"
                        :class="{'border-primary-one': activeData.id === column.id}"
                      >
                        <span class="truncate text-white ls-5 font-poppins fs-10 fw-400" >
                          {{ column.index_code + " " + column.title }}
                        </span>
                      </div>
                      <div
                        v-if="showDropdown && renderLimit !== 0"
                        @click="canEdit(project.cans) && removeAssociation(item.filterCollection, column)"
                      >
                        <XCircle
                          class="w-3 h-3 cursor-pointer text-secondary-five i-ml-2"
                        />
                      </div>
                    </div>
                    <div v-if="showDropdown" class="flex i-gap-9">
                      <div
                        v-for="(value, vIndex) in association.getSampleValues(
                          column.properties,
                          widget.parameters
                        )"
                        :key="vIndex"
                      >
                        <div class="flex i-gap-5">
                          <Parameter
                            custom-margin="m-0"
                            v-for="(property, propertyIndex) in 5"
                            :key="propertyIndex"
                            :background="association.getParameterBg(value, widget.definition_style,propertyIndex)"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div
              class="px-1 shadow-two bg-primary-three rounded-sm flex relative items-center border-solid border-color-transparent cursor-pointer border i-border-1 i-h-20 i-w-200"
              v-if="association.getAddModuleDisplayPermission(item.associatedModules.length,renderLimit,showDropdown)"
            >
              <InputSelect
                @change="onSelectAssociation($event, item.filterCollection)"
                label="Select Module Content"
                :items="moduleLastLabels"
                :label-classes="'text-secondary-two fs-14 fw-500'"
                :classes="'p-0 -i-ml-5 i-w-200 rounded-sm fs-10 fw-400 text-primary-one bg-transparent inst-dropdown param-dropdown'"
              />
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  
  <script setup>
    import usePermission from '@/composable/usePermission.js'
    const { canCreate, canEdit, canDelete, canRead } = usePermission()
  </script>
  
  <script>
  import Association from "@/components/roadmap/canvas/widgets/AssociatedContent/association.js";
  import InputSelect from "@/components/roadmap/canvas/widgets/AssociatedContent/InputSelect.vue";
  import InputSelectWithVmodel from "@/components/roadmap/canvas/widgets/AssociatedContent/InputSelectWithVmodel.vue";
  import { mapGetters, mapActions } from "vuex";
  import { deleteWidget } from '@/store/modules/roadmap/services'
  import WidgetTitle from "@/components/roadmap/WidgetTitle.vue";
  import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
  import CollapseHandle from "@/components/roadmap/canvas/widgets/CollapseHandle";
  import { getTheGap } from "@/store/modules/roadmap/services";
  import { getItemVisibility } from "@/store/modules/roadmap/services.js";
  import Parameter from "@/components/roadmap/canvas/widgets/people/component/Parameter.vue";

  export default {
    name: "AssociatedContent",

    components: {
      WidgetTitle,
      CollapsedBar,
      CollapseHandle,
      InputSelect,
      InputSelectWithVmodel,
      Parameter,
    },

    data() {
      return {
        propertyRange: {
          min: 0,
          max: 0,
        },
        renderLimit: 0,
        incrementing: true,
        selectedParameterId: null,
        showSetting: false,
        showDropdown: false,
        isExpanded: true,
        association: new Association(this),
      };
    },

    computed: {
      ...mapGetters({
        activeRoadmapData: "roadmap/programmatic",
        colorCodes: "global/colorCodes",
        activeData: "programmatic/activeScenarioCollection",
        project: "project/project",
      }),
      selectedModuleId() {
        return this.column.associated_module_id;
      },
      filterCollectionIds() {
        const collectionIdArr = [];
        this.filteredCollections.forEach((item) => {
          collectionIdArr.push(item.id);
        });
        return collectionIdArr;
      },
      moduleLastLabels() {
        let module = this.association.getModule(
          this.association.getModules(this.activeRoadmapData),
          this.selectedModuleId
        );
        if (module) return module.last_levels;
        return [];
      },
      getHeaderWidth() {
        if(this.isExpanded) {
          if(this.showSetting) {
            return 'm-w-260';
          }
          return 'm-w-200';
        }
        return 'w-12';
      }
    },

    props: [
      "column",
      "module",
      "layout",
      "widget",
      "flatData",
      "filteredCollections",
      "isolatedModules",
    ],

    methods: {
      ...mapActions({
        fetchAssociationByCollection: "roadmap/fetchAssociationByCollection",
      }),
      removeWidget() {
        this.$Progress.start();
        this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
          this.$Progress.finish();
          deleteWidget(this.widget.id, this.layout);
        });
      },
      handleCollapse() {
        this.isExpanded = !this.isExpanded;
      },
      isLastAndHaveSpace(item) {
        return item.column.length < 3;
      },
      getGap: getTheGap,
      getItemVisibility,
      toggleWidgetSettings(e) {
        e.preventDefault();
        if (this.selectedModuleId) {
          this.showSetting = !this.showSetting;
        } else {
          alert("Please Select a Module.");
        }
      },
      updateWidgetSettings(e) {
        this.association.moduleId = e;
        this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      },
  
      removeAssociation(item, association) {
        this.$Progress.start();
        this.association
          .addRemoveAssociation(
            {
              associated_id: association.id,
              scenario_collection_id: item.id,
              project_id: this.$route.params.id,
              scenario_id: this.activeRoadmapData.scenario_id,
            },
            this.filterCollectionIds,
            "delete"
          )
          .then((response) => {
            this.$Progress.finish();
          });
      },
  
      handleBarClick() {
        const max = this.association.maxItemLength;
        // if(!this.incrementing && this.renderLimit == 0) {
        //   this.showDropdown = false;
        // }
        if(this.incrementing && this.renderLimit == 0) {
          this.showDropdown = true;
        }
  
  
        if (this.renderLimit == 0) {
          this.incrementing = true;
        }
        if (this.incrementing) {
          this.renderLimit++;
        }
  
        if (!this.incrementing) {
          this.renderLimit--;
        }
        if (this.renderLimit >= max - 1) {
          this.incrementing = false;
        }
      },
  
      onSelectAssociation($event, item) {
        this.$Progress.start();
        this.association
          .addRemoveAssociation(
            {
              associated_id: $event,
              scenario_collection_id: item.id,
              project_id: this.$route.params.id,
              scenario_id: this.activeRoadmapData.scenario_id,
            },
            this.filterCollectionIds
          )
          .then((response) => {
            this.$Progress.finish();
          });
      },
  
      getData(items) {
        let moduleInIsolation = this.isolatedModules.find(
          (item) => item && item.module_id === this.module.id
        );
        if (moduleInIsolation && moduleInIsolation.isolate) {
          return items.filter(
            (data) =>
              moduleInIsolation.isolatedIds.includes(data.id) && !data.root
          );
        }
        return items;
      },
  
      UpdateValues(e) {
        let range = this.propertyRange;
        range.min = e.minValue;
        range.max = e.maxValue;
      },
  
      getDotsBackgroundColor(collection, module) {
        let collectionSettings = module.collection_settings;
        if (collection && collection.background_color) {
          return collection.background_color;
        }
  
        collectionSettings = JSON.parse(collectionSettings);
  
        if (collectionSettings.collections[collection.level].custom_bg) {
          return collectionSettings.collections[collection.level].custom_bg;
        }
  
        if (collectionSettings && collectionSettings.collections.length > 0) {
          let indexLevel = collection.level - 1;
          let colorCodes = this.colorCodes.slice(
            indexLevel * 9,
            indexLevel * 9 + 9
          );
          return colorCodes[
            collectionSettings.collections[collection.level].color
          ];
        }
        return null;
      },
  
      setActiveScenarioCollection(module, item) {
        this.$store.commit("programmatic/setActiveBrainstormScenarioCollection", item);
        this.$store.commit("build/setModulePropertySet", module.properties);
        this.$emit("toggleToPropertyMode");
      }
    },
  
    mounted() {
      this.association.getAssociations(this.filterCollectionIds);
      this.association.moduleId = this.selectedModuleId;
    },
  };
  </script>
  