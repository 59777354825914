<template>
  <div class="_main_wrapper relative flex" :class="(navigationClicked === 2)&&'no-left-panel-visible'">
    <div class="i-left-panel shadow-one flex-shrink-0" v-if="navigationClicked === 1">
      <div class="relative">
        <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
        <NavToggle v-if="projectId" />
      </div>

      <left-part
        ref="roadmap_sidebar"
        :navigationClicked="navigationClicked"
        :isolatedModules="isolatedModules"
        :isolatedModuleId="isolatedModule"
        @clickNavigation="clickNavigation"
        @toggleIsolation="toggleIsolation"
        @moduleIsolation="moduleIsolation"
        @loadAModuleData="loadAModuleData"
      />
    </div>
      <div class="relative">
        <div
          class="i-right-panel full-height overflow-hidden"
          :class="{'bld-full-w-no-left': navigationClicked === 2}"
          :ref="getRef"
          style="scroll-behavior: smooth"
        >
          <div class="flex flex-col absolute right-0 z-max ws-heading-box" v-if="navigationClicked === 1">
            <workspace-menu />
          </div>

          <div class="" :class="{'': navigationClicked === 2}">
            <div class="flex justify-between items-center absolute z-50"
                :class="{'tools-panel-cont' : navigationClicked == 1,
                          'tools-panel-cont-full': navigationClicked == 2,
                          'tools-panel-cont-full-width': navigationClicked == 2 && !sketch}">

              <div class="absolute tool-cont-pos flex gap-x-5 items-center" :class="{'tool-cont-pos-full': navigationClicked === 2}" v-if="!sketch">
                <FullscreenBorderedIcon
                    class="w-10 h-10 shadow-one"
                    v-if="navigationClicked === 2"
                    @click.native="clickNavigation(1)"
                    title="Navigation"
                />

                <div class="tools-panel z-50 h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center justify-between i-border-1 border-primary-four">
                  <div class="flex items-center">
                      <img
                      class="pr-3 i-border-r-1 i-border-r-1 cursor-pointer"
                      src="@/assets/images/icons/brainstormV2/save_as.svg" alt=""
                      :class="{'pointer-events-none': !canEdit(project.cans)}"
                      @click="openRoadmapNav"
                    />
                    <input v-if="activeRoadmapData && activeRoadmapData.id" class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box ml-3" type="text" placeholder="Roadmap title"
                          v-model="activeRoadmapData.title" :disabled="!canEdit(project.cans)" @change="updateTitle()">
                  </div>
                  <div style="flex-shrink: 0" class="flex gap-x-5 items-center">
                    <BarIcon class="h-4 text-secondary-five" />
                    <ResetIcon @click.native="resetZoom" class="w-4 h-4 text-primary-one cursor-pointer" />
                    <img src="/images/icons/brainstormV2/sketch.svg" alt="icon" />
                    <img class="bs-tool-mr-0" src="/images/roadmap/color_picker.svg" alt="" @click="shareModal = true">
                    <img src="/images/icons/brainstormV2/chain.svg" alt="">
                    <BarIcon class="h-4 text-secondary-five" />
                    <div class="flex tool-group">
                      <div class="flex gap-1">
                        <div
                          class="i-w-10 h-4 bg-primary-four cursor-pointer i-border-1 border-transparent"
                        />
                        <div
                          class="i-w-10 h-4 i-border-r-1 l2c cursor-pointer i-border-1 border-transparent"
                        />
                        <div
                          class="i-w-10 h-4 i-border-r-1 l3c cursor-pointer i-border-1 border-transparent"
                        />
                        <div
                          class="i-w-10 h-4 i-border-r-1 l4c cursor-pointer i-border-1 border-transparent"
                          :class="{'opacity-50': true}"
                        />
                      </div>
                  </div>
                    <!-- <img src="/images/icons/brainstormV2/sync.svg" alt="icon" @click="resetZoom" /> -->
                  </div>
                  <div class="flex tool-group ml-5">
                  </div>
                </div>

                <MasterViewBorderedIcon
                    class="w-10 h-10 shadow-one"
                    title="Master view (Coming Soon)"
                />
                <DuplicateBorderedIcon
                    class="w-10 h-10 shadow-one"
                    title="Copy Scenario (Coming Soon)"
                />
                <EditBorderedIcon
                    class="w-10 h-10 shadow-one"
                    title="Sketch (Coming Soon)"
                />
                <router-link :to="{name: 'roadmapVisualization', params: {projectId: $route.params.id, roadmapId: $route.params.roadmap_id}}">
                  <MonitorBorderedIcon
                      class="w-10 h-10 shadow-one"
                      title="Quick Visualize"
                  />
                </router-link>
              </div>
            </div>
          </div>

          <!-- <PanzoomComponent> -->
            <roadmap-canvas
              v-if="activeRoadmapData && activeRoadmapData.id"
              ref="roadmap_canvas"
              :isolatedModules="isolatedModules"
              :isolatedModuleId="isolatedModule"
              @toggleToPropertyMode="toggleToPropertyMode"
            />
          <!-- </PanzoomComponent> -->
        </div>
      </div>
    <RoadmapNavModal
      :dModal="loadingModal"
      @onRouteChange="onRouteChange"
      @toggleModalMode="toggleLoadingModalMode"
    />

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
import usePermission from '@/composable/usePermission.js'
import usePanzoom from '@/elements/panzoom/usePanzoom'
import NavToggle from '@/components/navigation/components/NavToggle.vue'
import useNavigation from '@/components/navigation/useNavigation.js'
import Nav from '@/components/navigation/Nav.vue'

const { canDelete, canCreate, canEdit } = usePermission()
const { resetZoom, getRef } = usePanzoom()
const { projectId, showNavigation } = useNavigation()
</script>
<script>
import LeftPart from "@/components/roadmap/canvas/LeftSection/LeftPart";
import RoadmapCanvas from "@/components/roadmap/canvas/Index.vue"
import {mapGetters, mapActions} from 'vuex';
import RoadmapNavModal from '@/components/roadmap/modal/RoadmapNavModal'
import useZoom from "@/elements/zoom.js";

export default {
  name: "Show",
  sockets: {
    connect: function () {
      // console.log('socket connected')
    },
    customEmit: function (data) {
      // console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  },
  components: {LeftPart, RoadmapCanvas, RoadmapNavModal},
  created() {
    document.title = "Roadmaps";
    this.getInitialProgrammatics({ project_id: this.$route.params.id })
    this.$Progress.start();
    this.$store.dispatch("roadmap/getFirstModuleProgrammatic", { roadmap_id: this.$route.params.roadmap_id, from_page: true });
    this.$store.dispatch("workspace/projectSelection", this.$route.params.id);
    this.$store.dispatch("definition/getDefinitionSets", { project_id: this.$route.params.id });
    this.$store.dispatch("roadmap/getAllLayoutsOfWorkspace", { project_id: this.$route.params.id });
    this.$store.dispatch("project/getSolidObject", this.$route.params.id).then(response => {
      if(response && response.active_module_id) {
        this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", response.active_module_id);
        this.$store.dispatch("programmatic/toggleModuleSelection", response.active_module_id);
      }
    });
  },
  data() {
    return {
      loadingModal: false,
      showPicker: false,
      inBrainstormWorking: [],
      pickerUpdated: false,
      usersBg: ['#5B17EA', '#EF5600', '#1C1C1D', '#00A7FE', '#1BAD9C', '#B858BA', '#BCC292', '#0D9D61', '#AD902B', '#BE4949'],
      flippedArray: [],
      viewIndex: 0,
      initialX: 40,
      initialY: 160,
      initialYF: 160,
      initialZoom: 1,
      navigationClicked: 1,
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      isolate: false,
      isolatedItemId: null,
      concept: false,
      filterLevel: [{ visibility: true }, { visibility: true }, { visibility: true }, { visibility: true }],
      sketch: false,
      sketchMenu: false,
      isolatedIds: [],
      contextModule: null,
      intel: false,
      intelIsolation: [null, null],
      shareModal: false,
      viewDetails: false,
      intelChartBg: null,
      intelLineBg: null,
      showLinePicker: false,
      isolatedModules: [],
      roadmapTitle: '',
      isolatedModule: null
    }
  },
  watch: {
    skcOpacity: function (val) {
      if (val) {
        this.renderCanvas()
      }
    },
    getQuadrantsColors: function(val) {
      this.pickerUpdated = true;
    },
    navigationClicked: function(val) {

    },
  },
  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
      activeData: "programmatic/activeScenarioCollection",
      project: "project/project"
    }),
    getModules() {
      if(this.activeRoadmapData) {
        return this.activeRoadmapData.modules;
      }
      return [];
    },
    getNumberModules() {
      return this.getModules.length / 3;
    },
    moduleSettings() {
      if(this.activeRoadmapData.module) {
        let settings = JSON.parse(this.activeRoadmapData.module.collection_settings);
        if(settings) {
          return settings.collections;
        }
        return [];
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getProgrammatics: 'roadmap/getProgrammatics',
      getInitialProgrammatics: 'roadmap/getInitialProgrammatics',
    }),
    toggleToPropertyMode() {
      this.$refs.roadmap_sidebar.navigateToProperty(this.activeData);
    },
    onRouteChange() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/getFirstModuleProgrammatic", { roadmap_id: this.$route.params.roadmap_id })
      .then(res => {
        this.$Progress.finish();
      })
    },
    toggleLoadingModalMode(value) {
      this.loadingModal = value;
    },
    clickNavigation(val) {
      if(val) {
        this.navigationClicked = val;
      } else {
        if(this.navigationClicked === 2) {
          this.navigationClicked = 1;
        } else {
          this.navigationClicked = 2;
        }
      }

      if (this.navigationClicked == 1) {
        this.skcWidth = this.skcCanvas.canvasWidth
        this.skcHeight = this.skcCanvas.canvasHeight
        this.initialY = 160
      }
      else if (this.navigationClicked == 2) {
        this.skcWidth = this.skcCanvas.canvasWidthFull
        this.initialY = this.initialYF
        this.skcHeight = this.skcCanvas.canvasHeightFull
      }
    },
    toggleView() {
      if (this.viewIndex == 0) {
        this.viewIndex = 1
      } else if (this.viewIndex == 1) {
        this.viewIndex = 0
      }
    },
    updateTitle() {
      let data = {
        id: this.activeRoadmapData.id,
        title: this.activeRoadmapData.title,
        roadmap_id: this.$route.params.roadmap_id
      };
      this.$store.dispatch("roadmap/updateProgrammatic", data);
    },
    newRootChild(moduleId = null, order = null) {
      let data = {
        parent_id: null,
        brainstorm_id: this.$route.params.brainstorm_id,
        level: 1,
        title: this.moduleSettings.length > 0 ? this.moduleSettings[1].title : 'Identifier',
      }

      this.$Progress.start();
      if(this.activeRoadmapData.scenario_id) {
        data.scenario_id = this.activeRoadmapData.scenario_id;
        data.item_order = data.order;
        data.from_right_side = false;
        data.module_id = moduleId;
        data.order = order;
        this.toScenarioCreate(data);
      } else {
        data.order = this.activeRoadmapData.collections.length > 0 ? this.activeRoadmapData.collections[this.activeRoadmapData.collections.length - 1].order + 1 : 1
        if(this.activeRoadmapData.module_id) {
          data.item_order = data.order;
          data.from_right_side = false;
          data.module_id = this.activeRoadmapData.module_id;
          this.toBuildCreate(data);
        } else {
          this.toBrainstormCreate(data);
        }
      }
    },
    newSubChild(data) {
      this.$Progress.start();
      if(this.activeRoadmapData.scenario_id) {
        data.scenario_id = this.activeRoadmapData.scenario_id;
        data.item_order = data.order;
        data.from_right_side = false;
        this.toScenarioCreate(data);
      } else {
        if(this.activeRoadmapData.module_id) {
          data.module_id = this.activeRoadmapData.module_id;
          data.item_order = data.order;
          data.from_right_side = false;
          this.toBuildCreate(data);
        } else {
          this.toBrainstormCreate(data);
        }
      }
    },
    toBuildCreate(data) {
      this.$store.dispatch("build/storeData", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });
      this.changedDataFromCollaboration({ load: true });
    },
    toBrainstormCreate(data) {
      this.$store.dispatch("brainstormv1/storeItem", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$Progress.finish();
      });

      this.changedDataFromCollaboration({ load: true });
    },
    toScenarioCreate(data) {
      this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });

      this.changedDataFromCollaboration({ load: true });
    },
    notifyUpdate(data) {
      this.changedDataFromCollaboration(data);
    },
    // controlled scroll
    menuHandler(obj) {
      this.contextmenu = true
      this.contextmenuItem = obj.item
      this.contextModule = this.contextmenuItem.module_id;
      let top = obj.top
      let left = obj.left
      let context = document.getElementById('context-menu-' + this.activeRoadmapData.module_id)
      context.style.left = left+'px'
      context.style.top = top+'px'
    },
    closeMenuHandler(e) {
        var context = document.getElementById('context-menu-' + this.activeRoadmapData.module_id);
        if (context && !context.contains(e.target)) {
          this.contextmenu = false
        }
    },
    applyAppearnce(value) {
      this.appearance = value
      this.contextmenu = false
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyDescription(value) {
      this.description = value
      this.contextmenu = false
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = {... this.contextmenuItem}
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.activeRoadmapData.module_id) {
        this.$store.dispatch("build/regularUpdate", obj)
      }
      if (this.activeRoadmapData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false
    },
    getIds(item) {
      this.isolatedIds.push(item.id);
      item.child.forEach(child => {
        this.getIds(child);
      });
    },
    moduleIsolation(moduleId) {
      if (moduleId === this.isolatedModule) {
        this.isolatedModule = null;
      } else {
        this.isolatedModule = moduleId;
      }
    },
    toggleIsolation(item) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === item.module_id);
      if(moduleIndex >= 0) {
        let moduleIsolation = this.isolatedModules[moduleIndex];
        if(moduleIsolation.isolatedItemId !== item.id) {
          this.prepareIsolationData(item);
        }
        this.isolatedModules.splice(moduleIndex, 1);
      } else {
        this.prepareIsolationData(item);
      }
    },
    prepareIsolationData(item) {
      let isolateData = item.parents;
      let data = {
        module_id: item.module_id,
        isolate: true,
        isolatedIds: this.getChildIdForIsolation(item, isolateData),
        isolatedItemId: item.id
      }
      this.isolatedModules.push(data);
    },
    getChildIdForIsolation(item, isolateData) {
      isolateData.push(item.id);
      if(item.child && item.child.length > 0) {
        item.child.forEach(child => {
          isolateData = this.getChildIdForIsolation(child, isolateData);
        });
      }
      return isolateData;
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
    openRoadmapNav() {
      this.loadingModal = true
    },
    loadAModuleData(moduleId) {
      this.$refs.roadmap_canvas.loadAModuleData(moduleId);
    }
  },
  beforeDestroy() {
    this.$store.state.roadmap.programmatic = [];
  },

}
</script>

<style scoped>
.tools-panel {
  width: 620px;
}

.tool-group img {
  margin-right: 20px;
  cursor: pointer;
  /* opacity: .6; */
}

.tool-group img:hover {
  opacity: 1;
}

.bs-content-div {
  height: 1080px;
}
.bs-content-full-div {
  height: 1080px;
}

.tool-group .bs-tool-mr-0 {
  margin-right: 0;
}
.brainstorm-full-mt {
  margin-top: 54px;
}
.icon-30 {
  width: 30px;
  height: 30px;
}
.tool-level-box {
  width: 10px;
  height: 16px;
  border-radius: 1px;
}
.concept-tool-level-box {
  width: 32px;
  height: 16px;
  border-radius: 2px;
}
.l1c {
  background: #2B80AD;
}
.l2c {
  background: #3D6A83;
}
.l3c {
  background: #6A7881;
}
.l4c {
  background: #818487;
}
.concept-title-box {
  width: 290px;
}
.intel-title-box {
  width: 320px;
}
#sketch-menu {
  top: 33px;
  left: 115px;
  width: 275px;
}
.sketch-item:hover {
  opacity: 1;
}
.track-box {
  width: 140px;
}
.value-track, .progress-track, .range {
  height: 2px;
}
.range {
  outline: none;
  -webkit-appearance: none;
}
input[type='range']::-webkit-slider-thumb {
  width: 20px;
  -webkit-appearance: none;
  height: 10px !important;
  cursor: pointer;
  background: #3D3D3D;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}
.icon-list {
  border-radius: 0px 0px 2px 2px;
  z-index: 999;
  top: 33px;
  left: -15px;
}
.circe-of-user {
  border-radius: 17px;
  z-index: 9999;
}
.circle-user {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.triangle-div {
  top: -18px;
  left: -16px;
  font-size: 1.4rem;
}

/*arrow*/
.rotate-90 {
  transform: rotate(
      -90deg
  );
}
.vue-pan-zoom-scene {
  height: 790px
}
#container {
  width: fit-content;
  padding-right: 1000px;
  padding-bottom: 1000px;
}
.color-picker-div {
  border-radius: 0 0 2px 2px;
  width: 32px;
  z-index: 9999999
}
.pan-area {
  z-index: 1;
}
.tools-panel-cont {
  left: 40px;
  top: 160px;
}
.tools-panel-cont-full {
  left: 40px;
  top: 100px;
}
.tools-panel-cont-full-width {
  width: 1840px;
}
.tool-pos {
  top: 94px;
}
.tool-cont-pos {
  top: -64px;
}
.tool-cont-pos-full {
  top: -6px;
}
.br-view-details {
  border-radius: 2px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.13);
}
.selected-shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
