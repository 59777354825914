<template>
    <div v-click-outside="hidePicker">
        <div @click="showPicker=!showPicker" :style="{background: item.color}" class="surface"></div>
        <color-panel
            v-if="showPicker"
            v-model="item.color" 
        >
        </color-panel> 
    </div>
</template>

<script>
export default {
    name : "AssignColorPicker",
    data: () => ({
        showPicker: false
    }),
    props: {
        item: Object,
        updateWidgetSettings: Function
    },
    methods: {
        hidePicker() {
            if(this.showPicker) {
                this.showPicker = false
                this.updateWidgetSettings()
            }
        }
    }
}
</script>

<style scoped>
.surface {
    height: 9px;
    width: 17px;
    border-radius: 2px;
}
</style>
