<template>
    <div>
        <SettingsAccordion
            title="Labels"
            v-model="isOpen"
        >
            <div class="grid grid-cols-2 gap-4 mt-2">
                <div
                    v-for="(label, i) in widgetWiseLabels"
                    :key="i"
                >
                    <Label
                        :label="label"
                        @onClickDelete="removeLabel"
                        @onBlur="updateLabel"
                    />
                </div>
            </div>
            <div class="mt-3">
                <img
                    v-if="canCreate(project.cans)"
                    @click.prevent="createNewLabel"
                    src="@/assets/images/icons/brainstormV2/plus_circle_outline.svg"
                    alt="icon" class="settings-icon cursor-pointer"
                />
            </div>
        </SettingsAccordion>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canCreate, canEdit, canDelete, canRead } = usePermission()
</script>

<script>
import Label from './Label'
import { mapActions, mapGetters } from 'vuex'
import SettingsAccordion from "@/components/roadmap/canvas/widgets/SettingsAccordion";

export default {
    name: 'PeopleRole',
    components: {
        Label,
        SettingsAccordion
    },
    props: ['widgetId'],
    computed: {
        ...mapGetters({
            labels: 'people/getLabels',
            project: 'project/project'
        }),

        widgetWiseLabels() {
            return this.labels(this.widgetId)
        }
    },
    data() {
        return {
            isOpen: true
        }
    },
    methods: {
        ...mapActions({
            getLabels: 'people/getLabels',
            createLabel: 'people/createLabel',
            updateLabelData: 'people/updateLabel',
            deleteLabel: 'people/deleteLabel',
        }),

        createNewLabel () {
            let data = {
                roadmap_widget_id: this.widgetId,
                title: 'Default Title',
                short_title: 'DT'
            }
            this.createLabel(data)
        },

        removeLabel(id) {
            let data = {
                id: id,
                widgetId: this.widgetId
            }
            this.deleteLabel(data)
        },

        updateLabel(label) {
            this.updateLabelData(label)
        }
    },
    created() {
        this.getLabels(this.widgetId)
    }
}
</script>

<style scoped>
.level-btn {
    height: 20px;
    width: 80px;
    border-radius: 2px;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
}
</style>
