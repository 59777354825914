<template>
    <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins">
        <SettingsHeader
            class="mb-5"
            title="Coordinate Configuration"
        />
        <div class="space-y-3">
            <div v-for="(column, columnIndex) in columns" :key="columnIndex">
                <SettingsAccordion
                    :title="`Column ${columnIndex + 1}`"
                    v-model="column.active"
                >
                    <template v-slot:action>
                        <img @click="addItem(columns)" v-if="(columnIndex + 1) === columns.length" alt="icon" class="cursor-pointer i-w-14 i-h-14" src="/images/icons/brainstormV2/plus_circle_outline.svg" />
                        <img @click="removeItem(columns, columnIndex, widget)" alt="icon" v-if="(columns.length > 1)" class="i-w-14 i-h-14 ml-2 cursor-pointer" src="/images/icons/brainstormV2/close.svg"/>
                    </template>
                    <div class="flex flex-col gap-2">
                        <InputSelect
                            v-model="column.componentName"
                            label="Select Column"
                            :value="column.name"
                            :value-key="'name'"
                            :items="componentNames"
                            @change="updateWidgetSettings(widget)"
                        />
                        <template v-if="parameterComponent.includes(column.componentName)">
                            <InputSelect
                                v-model="column.property_id"
                                label="Select Property Set"
                                :items="module.properties"
                                @change="updateWidgetSettings(widget)"
                            />
                            <!-- @change="updateWidgetSettings()" -->
                            <InputSelect
                                v-if="column.property_id"
                                v-model="column.id"
                                label="Select Parameter"
                                :items="getPropertyCollection(column.componentName, column.property_id)"
                                @change="updateWidgetSettings(widget)"
                            />
                        </template>
                        <template v-if="definitionComponent.includes(column.componentName)">
                            <InputSelect
                                v-if="column.id"
                                label="Select Definition"
                                v-model="column.definition_id"
                                :items="definitionSets"
                                @change="updateWidgetSettings(widget)"
                            />
                        </template>
                        <template v-if="noteComponent.includes(column.componentName)">
                            <InputSelect
                                v-if="column.id"
                                v-model="column.showNoteComment"
                                :items="noteCommentOptions"
                                @change="updateWidgetSettings(widget)"
                            />
                        </template>
                        <template v-if="styleComponent.includes(column.componentName)">
                            <DefinitionColorPicker
                                :widget="column"
                                @onChange="updateWidgetSettings(widget)"
                            />
                        </template>
                    </div>
                </SettingsAccordion>
            </div>

            <DefaultDefinitionColorPicker
                :widget="widget"
                :updateWidgetSettings="() => updateWidgetSettings(widget)"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useBusinessSetting from './useBusinessSetting';
import { useProjectData } from '@/components/dashboard/sidebar/useProjectData.js'
//components
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import InputSelect from '@/elements/inputs/InputSelect';
import DefinitionColorPicker from './components/DefinitionColorPicker.vue'
import DefaultDefinitionColorPicker from '@/components/roadmap/DefinitionColorPicker.vue'

const props = defineProps({
    widget: Object,
    module: Object,
    columns: Array,
    componentNames: Array
})

const { getPropertiesByModule } = useProjectData()
const properties = ref([])
const parameterComponent = ['DynamicField', 'MetricValue', 'MetricDefinition', 'Decision', 'FieldParameter']
const noteComponent = ['DynamicField', 'Decision', 'MetricValue', 'MetricDefinition']
const definitionComponent = ['MetricDefinition']
const styleComponent = ['Decision']
const noteCommentOptions = [
    {
        title: "Don't add Note and Comment",
        id: false
    },
    {
        title: 'Add Note and Comment',
        id: true
    },
]

const {
    definitionSets,
    updateWidgetSettings,
    addItem,
    removeItem
} = useBusinessSetting()

const getPropertyCollection = (componentName, property_id)  => {
    if(!properties.value.length) return []
    const type = componentName == 'FieldParameter' ? 2 : 1
    const parameter = properties.value.find(({id}) => id == property_id)
    if(!parameter || !parameter.property_collections || !parameter.property_collections.length) return []
    return parameter.property_collections.filter(({option}) => option == type)
}

onMounted( async () => {
    properties.value = await getPropertiesByModule(props.module.id)
})
</script>

<style>
.settings-widget{
    width: 250px;
    min-height: 624px;
    max-height: 724px;
    overflow-y: auto;
}
</style>
  
  