<template>
    <div class="i-h-50 i-w-50 bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm" :class="!canCreate(project.cans)&&'pointer-events-none'" @click="$emit('onClick')">
        <div :style="{'background': background}" class="w-10 i-h-40 flex justify-center items-center text-primary-three shadow-one rounded cursor-pointer i-border-2 border-primary-three">
            <h4 class="text-center fs-14 fw-bold">{{ label }}</h4>
        </div>
    </div>
</template>

<script setup>
  import usePermission from '@/composable/usePermission.js'
  const { canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    props: {
        label: {
            type: String,
            default: '+'
        },
        background: {
            required: true
        }
    },
    computed: {
        ...mapGetters({
            project: "project/project",
        })
    }
}
</script>