<template>
    <div class="inline-flex items-center gap-1">
        <div @click="avatarClick" :class="[sizes]" class="border-2 border-white rounded-full flex justify-center items-center cursor-pointer shadow-two overflow-hidden">
            <img
                v-if="photoUrl"
                :class="[sizes, 'object-cover']"
                id="img"
                :src="photoUrl"
                :title="fullName"
                onerror="javascript:this.src='/images/profile.png'"
                >
            <span v-else class="text-sm" style="padding-top: 2px;"> + </span>
        </div>
        <div v-if="localLabel" class="fw-600 text-xs" :title="localLabel.title"> {{ localLabel.short_title }} </div>
    </div>
</template>

<script>
import { peopleFullName } from '@/store/modules/roadmap/services';
export default {
    props: {
        photoUrl: {
            type: [String, Boolean]
        },
        label: {
            type: [Object, Boolean]
        },
        people: {
            type: Object
        },
        sizes: {
            type: String,
            default: 'w-5 h-5'
        }
    },
    computed: {
        fullName() {
            return peopleFullName(this.people)
        },
        localLabel() {
            if(!this.label) {
                return
            }
            return this.label
        }
    },
    methods: {
        avatarClick() {
            if(!this.photoUrl) {
                this.$emit('onClick');
            } else {
                this.$emit('onAvatarClick')
            }
        }
    }
}

</script>