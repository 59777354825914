<template>
    <div>
        <div>
            <input 
                v-model="column.title" 
                class="w-full fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude" 
                type="text" 
                @input="updateWidgetSettings(widget)"
            >
        </div>
        <div class="bg-primary-two i-mt-3 business-widget-padding">
            <div class="relative" v-for="(item, index) in getDataByIsolatedModules(filteredCollections, isolatedModules, module, layout)" :key="item.id">
                <div class="i-w-275 h-5" :class="[getTheGap(flatData, item, index)]">
                    <div
                        class="px-2 rd-last-item rounded-sm flex relative items-center border-color-transparent cursor-pointer"
                        :style="{
                            'background': getBackground(item, module),
                        }"
                    >
                        <span
                        class="text-white ls-5 font-poppins fs-13 fw-400 truncate"
                        :class="{'text-primary-one textarea-secondary-one' : item.appearance !== 0}"
                        >
                            {{ item.index_code }} {{ item.title }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Collection from '@/store/services/traits/collection';
import { getTheGap } from "@/store/modules/roadmap/services";
import useBusinessSetting from '../useBusinessSetting'

const collection = new Collection;

const props = defineProps({
    widget: Object,
    column: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array,
    appearance: {
      default: true
    },
})

const { updateWidgetSettings, getDataByIsolatedModules } =useBusinessSetting()
const getBackground = (level, module) =>  {
    return collection.getBackground(level, module);
};
</script>