<template>
  <div class="relative font-poppins">
    <div class="h-6">
      <WidgetTitle name="Timeline" v-show="isExpanded" v-model="widget.title"
        @updateWidgetTitle="$store.dispatch('roadmap/updateWidgetSettings', widget)">
        <img src="/images/roadmap/delete_widget.svg" alt="icon" class="cursor-pointer" content="Delete Widget"
          v-tippy="{ placement: 'top', arrow: true }" @click="deleteWidget()" />
      </WidgetTitle>
    </div>

    <div class="group-shadow-bg bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm"
      :class="[isExpanded ? 'm-w-260' : 'w-12']">
      <CollapseHandle :isExpanded="isExpanded" class="absolute" :handler="handleCollapse" />
      <div
        class="panzoom-exclude group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five handler"
        @contextmenu="toggleWidgetSettings($event)">
        <img v-if="isExpanded" src="@/assets/images/roadmap/settings_icon.svg" alt="icon" class="widget-setting-icon" />
      </div>
    </div>
    <CollapsedBar
      :is-expanded="isExpanded"
      title="Metric:"
      :widget="widget"
    />

    <!-- <div v-if="!isExpanded"
      class="empty-widget-bar w-12 bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm mt-8 m-h-220 minimized-bar-timeline relative">
      <div
        class="h-full absolute bg-white shadow-two border-secondary-five border border-solid rounded-sm minimized-bar-w">
        <VerticalText title="Timeline:" :subTitle="widget.title" />
      </div>
    </div> -->

    <div v-if="!settings && isExpanded" class="mb-2 mt-3">
      <div class="flex">
        <div style="width:276px" class="flex">
          <p style="width: 91px" v-for="label in columnLabel" :key="label"
            class="fs-8 fw-400 text-center px-1 truncate">{{ label }}</p>
        </div>
        <div @click="setParameter()" style="width: 184px" class="flex justify-center px-1 truncate cursor-pointer">
          <span :class="[isParamSelected ? 'text-primary-one' : 'text-secondary-two']" class="fs-8 fw-400">{{
              getSelectedParamName()
          }}
          </span>
        </div>
        <div class="flex">
          <template v-for="(assign, assignIndex) in assigns">
            <p v-if="assign.name" style="width: 52px" :key="assignIndex" class="fs-8 fw-400 text-center truncate px-1">
              {{ assign.name }}</p>
          </template>
        </div>
      </div>
    </div>

    <div v-if="!settings && isExpanded" class="relative rounded-sm default-style dashed-border overflow-y-auto pb-3"
      :class="{ 'no-border': widget.parameters.length > 0 && widget.parameters[0].id, 'bg-primary-two': activeBg }"
      :style="{ 'width': getWidth() + 'px' }">
      <template v-for="(item, index) in processedFilterColls">
        <div :style="{ visibility: _getItemVisibility(item) ? 'visible' : 'hidden' }"
          v-if="widget && widget.parameters && widget.parameters.length > 0" :class="[index !== 0 ? item.space : '']"
          :key="index" :title="`${item.index_code} ${item.title}`">
          <TimeLineRow
            :item="item"
            :collection-item-index="index"
            :collection-length="filteredCollections.length"
            :widget="widget"
            :formatDate="formatDate"
            @loadAModuleData="loadAModuleData"
            @onChangeDuration="updateEndDate"
            @onSelectDate="data => onSelectDate(data, processedFilterColls)"
          />
        </div>
      </template>
    </div>

    <div v-show="isExpanded">
      <Settings class="my-8 absolute z-10" v-if="settings" :widget="widget" :layout="layout"
        :moduleHeight="moduleHeight" :module="module" />
    </div>

  </div>
</template>

<script>
import WidgetTitle from '@/components/roadmap/WidgetTitle.vue'
import { mapGetters, mapActions } from "vuex";
// import VerticalText from '@/components/roadmap/canvas/widgets/VerticalText'
import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
import Settings from "@/components/roadmap/canvas/widgets/timeline/Settings.vue";
import CollapseHandle from '@/components/roadmap/canvas/widgets/CollapseHandle'
import ProgressBar from '@/components/roadmap/canvas/widgets/timeline/component/ProgressBar.vue';
import GraphLayout from '@/components/roadmap/canvas/widgets/timeline/component/GraphLayout.vue';
import TimeLineRow from '@/components/roadmap/canvas/widgets/timeline/component/TimeLineRow.vue'
import useTimeline from '@/components/roadmap/canvas/widgets/timeline/useTimeline'
import { getBackground, progress, getTheGap, deleteWidget, getSelectedParamName, getSelectedParam, getItemVisibility } from '@/store/modules/roadmap/services';

export default {
  setup() {
    const {
      formatDate,
      updateDate,
      showModal,
      setToAll,
      modalData,
      openDateSelectionModal,
      setDate,
      updateEndDate,
      onDateChange,
      activeRoadmapData,
      load
    } = useTimeline()

    const onSelectDate = (data, processedFilterColls) => {
      openDateSelectionModal(data)
      load(processedFilterColls)
    }

    return {
      formatDate,
      updateDate,
      showModal,
      setToAll,
      modalData,
      openDateSelectionModal,
      setDate,
      updateEndDate,
      onDateChange,
      activeRoadmapData,
      onSelectDate
    };
  },

  // name: "TimelineInput",
  components: {
    WidgetTitle,
    Settings,
    CollapseHandle,
    CollapsedBar,
    // VerticalText,
    ProgressBar,
    TimeLineRow,
    GraphLayout
  },
  props: ['module', 'filteredCollections', 'layout', 'widget', 'flatData', 'isolatedModules'],

  async created() {
    this.fetchAssigns(this.widgetId)
    this.systemData = this.module;
    this.systemData.child = this.systemData.collections;
  },

  data() {
    return {
      columnLabel: ['Start Date', 'End Date', 'Duration'],
      isExpanded: true,
      settings: false,
      moduleHeight: 132,
      systemData: [],
    };
  },
  watch: {
    layout: {
      handler: function () {
        this.moduleHeight = document.getElementById(`div_content_${this.layout.id}_${this.module.id}`).clientHeight;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      // activeRoadmapData: "roadmap/programmatic",
      modules: "programmatic/projectModules",
      colorCodes: "global/colorCodes",
      assigns: "timeline/getAssigns",
      parameterId: 'roadmap/parameterId'
    }),

    processedFilterColls() {
      // let filteredCollections = this.$lodash.cloneDeep(this.filteredCollections);
      let collections = this.getData(this.filteredCollections);
      let items = collections.map((item, index) => {
        let parameter = item.properties?.find(i => i.property_collection_id == this.parameterId);
        if (parameter) {
          item.background = getBackground(this.widget, parameter.sample_value)
          item.amount = progress(parameter)
        }
        item.space = getTheGap(this.flatData, item, index);
        return item
      });
      // this.load(items)
      return items
      //return filteredCollections;
    },
    activeBg() {
      return !!(this.layout && this.layout.settings && this.layout.settings.color);
    },
    widgetId() {
      return this.widget.id
    },
    assigns() {
      return this.widget.assigns
    },
    projectId() {
      return this.$route.params.id
    },
    getModuleSettings() {
      let module = this.modules.find(item => parseInt(item.id) === parseInt(this.module.id));
      if (module) {
        return JSON.parse(module.collection_settings);
      }
      return null;
    },
    getSelectedParam() {
      return getSelectedParam(this.widget, this.module)
    }
  },
  methods: {
    ...mapActions({
      fetchAssigns: "timeline/fetchAssigns"
    }),
    getData(items) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if (moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
      } else if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
        return items.filter(data => this.layout.isolated_ids.includes(data.id) && !data.root);
      }
      return items;
    },
    getAssignsLabelWidth() {
      return this.assigns.length * 55 + 'px'
    },
    getSelectedParamName() {
      return getSelectedParamName(this.widget, this.module)
    },
    // getSelectedParam() {
    //   return getSelectedParam(this.widget, this.module)
    // },
    getCollectionIds() {
      return this.filteredCollections.map(item => item.id);
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(item) {
      if (item && item.background_color) {
        return item.background_color;
      }
      let collectionSettings = this.getModuleSettings;
      if (collectionSettings && collectionSettings.collections.length > 0) {
        let colorCodes = this.getLevelWiseColorCode(item.level);
        return colorCodes[collectionSettings.collections[item.level].color];
      }
      return null;
    },
    handleCollapse() {
      this.isExpanded = !this.isExpanded
    },
    async updateWidgetSettings() {
      await this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    getWidth() {
      let minimumWidth = 460
      let assigns = this.widget.assigns.filter(assign => assign.name)
      if (assigns) {
        assigns.map(assign => minimumWidth += 52)
      }
      return minimumWidth;
    },
    toggleWidgetSettings(e) {
      e.preventDefault();
      this.settings = !this.settings;
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    setParameter() {
      let item = this.getSelectedParam
      if (item && item.id) {
        this.$set(this.layout, 'selectedPropertiesId', item.id)
      }
    },
    isParamSelected() {
      if (this.getSelectedParam && this.getSelectedParam.id == this.layout.selectedPropertiesId) {
        return true
      }
      return false
    },
    _getItemVisibility(item) {
      return getItemVisibility(this.layout, item)
    },
    loadAModuleData() {
      this.$emit("loadAModuleData");
    },
  }
};
</script>

<style>
.minimized-bar-timeline {
  height: calc(100% - 110px);
}

:root {
  --people-element-with: 63px;
}

.people-element-with {
  min-width: var(--people-element-with);
}

.btn-area {
  width: 100px;
  height: 20px;
  border-radius: 4px;
}

.logic-parameter-outline {
  float: left;
  width: 80px;
  margin-left: 4px;
  height: 20px;
  border-radius: 2px;
}

.logic-box-container {
  float: left;
  width: 80px;
  height: 20px;
}

.box-container-operator {
  width: 96px;
}

.default-style {
  /*min-width: 250px;*/
  width: 440px;
}

.group-shadow-bg {
  height: 50px;
}

.group-setting {
  height: 40px;
}

.widget-setting-icon {
  width: 13px;
  height: 13px;
}

.value-text-pos {
  top: -18px;
  left: 4px;
}

.dashed-border {
  border: 1px dashed #C9C9C9;
}

.no-border {
  border: none;
}

.fs-6 {
  font-size: 6px;
}

.none-margin {
  margin: 0;
}

.minimized-bar-w {
  width: 38px;
}
</style>
