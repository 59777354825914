<template>
    <div  class="flex space-x-2.5">
        <div v-click-outside="hideNote" class="relative panzoom-exclude">
            <div
                @click="showNote = !showNote"
                class="flex items-center justify-center border border-secondary-five rounded-sm w-5 h-5 cursor-pointer"
                :style="{backgroundColor: hasNote ? bgColor : '#fff'}"
            >
                <EditIcon
                    class="w-3 h-3"
                    :class="{'opacity-40': !hasNote, 'text-white': hasNote}"
                />
            </div>
            <div v-if="showNote" style="width: 238px; height: 151px;" class="absolute z-20 top-0 left-5 bg-white pt-1.5 pb-2 px-2.5 rounded-sm border border-secondary-five shadow-one">
<!--                <input-->
<!--                    placeholder="Title"-->
<!--                    v-model="note.title"-->
<!--                    @input="$emit('onType')"-->
<!--                    type="text" -->
<!--                    class="w-full fw-600 fs-12 bg-transparent placeholder-secondary-five">-->
                <textarea
                    placeholder="Description"
                    type="text"
                    v-model="note.description"
                    style="height: 90px"
                    @input="$emit('onType')"
                    class="fw-500 fs-12 w-full placeholder-secondary-five">
                </textarea>
            </div>
        </div>
            <div v-click-outside="hideComment" class="relative  panzoom-exclude">
            <div
                @click="openComment(collectionId)"
                class="flex items-center justify-center border border-secondary-five rounded-sm w-5 h-5 cursor-pointer"
                :style="{backgroundColor: hasComment ? bgColor : '#fff'}"
            >
                <MessageIcon class="w-3 h-3" :class="{'opacity-40': !hasComment, 'text-white': hasComment}"/>
            </div>
            <!-- comments -->
            <div v-if="showComment" class="absolute z-20 top-0 left-5">
                <RightNavComponentWrapper>
                    <Comments
                        :comments="commentList"
                        :active-id="collectionId"
                    />
                </RightNavComponentWrapper>
            </div>
            <!-- comments -->
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import store  from '@/store'
import Comments from './comment/Comments';

const props = defineProps({
    note: Object,
    bgColor: String,
    commentCount: [String, Number],
    collectionId: [String, Number]
})
const commentList = computed(() => store.getters['drawer/getComments'])
const hasComment = computed(() => {
    return props.commentCount > 0 || hasOwnComment(commentList.value, props.collectionId)
})
const hasNote = computed(() => (props.note.title || props.note.description))

const showComment = ref(false)
const showNote = ref(false)
const hideComment = () => {
    if(showComment.value) {
        showComment.value = false
    }
}
const hideNote = () => {
    if(showNote.value) {
        showNote.value = false
    }
}

const openComment = (collectionId) => {

    if(!showComment.value) {
        fetchComments(collectionId)
    }
    showComment.value = !showComment.value
}

const fetchComments = (collectionId) => {
    store.dispatch('drawer/fetchComments', { id: collectionId, key: 'scenario_collection_id' })
    // console.log("Fetch comments", collectionId)
}

const hasOwnComment = (comments, collectionId) => {
    if(!comments) return false
    if(comments.length) {
        return comments[0].scenario_collection_id == collectionId
    }
}
</script>
