<template>
  <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins">
    <SettingsHeader
        class="mb-5"
        title="Timeline Configuration"
        @onDelete="deleteWidget"
    />

    <div class="flex flex-col gap-5">
      <InputSelect
          v-model="timeline"
          :items="[{id: '', title: 'Timeline Fields'}]"
      />
      <InputSelect
          v-model="timelineFields"
          :items="[{id: '', title: 'Timeline Fields'}]"
      />
    </div>

    <hr class="border-secondary-four mt-5">

    <div class="mt-6" v-for="(parameter, pIndex) in widget.parameters" :key="pIndex">
      <SettingsAccordion
          title="Parameter"
          v-model="parameter.active"
      >
        <div class="flex flex-col gap-2">
          <InputSelect
              v-model="parameter.property_id"
              label="Select Property Set"
              :items="module.properties"
          />
          <InputSelect
              v-if="parameter.property_id"
              v-model="parameter.id"
              label="Select Parameter"
              :items="getPropertyCollection(parameter)"
              @change="updateWidgetSettings()"
          />
        </div>
      </SettingsAccordion>
    </div>

    <hr class="mt-5 mb-2">

    <div class="space-y-2 mt-5">
      <div v-for="(item, a_index) in assigns" :key="a_index">
          <SettingsAccordion
              :title="`Assign ${a_index + 1}`"
              :value="item.active"
              @input="(e) => $set(item, 'active', e)"
          >
              <template v-slot:action>
                  <img src="/images/icons/brainstormV2/plus_circle_outline.svg" alt="icon" class="settings-icon cursor-pointer" @click="newParameter()" />
                  <img src="/images/icons/brainstormV2/close.svg" alt="icon" class="settings-icon ml-2 cursor-pointer" @click="deleteParameter(index)" />
              </template>
              <div class="flex flex-col gap-2 panzoom-exclude">
                <input
                  v-model="item.name"
                  @blur="updateWidgetSettings"
                  class="w-full fw-500 fs-10 i-border-1 border-secondary-five rounded-sm bg-transparent p-1"
                  type="text"
                >
                <AssignColorPicker
                  :item="item"
                  :updateWidgetSettings="updateWidgetSettings"
                />
              </div>
          </SettingsAccordion>
      </div>
    </div>

    <hr class="mt-5">
    <div>
      <LabelColor
        :widget="widget"
        :module="module"
        :layout="layout"
        :moduleHeight="moduleHeight"
        @onClickOutsideColorPicker="hidePicker"
        @onColorChange="onColorChange"
        :updateWidgetSettings="updateWidgetSettings"
        :openColorPicker="openColorPicker"
      />
    </div>
  </div>
</template>

<script>
import LabelColor from '@/components/roadmap/canvas/widgets/timeline/component/LabelColor.vue'
import AssignColorPicker  from '@/components/roadmap/canvas/widgets/timeline/component/AssignColorPicker.vue'
import { deleteWidget } from '@/store/modules/roadmap/services'
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import InputSelect from '@/elements/inputs/InputSelect';
export default {
  name: "TimelineSettings",

  components: {
    LabelColor,
    AssignColorPicker,
    InputSelect,
    SettingsHeader,
    SettingsAccordion
  },

  props: ['widget', 'layout', 'module', 'moduleHeight'],

  data: () => ({
    timeline: '',
    timelineFields: '',
    currentComponentName: '',
    current: {},
    currentIndex: 0,
    rangeDefinitions: ['0-2', '2-4', '4-6', '6-8', '8-10'],
    sectionArr: [{name: '5 Level People Metrics', value: 1}]
  }),


  computed: {
    pickerPositionOffset () {
      return 399 + (30 * this.currentIndex)
    },
    assigns() {
      return this.widget.assigns.map(assign => {
        this.$set(assign, 'active', false)
        return assign
      })
    },
    currentComponent () {
      return this.currentComponentName
    }
  },


  methods: {
    openColorPicker(obj, index) {
      this.currentIndex = index
      this.current = obj
      // this.showPicker = !this.showPicker
    },

    hidePicker() {
      this.showPicker = false;
      this.updateWidgetSettings();
    },
    onColorChange(color)  {
      this.current.bg = color
    },
    getMinRange(rangeText) {
      let rangeArray = rangeText.split("-");
      if(rangeArray[0]) {
        return rangeArray[0];
      }
      return 0;
    },
    updateWidgetSettings() {
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    newParameter() {
      this.widget.assigns.push({ name: null, type: 1, color: '#00A7FE' });
      this.widget.operator.push('+');
    },
    deleteParameter(index) {
      this.widget.assigns.splice(index, 1);
      this.widget.operator.splice(index, 1);
      // if(this.widget.parameters.length <= 0) {
        //   this.widget.parameters = [{ property_id: null, id: null }];
      // }
      if(this.widget.assigns.length <= 0) {
        this.widget.assigns = [{ name: null, type: 1, color: '#00A7FE' }];
      }
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    getPropertyCollection(parameter) {
      let properties = this.module.properties.find(item => item.id === parameter.property_id)
      if(properties) {
        return properties.property_collections.filter(param => !param.option || param.option == 1)
      }
      return []
    }
  },

  created() {
    if(this.widget && (!this.widget.parameters || this.widget.parameters.length <= 0)) {
      this.widget.parameters = [{ property_id: null, id: null }];
    }
  },

}
</script>

<style scoped>
.top-399 {
  top: 399px;
}
/* .l-80{
  left: 80px;
} */
.settings-widget {
  width: 250px;
  min-height: 624px;
  max-height: 724px;
  overflow-y: auto;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
/* .range-section {
  width: 47px;
  height: 14px;
} */
/* .definition-section {
  width: 47px;
} */
/* .value-identifier {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: #8D8D8D;
} */
.param-dropdown:focus {
  border: 1px solid #C9C9C9;
}
.delete-icon {
  width: 17px;
  height: 15px;
}
</style>
