<template>
    <h2
        :class="[textLength > 3 ? '' : 'w-2']"
        :title="fullTitle"
        v-html="getText()"
        class="inner rotate absolute font-poppins text-center truncate fw-600"
    ></h2>
</template>

<script>
export default {
    name: 'VerticalText',
    props: {
      title: {
        default: null
      },
      fullTitle: {
        type: String
      },
      textColor: {
        type: String,
        default: 'text-white'
      }
    },
    computed: {
        textLength() {
            if(this.title) {
                return this.title.toString().length
            }
            return 0
        }

    },
    methods: {
        getText() {
            if(!this.title) return this.addSpan('', true)
            return this.addSpan(this.title, true)
        },

        addSpan(text) {
            let textArr = text.toString().split(' ')
            if(textArr.length === 1) return `<span class="${this.textColor ? this.textColor : 'text-white'}">${textArr[0]}</span>`

            let joinedText = textArr.join(`<span class="tex-transparent">_</span>`)
            return `<span class="${this.textColor ? this.textColor : 'text-white'}">${joinedText}</span>`
        }
    }
}
</script>

<style>
.rotate {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
}
.tex-transparent {
    color: transparent;
}
</style>
