<template>
  <div class="relative" style="display:none;">
    <div class="w-275" :style="{'margin-left': getLeftMargin()}" style="visibility: hidden">
<!--      visibility: getItemVisibility(item) ? 'visible' : 'hidden'-->
      <div
        class="px-2 rd-last-item rounded-sm flex relative items-center border-color-transparent cursor-pointer"
        :style="{
          'background': appearance === 0 && item.appearance === 0 ? getBg(level, item) : 'transparent',
        }"
        style="visibility: hidden"
        :id="'l4_item_' + item.id"
        :class="{
          'i-border-1': activeData.id === item.id || item.appearance === 1,
          'text-white textarea-primary-three shadow-two' : item.appearance === 0,
          'text-primary-one textarea-secondary-one' : item.appearance !== 0,
          'border-secondary-five' : item.appearance === 1 && activeData.id !== item.id,
          'border-primary-one i-border-1' : activeData.id === item.id
        }"
        @click="$emit('setActiveScenarioCollection', item)"
      >
        <span
          class="text-white ls-5 font-poppins fs-13 fw-400" v-if="indexTitle"
          :class="{'text-primary-one textarea-secondary-one' : item.appearance !== 0,}"
        >
          {{ item.index_code }}
        </span>
        <input type="text"
               class="panzoom-exclude focus:outline-none bg-transparent w-full placeholder-secondary-two cursor-pointer text-white ls-5 font-poppins fs-13 fw-400 truncate"
               v-model="item.title"
               :class="{'ml-1': indexTitle, 'text-primary-one textarea-secondary-one' : item.appearance !== 0}"
               v-on:keyup.delete="$emit('deleteItem', $event, item.id)"
               @input="regularUpdate()"/>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import { getItemVisibility } from '@/store/modules/roadmap/services.js'

export default {
  name: "JumpedData",
  props: {
    item: {
      type: Object,
      required: true
    },
    layerOptions: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      required: true
    },
    layout: {
      type: Object,
      required: true
    },
    indexTitle: {
      default: true
    },
    description: {
      default: true
    },
    appearance: {
      default: true
    },
    moduleSettings: {
      //
    },
  },
  computed: {
    ...mapGetters({
      activeData: "programmatic/activeScenarioCollection",
      colorCodes: "global/colorCodes",
    })
  },
  data() {
    return {
      expandLevels: [
        { level: 1, margins: ['405px', '680px', '915px', '1190px'] },
        { level: 2, margins: ['335px', '610px'] },
        { level: 3, margins: ['30px'] },
      ],
      defaultMargins: ['170px', '100px', '30px']
    }
  },
  methods: {
    getLeftMargin() {
      let count = 0;
      let opensArray = [];
      for (let i = this.level + 1; i <= 3; i++) {
        if(this.layerOptions[i].layer > 1) {
          opensArray.push(this.layerOptions[i].layer - 1);
          count += (this.layerOptions[i].layer - 1);
        }
      }

      if(count > 0) {
        if(this.level === 1) {
          if(opensArray[0] === opensArray[1] && opensArray[0] === 1) {
            return "640px";
          }
        }
        return this.expandLevels[this.level - 1].margins[count - 1];
      }
      return this.defaultMargins[this.level - 1];
    },
    getBg(level, item) {
      if (!item) return
      if (item && item.background_color) {
        return item.background_color;
      }
      let collectionSettings = this.moduleSettings;
      if (collectionSettings && collectionSettings.collections.length > 0) {
        if (collectionSettings.collections[level].custom_bg) {
          return collectionSettings.collections[level].custom_bg;
        }
        let colorCodes = this.getLevelWiseColorCode(level);
        return colorCodes[collectionSettings.collections[level].color];
      }
      return null;
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getItemVisibility(item) {
      return getItemVisibility(this.layout, item)
    },
    regularUpdate() {
      this.$emit('regularUpdate', this.item);
    }
  }
}
</script>

<style scoped>
.w-275 {
  width: 275px;
}
.jumped-3rd {
  left: 30px;
  top: 0;
}
.jumped-2nd {
  left: 100px;
  top: 0;
}
.jumped-1st {
  left: 170px;
  top: 0;
}
</style>
