<template>
    <div class="flex items-center justify-between">
      <h4 class="fs-10 fw-500 text-secondary-one">{{ title }}</h4>
      <!-- <div class="flex items-center">
        <img src="/images/roadmap/delete_widget.svg" alt="icon"
        class="cursor-pointer w-4 h-4"
        content="Delete Widget"
        v-tippy="{ placement : 'top',  arrow: true }"
        @click="$emit('onDelete')" />
        <img src="/images/roadmap/widget_load.svg" alt="icon" class="ml-3" />
      </div> -->
    </div>
</template>

<script>
    export default {
        name: "SettingsHeader",
        props: {
            title: {
                type: String,
                default: ''
            }
        }
    }
</script>