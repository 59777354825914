<template>
  <div>
    <div class="relative add-widget bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm ml-5" @contextmenu="showMenu($event)" v-click-outside="hideMenu">
      <div class="add-group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five">
        <img src="/images/roadmap/settings_icon.svg" alt="icon" class="widget-setting-icon" />
      </div>
      <MenuComponent
        class="absolute transform translate-y-2"
        ref="menu"
        @updateSettings="updateWidget"
      />
    </div>
    <div class="empty-widget-bar bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm ml-5 mt-8 calculative-height">
      <div class="h-full w-full bg-white shadow-two rounded-sm"></div>
    </div>
  </div>
</template>

<script>
import MenuComponent from './MenuComponent'
export default {
  name: "EmptyWidget",
  props: ['module', 'layout', 'widget', 'filteredCollections'],
  components: {
    MenuComponent
  },
  created() {
    const self = this;
    setTimeout(() => {
      self.moduleHeight = document.getElementById(`div_content_${self.layout.id}_${self.module.id}`).clientHeight;
    }, 1500);
  },
  data() {
    return {
      moduleHeight: 132
    }
  },
  methods: {
    showMenu(e) {
      e.preventDefault();
      this.$refs.menu.toggleMenu();
    },
    hideMenu() {
      this.$refs.menu.hideMenu();
    },
    updateWidget(type) {
      this.$set(this.widget, 'type', type);
      // this.widget.type = type;
      let parameter = { property_id: null, id: null };

      // for people only
      if(type === 4) {
        parameter.people_id = '';
      }

      // for timeline only
      if(type === 7) {
        this.$set(this.widget, 'assigns', [])
        this.widget.assigns.push({ name: null, type: 1, color: '#00A7FE' });
      }

      this.$Progress.start();
      this.widget.parameters = [parameter];
      this.widget.collection_ids = this.getCollectionIds();
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget).then(response => {
        this.$Progress.finish();
        let widgetInLayout = this.layout.widgets.findIndex(widget => widget.id === this.widget.id);
        if(widgetInLayout >= 0) {
          this.layout.widgets[widgetInLayout] = this.widget;
          this.$emit("reRenderEmptyWidget");
        }
        // this.$store.dispatch("roadmap/getProgrammatic", { roadmap_id: this.$route.params.roadmap_id }).then(data => {
        //   this.$Progress.finish();
        // });
      });
    },
    getCollectionIds() {
      return this.filteredCollections.map(item => item.id);
    }
  }
}
</script>

<style scoped>
.group-setting {
  height: 40px;
}
.widget-setting-icon {
  width: 13px;
  height: 13px;
}
.add-widget {
  height: 50px;
  width: 50px;
}
.add-group-setting {
  height: 40px;
}
.empty-widget-bar {
  width: 50px;
}
.calculative-height {
  height: calc(100% - 76px)
}
</style>
