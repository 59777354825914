import store from '@/store';
import { computed } from 'vue'

export default function useDecisionParamUpdate() {
    const activeRoadmapData = computed(() => store.getters['roadmap/programmatic'])
    const activeData = computed(() => store.getters['programmatic/activeScenarioCollection'])

    const updateParameterValue = (item, parameterId, value, cb) => {
        let collectionParam = _parameterNextValue(item, parameterId, Number(value), activeRoadmapData.value);
        let itemParameter = item.properties?.find(data => parseInt(data.property_collection_id) === parseInt(parameterId));
        if (itemParameter) {
            itemParameter.sample_value = collectionParam.value;
        } else {
            item.properties.push({
                scenario_collection_id: item.id,
                sample_value: collectionParam.value,
                property_collection_id: parameterId
            });
        }

        store.dispatch("programmatic/updatePropertyItem", collectionParam).then(response => {
            if (response && response.data && response.data.data && response.data.data.id) {
                if (!itemParameter) {
                    itemParameter = item.properties.find(data => parseInt(data.property_collection_id) === parseInt(parameterId));
                    if (itemParameter) {
                        itemParameter.id = response.data.data.id;
                    }
                }
                cb()
            }
        });

        if (activeData.value && activeData.value.id == item.id) {
            let dataObj = {
                property_collection_id: parameterId,
                sample_value: collectionParam.value
            };
            store.commit("programmatic/updateActiveItemParameterValue", dataObj);
        }
    }

    const _parameterNextValue = (item, parameterId, value, roadmap) => {
        let collectionParam = {};
        collectionParam.sc_id = item.id;
        collectionParam.scenario_id = roadmap.scenario_id;
        collectionParam.project_id = roadmap.project_id;
        collectionParam.property_collection_id = parameterId;
        collectionParam.id = parameterId;
        collectionParam.module_id = item.module_id;
        value = value + 2.5;
        collectionParam.value = value;
        if(value > 10) {
            collectionParam.value = 0;
        }
        return collectionParam;
    }

    const getParameterTextDefinition = (value, definitionStyles) => {
        if(value > 0) {
            value = value - .000001;
        }
        let valueIndex = Math.floor(value / 2.5);
        if(valueIndex > 4) {
            valueIndex = 4;
        }
        if(definitionStyles[valueIndex]) {
            return definitionStyles[valueIndex].definition;
        }
        return null;
      }

    return {
        updateParameterValue,
        getParameterTextDefinition
    }
}