<template>
    <div class="relative font-poppins ml-5">
        <div class="h-6">
            <WidgetTitle
                name="Coordinate"
                v-show="isExpanded"
                v-model="widget.title"
                @updateWidgetTitle="updateWidgetTitle(widget)"
            >
                <img src="/images/roadmap/delete_widget.svg" alt="icon"
                    class="cursor-pointer"
                    content="Delete Widget"
                    v-tippy="{ placement : 'top',  arrow: true }"
                    @click="() => { canEdit(project.cans) && onDeleteWidget(widget, layout) }"
                />
            </WidgetTitle>
        </div>

        <div
            class="group-shadow-bg bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm"
            :class="[isExpanded ? 'm-w-260' : 'w-12']"
        >
            <CollapseHandle 
                :is-expanded="isExpanded" 
                class="absolute" 
                :handler="handleCollapse"
            />
            <div class="panzoom-exclude group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five handler"
                @contextmenu="toggleWidgetSettings($event)">
                <img v-show="isExpanded" src="/images/roadmap/settings_icon.svg" alt="inst-icon" class="widget-setting-icon"/>
            </div>
            </div>
            <CollapsedBar
                :is-expanded="isExpanded"
                title="Coordinate:"
                :widget="widget"
            />

            <div v-show="isExpanded">
                <div v-if="showSetting">
                    <Setting 
                        class="absolute mt-8"
                        :columns="widget.columns"
                        :module="module"
                        :widget="widget"
                        :component-names="getComponentNames()"
                    />
                </div>

                <div v-else class="flex">
                    <div v-for="(column, columnIndex) in widget.columns" :key="`column.componentName-${columnIndex}`">
                        <component
                            :is="getComponent(column.componentName)"
                            :column="column"
                            :widget="widget"
                            :layout="layout"
                            :module="module"
                            :filtered-collections="filteredCollections"
                            :flat-data="flatData"
                            :isolated-modules="isolatedModules"
                            @onUpdateParameterValue="$emit('loadAModuleData')"
                        />
                    </div>
                </div>
            </div>
        </div>
</template>

<script setup>
import { watch } from 'vue'
import useBusiness from './useBusiness';
import usePermission from '@/composable/usePermission.js'
import {getComponentNames, getComponent} from './useImporter'
//components
import WidgetTitle from '@/components/roadmap/WidgetTitle.vue'
import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
import CollapseHandle from '@/components/roadmap/canvas/widgets/CollapseHandle'
import Setting from './Setting.vue';

const {
    isExpanded,
    showSetting,
    project,
    updateWidgetTitle,
    onDeleteWidget,
    handleCollapse,
    toggleWidgetSettings,
    setInitialData
} = useBusiness()
const { canEdit, canCreate, canDelete } = usePermission()

const props = defineProps({
    widget: Object,
    module: Object,
    layout: Object,
    filteredCollections: Array,
    flatData: Array,
    isolatedModules: Array,
})

watch(
    ()=> props.widget,
    ()=> {
        setInitialData(props.widget)
    },
    {immediate: true}
)
</script>