<template>
  <div class="h-full">
        <PanzoomComponent>
          <div class="space-y-6 mt-40 ml-10">
              <div v-for="(module, index) in getModules" :key="index">
                <ModuleData
                  :module="module"
                  :moduleIndex="index"
                  :isolatedModules="isolatedModules"
                  @onClickLoad="openModuleSelectModal"
                  @setLayoutModule="setLayoutModule"
                  @toggleToPropertyMode="toggleToPropertyMode"
                  :ref="`module_${module.id}`"
                />
              </div>
          </div>
        </PanzoomComponent>
       <LayoutSelectionModal
        :dModal="loadingModal"
        :selected="selected"
        :setSelected="setSelected"
        @setLayoutModule="setLayoutModule"
        @toggleModalMode="toggleLoadingModalMode"
        @onClickOpen="handleLayoutChange"
      />
      <!-- <div style="position: fixed; width: 100%; height: 100%; z-index: 999999; inset: 0;" class="bg-red-100">
      Lorem ipsum dolor sit amet.
    </div> -->
    <!-- start date selection modal -->
    <Modal v-model="showModal">
      <div class="py-5 px-3 bg-white shadow-one">
        <p class="fs-12 text-secondary-two mb-2">
          <span v-if="modalData.type == 0">Select start date</span>
          <span v-else>Select end date</span>
        </p>
        <Datepicker v-model="modalData.date" :inline="true" @input="setDate"/>
        <div class="flex items-center justify-between mt-4">
          <div class="flex items-center gap-3">
            <input v-model="setToAll" id="date-checkbox" type="checkbox" value="" style="visibility: visible">
            <label class="fs-10 text-secondary-two" for="date-checkbox">Set this date to all empty field</label>
          </div>
          <button @click="updateDate()" class="text-secondary-one fs-12 shadow-one rounded-sm px-4 py1">Ok</button>
        </div>
      </div>
    </Modal>
    <!-- end date selection modal -->
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ModuleData from './Container/ModuleData.vue'
import LayoutSelectionModal from '@/components/roadmap/modal/LayoutSelectionModal'
// import Zoomable from "@/elements/Zoomable"
import Modal from '@/elements/atom/Modal.vue';
import Datepicker from 'vuejs-datepicker';
import useTimeline from '@/components/roadmap/canvas/widgets/timeline/useTimeline'
import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'

export default {
  name: "RoadmapIndex",
  components: {
    ModuleData,
    LayoutSelectionModal,
    PanzoomComponent,
    // Zoomable,
    Modal,
    Datepicker,
  },
  props: ['isolatedModules', 'isolatedModuleId'],
  data: () => ({
    selected: '',
    clickedLayoutId: '',
    loadingModal: false,
    initialX: 0,
    initialY: 0,
    initialYF: 0,
    initialZoom: 1,
    layoutModule: { source: null, target: null }
  }),
  created() {
    this.$store.dispatch('programmatic/getModules', this.activeRoadmapData.project_id)
  },

  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
    }),
    getModules() {
      if (this.isolatedModuleId) {
        return this.activeRoadmapData.modules.filter(module => module.id === this.isolatedModuleId);
      }
      return this.activeRoadmapData.modules;
    }
  },
  methods: {
    ...mapActions({
      getAllLayoutsOfWorkspace: 'roadmap/getAllLayoutsOfWorkspace',
      replaceLayout: 'roadmap/replaceLayout',
    }),
    setSelected(id) {
      this.selected = id
    },
    toggleLoadingModalMode(value) {
      this.loadingModal = value;
    },
    toggleToPropertyMode() {
      this.$emit("toggleToPropertyMode");
    },
    scrollReset() {
      let myDiv = document.getElementById('pan-zoom-runner')
      if(myDiv) {
        myDiv.scrollTop = 0
      }
    },

    openModuleSelectModal(layoutId) {
      this.loadingModal = true
      this.selected = layoutId
      this.clickedLayoutId = layoutId
    },
    setLayoutModule(moduleId, key) {
      this.layoutModule[key] = moduleId;
    },
    handleLayoutChange() {
      this.$Progress.start();
      let payload = {
        layoutId: this.clickedLayoutId,
        data: {
          reference_layout_id: this.selected,
          same_module: this.layoutModule.source === this.layoutModule.target
        }
      }
      this.replaceLayout(payload)
      .then(res => {
       this.$store.dispatch("roadmap/getProgrammatic", { roadmap_id: this.$route.params.roadmap_id })
        .then(() => {
          this.$Progress.finish();
          this.$store.dispatch("roadmap/getAllLayoutsOfWorkspace", { project_id: this.$route.params.id });
        })
      })
    },
    loadAModuleData(moduleId) {
      this.$refs[`module_${moduleId}`][0].loadAModuleData();
    }
  },

  setup() {
    const {
      formatDate,
      updateDate,
      showModal,
      setToAll,
      modalData,
      openDateSelectionModal,
      setDate,
      updateEndDate,
      onDateChange,
      activeRoadmapData
     } = useTimeline()


    return {
      formatDate,
      updateDate,
      showModal,
      setToAll,
      modalData,
      openDateSelectionModal,
      setDate,
      updateEndDate,
      onDateChange,
      activeRoadmapData
    };
  },

}
</script>

<style scoped>
.canvas-top-gap {
    padding-top: 160px;
    padding-left: 40px;
}
.canvas-container {
  height: 1080px;
  overflow: scroll;
}
.bs-content-div {
  height: 920px;
}
.bs-content-full-div {
  height: 1080px;
}
</style>
