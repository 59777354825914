var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.column.title),expression:"column.title"}],staticClass:"w-20 fw-400 fs-8 bg-transparent mt-3 text-secondary-one text-center px-2 panzoom-exclude",attrs:{"type":"text"},domProps:{"value":(_vm.column.title)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.column, "title", $event.target.value)},function($event){return _setup.updateWidgetSettings(_vm.widget)}]}}),_c('div',{staticClass:"bg-primary-two mt-1 business-widget-padding panzoom-exclude"},_vm._l((_setup.getDataByIsolatedModules(_vm.filteredCollections, _vm.isolatedModules, _vm.module, _vm.layout)),function(item,index){return _c('div',{key:item.id},[_c('input',{staticClass:"w-20 flex text-center justify-center items-center bg-white rounded-sm border border-secondary-five fw-400 fs-12 text-secondary-one",class:[_setup.getTheGap(_vm.flatData, item, index)],staticStyle:{"height":"20px"},attrs:{"type":"text"},domProps:{"value":_setup.getFieldParameterValue(item, _vm.column)},on:{"input":function($event){return _setup.updateSampleValue($event, item, _vm.column, {
                    id: _vm.column.id,
                    module_id: _vm.module.id,
                    project_id: _vm.$route.params.id,
                    property_collection_id: _vm.column.id,
                    sc_id: item.id,
                    scenario_id: item.scenario_id,

                })}}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }