<template>
    <div class="">
        <div class="flex items-center">
            <h4 class="text-secondary-two fs-10 fw-500 mt-4">Color Style </h4>
            <!-- <img src="/images/icons/chevron_down.svg"
                alt="icon"
                class="cursor-pointer rotate-180" 
            /> -->
        </div>
            
      <div class="flex justify-between mt-3">
        <h4 class="fs-10 fw-500 text-primary-one">Levels %</h4>
        <h4 class="fs-10 fw-500 text-primary-one">Logic</h4>
      </div>
      <div class="" v-click-outside="hidePicker">
        <div :style="{top : pickerPositionOffset+'px'}" class="absolute color-picker-div l-80" v-if="showPicker">
            <div class="flex flex-col items-center justify-center mt-3">
                <color-panel 
                    v-model="color" 
                    @change="onColorChange">
                </color-panel> 
            </div>
        </div>
        <div v-for="(definitionStyle, index) in widget.definition_style" :key="index" class="flex justify-between items-center mt-2" :class="{'mt-4': index > 0}" >
          <div @click.prevent="handleOpen(definitionStyle, index)" class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center mr-5 cursor-pointer"
            :style="{'background': definitionStyle.bg}"
          >{{ definitionStyle.range }}
          </div>
          <div class="bg-white shadow-two rounded-sm p-1 w-20">
            <div :style="{'background': definitionStyle.bg, width: getMinRange(definitionStyle.range)*10+'%'}" class="bg-primary-four rounded-sm p-1"></div>
          </div>
          <div class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center ml-5" :style="{'background': definitionStyle.bg}">
            <input @blur="updateWidgetSettings" type="text" class="panzoom-exclude bg-transparent definition-section text-center" v-model="definitionStyle.definition" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'FiveLevelPeople',

    props: ['widget', 'layout', 'module', 'moduleHeight', 'openColorPicker', 'updateWidgetSettings'],

    data: () => ({
        color: '',
        showPicker: false,
    }),

    computed: {
        pickerPositionOffset () {
            return 399 + (30 * this.currentIndex)
        },
    },

    methods: {
        handleOpen(obj, index) {
           this.openColorPicker(obj, index)
            this.showPicker = !this.showPicker
        },
        hidePicker() {
            if(this.showPicker) {
                this.$emit('onClickOutsideColorPicker');
            }
            this.showPicker = false;
        },
        onColorChange(color)  {
            this.$emit('onColorChange', color);
            // this.current.bg = color
        },
        getMinRange(rangeText) {
            let rangeArray = rangeText.split("-");
            if(rangeArray[0]) {
                return rangeArray[0];
            }
            return 0;
        },
    }

}
</script>

<style scoped>
.top-399 {
  top: 399px;
}
.l-80{
  left: 80px;
}
.settings-widget {
  width: 250px;
  min-height: 624px;
  max-height: 724px;
  overflow-y: auto;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
.range-section {
  width: 47px;
  height: 14px;
}
.definition-section {
  width: 47px;
}
.value-identifier {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: #8D8D8D;
}
.param-dropdown:focus {
  border: 1px solid #C9C9C9;
}
.delete-icon {
  width: 17px;
  height: 15px;
}
</style>
