<template>
    <modal name="module-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
        <div class="p-10">
            <div class="flex i-border-b-1 border-secondary-four">
                <h3 class="font-poppins text-primary-one opacity-50 fs-20 cursor-pointer ws-modal-item-selector i-border-b-1 border-primary-one opacity-100">
                    Layouts</h3>
                <!-- <h3 class="font-poppins text-primary-one opacity-50 fs-20 ws-modal-mr cursor-pointer" :class="{'ws-modal-item-selector i-border-b-1 border-primary-one opacity-100': is_creating}"
                    @click="is_creating = true, showList = false">
                    Create new</h3> -->

            </div>
            <div class="i-mt-30">
                <!-- <div class="" :class="{'d-none': showList}">
                    <h4 class="font-poppins text-secondary-one fs-14">Title</h4>
                    <div class="flex justify-between items-center mt-2 relative">
                        <div class="modal-collaborator flex-3 pr-3 relative">
                            <input type="text"
                                   class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                                   :placeholder="placeholder.title" @click="placeholder.title = ''"
                                   v-click-outside="setTitlePlaceholder"
                                   v-model="conceptObj.title">
                        </div>
                        <h2 class="font-poppins fs-14 absolute open-list-btn cursor-pointer text-primary-one" @click="showList = true" v-if="!is_creating">Open List</h2>
                    </div>
                </div> -->
                <div id="scrolling_div" class="i-mb-30 i-pr-30 overflow-y-scroll ws-scrollbar" style="height: 200px">
                    <div v-for="(item, index) in workspaceLayouts" :key="index"
                        :id="item.id"
                         class="cursor-pointer flex items-center mb-2 h-10 i-pl-10 ws-update-item"
                         :class="{'rounded i-border-1 border-secondary-two': selected == item.id}"
                         @click="setSelected(item.id), $emit('setLayoutModule', item.module_id, 'source')">
                        {{ item.title }}
                    </div>
                </div>
                <div class="flex i-mt-30">
                    <!-- <button v-if="is_creating" class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                            :class="{'pointer-events-none cursor-not-allowed': !activeBrainstormData.module_id && !activeBrainstormData.scenario_id}"
                            :disabled="loading || !conceptObj.title || (!activeBrainstormData.module_id && !activeBrainstormData.scenario_id)" @click="save()">
                        Create
                    </button> -->
                    <button
                        @click="$emit('onClickOpen'), closeModal()"
                         class="fs-1 mr-5 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                    >
                        Open
                    </button>
                    <button class="focus:outline-none fs-14 fw-600 font-poppins i-pr-35 i-border-1 border-secondary-one rounded text-primary-one h-10 w-40"
                            @click="closeModal()">Cancel
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name : 'ModuleSelectionModal',
    data: () => ({
        //showList: true,
    }),

    props: ['dModal', 'selected', 'setSelected'],

    watch: {
        dModal: function (val) {
            if (val) {
                this.openModal();
                this.setScroll();
            } else {
                this.closeModal();
            }
        },

    },
     computed: {
        ...mapGetters({
            workspaceLayouts: "roadmap/workspaceLayouts",
        })
    },
    methods: {
        closeModal() {
            this.$modal.hide('module-modal');
            this.$emit('toggleModalMode', false);
        },
        openModal() {
            this.$modal.show('module-modal');
        },
        setScroll() {
            setTimeout(()=> {
                let item = document.getElementById(this.selected)
                let top = item.offsetTop;
                document.getElementById('scrolling_div').scrollTop = top-200;
            }, 100)
        }
    }
}
</script>
