<template>
  <div>
    <input type="text"
           class="bg-transparent rounded-sm fs-10 fw-400 text-primary-one logic-parameter-outline text-center"
           :title="`${item.index_code} ${item.title}`"
           @keyup="updateParameterValue()"
           placeholder="50"
           v-model="itemValue"
    />
  </div>
</template>

<script>
export default {
  name: "IndividualCustomField",
  props: {
    item: Object,
    parameter: Object
  },
  data() {
    return {
      itemValue: 0
    }
  },
  created() {
    let item = this.parameter.item_values.find(data => data.id === this.item.id);
    if(item) {
      this.itemValue = item.sample_value;
    } else {
      this.itemValue = 0;
    }
  },
  methods: {
    updateParameterValue() {
      let item = this.parameter.item_values.find(data => data.id === this.item.id);
      if(item) {
        item.sample_value = this.itemValue;
      }
      this.$emit('updateWidgetSettings');
    }
  }
}
</script>

<style scoped>

</style>
