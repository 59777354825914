import { render, staticRenderFns } from "./AssignButton.vue?vue&type=template&id=1a40d818&scoped=true&"
import script from "./AssignButton.vue?vue&type=script&lang=js&"
export * from "./AssignButton.vue?vue&type=script&lang=js&"
import style0 from "./AssignButton.vue?vue&type=style&index=0&id=1a40d818&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a40d818",
  null
  
)

export default component.exports