<template>
    <div class="">
        <template v-for="n in 4">
            <div class="flex items-center mb-1" :key="n">
                <div class="small-btn mr-2 flex justify-center items-center text-white btn-font">Low</div>
                <div class="small-btn flex justify-center items-center text-white btn-font">Low</div>
                <span class="px-1">=</span>
                <div class="big-btn flex justify-center items-center text-white btn-font">High</div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'FourZonePeople'
}
</script>


<style scoped>
.small-btn {
    height: 20px;
    width: 50px;
    border-radius: 2px;
    background-color: #FF00A8;
}
.big-btn {
    height: 20px;
    width: 80px;
    border-radius: 2px;
    background-color: #FF00A8;
}
.btn-font {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
}
</style>
