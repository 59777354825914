<template>
    <div>
        <TopTitle
            title="Comments"
            icon-type="layout"
            :is-list="isList"
            :setLayoutType="setLayoutType"
        />
        <div class="grid gap-5 mt-4" :class="isList ? 'grid-cols-1' : 'grid-cols-2'">
            <div v-for="comment in getComments" :key="comment.id" class="relative">
                <div v-if="comment.replies && comment.replies.length > 0" class="absolute top-0 h-full border-r border-secondary-four left-3 ml-4"></div>
                <Comment
                    :comment="comment"
                    :is-list="isList"
                    :active-id="activeId"
                />
            </div>
        </div>

        <div>
              <textarea class="bg-transparent placeholder-secondary-five border-secondary-two border rounded mt-4 px-2 py-2 fs-14"
                        placeholder="Comment here"
                        v-model="commentText" style="width: 100%">

              </textarea>
          <div class="space-x-5 mt-3 mb-5">
            <button class="bg-secondary-one rounded-sm text-white i-h-30 fw-400" @click="addComment()"
                    :class="{'fs-10 w-16': !isList, 'fs-12 w-20' : isList}">Comment</button>
          </div>
        </div>

        <button v-if="comments && comments.length > 2" class="text-primary-four fs-14 fw-500 mt-4 flex justify-center w-full" @click="displayAll = !displayAll">See {{ !displayAll ? 'All' : 'Less' }}</button>
    </div>
</template>


<script>
import sidebarMixin from '@/components/InstMenu/right-navigation-drawer/mixins.js'
import TopTitle from '@/components/InstMenu/right-navigation-drawer/components/TopTitle.vue'
import Comment from '@/components/InstMenu/right-navigation-drawer/module/comments/Comment.vue'
import {mapActions, mapGetters} from "vuex";

export default {
    name : 'Comments',
    props: {
       comments: {
         type: Array
       },
       activeId: {
         type: Number
       }
    },
    data: () => ({
      displayAll: false,
      commentText: null
    }),
    mixins: [sidebarMixin],
    components: {
        TopTitle,
        Comment
    },
    computed: {
      ...mapGetters({
        profileData: "profile/data",
      }),
      getComments() {
        if(this.comments && this.comments.length > 0) {
          return this.displayAll ? this.comments : this.comments.slice(0, 2);
        }
        return [];
      }
    },
    methods: {
      ...mapActions({
        storeComment: 'drawer/storeComment'
      }),
      addComment() {
        let data = {
          scenario_collection_id: this.activeId,
          comment: this.commentText
        };

        // let comment = data;
        // comment.created_by = Object.assign({}, this.profileData);
        // comment.created_by.image = { file_path: this.profileData.image };
        // comment.created_at = new Date();
        this.commentText = '';
        this.storeComment(data).then(response => {
          if(response && response.data && response.data.data && response.data.data.id) {
            this.comments.push(response.data.data);
          }
        });
      }
    }
}
</script>
