<template>
  <div :title="`${item.index_code} ${item.title}`" class="w-full cursor-pointer panzoom-exclude" @click="updateSliderValue(sample_value)">
    <p 
      v-if="systemParameter && (!systemParameter.option || systemParameter.option === 1)"
      class="px-1 truncate w-full"
    >
      {{ getParameterValue }}</p>
    <input
        v-if="systemParameter && systemParameter.option && systemParameter.option === 2"
        class="w-20 bg-transparent rounded-sm fs-10 fw-400 text-primary-one text-center cursor-initial px-1 h-5 mr-1 mt-0.5"
        type="text"
        v-model="getParameterValue"
        @keyup="shareValue(sample_value)"
        placeholder="50"
    />
  </div>
</template>

<script>

import {cloneDeep} from "lodash";

export default {
  name: "SystemParameter",
  props: {
    item: {
      type: Object,
      required: true
    },
    parameter: {
      type: Object,
      required: true
    },
    systemParameter: {
      type: Object
    },
    filteredCollections: Array,
    module: Object
  },
  computed: {
    getParameterValue: {
        get() {
            let value = 0;
            if(this.item && this.item.properties && this.item.properties.length > 0 && this.parameter && this.parameter.id) {
                let param = this.item.properties?.find(data => data.property_collection_id === this.parameter.id);
                if(param) {
                  value = param.sample_value ? param.sample_value : 0;
                }
            }
            if(this.systemParameter.format == 2) {
              value = value * 10;
            }
            return value;
            },
            set(value) {
                this.sample_value = value;
            }
        }
  },
  data() {
    return {
      sample_value: 0
    }
  },
  created() {
    let value = this.getParameterValue || 0;
    value = this.parameter.custom_type == 3 ? value / 100 : value;
    this.sample_value = !this.systemParameter.option || this.systemParameter.option === 1 ? value.toFixed(2) : value
  },
  methods: {
    updateSliderValue(value) {
      value = cloneDeep(parseFloat(value));
      if(!this.systemParameter.option || this.systemParameter.option == 1) {
        if(this.systemParameter.format == 2) {
          value = value / 10;
        }

        value = value + 2;
        value = Math.floor(value);
        if(value > 10) {
          value = 1;
        }
        this.sample_value = value;
        if(this.systemParameter.format == 2) {
          this.sample_value = value * 10;
        }
        this.$emit("updatePropertyValue", this.item, this.parameter.id, value);
      }
    },
    shareValue(value) {
        let reloadable = true;
        if(this.systemParameter && this.systemParameter.option == 2 && this.systemParameter.same_value_apply_to_all) {
            this.filteredCollections.forEach((data) => {
                let propParam = data.properties.find(paramObj => paramObj.property_collection_id == this.systemParameter.id);
                if(propParam) {
                    propParam['sample_value'] = value;
                }
            });
            if(this.module && this.module.temp_properties_value) {
                let moduleParam = this.module.temp_properties_value.find(paramObj => paramObj.property_collection_id == this.systemParameter.id);
                if(moduleParam) {
                    moduleParam['sample_value'] = value;
                }
            }
            reloadable = false;
        }
        this.$emit("updatePropertyValue", this.item, this.parameter.id, value, reloadable);
    }
  }
}
</script>
