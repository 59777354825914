<template>
     <div
        @click="$emit('onClickAssign', {index:index, status: oppositeStatus})"
        class="relative w-10 ls-5 h-5 shadow-one rounded-sm font-poppins fw-400 fs-10 text-white flex justify-center items-center cursor-pointer"
        :style="{backgroundColor: status ? bgColor: '#fff'}"
    >
        {{ text }}
        <div
            v-if="index+1<assignCount"
            style="right:-7px"
            :style="{height: getHeight() }" 
            class="absolute h-5 border-r border-white"
        >
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'AssignButton',

    computed: {
        ...mapGetters({
            getAssign: 'timeline/getAssign'
        }),

        assignList() {
            return this.getAssign(this.widgetId, this.collectionId)
        },

        assignInfo() {
            if(this.assignList) {
                return this.assignList.find(i => i.assign_index == this.index)
            } 
            return null
        },

        status() {
            if(!this.assignInfo) return 0
            return this.assignInfo.status
        },
        oppositeStatus() {
            return this.status == 1 ? 0 : 1
        }
    },
    methods: {
        getHeight() {
            if(this.collectionItemIndex+1 == this.collectionLength) return '20px'
            return '32px'
        }
    },

    props: {
        widgetId: [Number, String],
        collectionItemIndex: Number,
        collectionLength: Number,
        assignCount: Number,
        text: {
            type: String,
        },
        index: Number,
        bgColor: String,
        collectionId: Number,
        isAssigned: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.bg-assigned {
    background-color: #00A7FE;
}
</style>
