import Association from './components/Association'
import Decision from './components/Decision'
import DynamicField from './components/DynamicField'
import SystemContent from './components/SystemContent'
import MetricValue from './components/MetricValue'
import MetricDefinition from './components/MetricDefinition'
import NoteComponent from './components/NoteComponent'
import FieldParameter from './components/FieldParameter'
import { get } from 'lodash'

const components = {
    SystemContent: {
        title: 'System Content',
        component: SystemContent,
        name: 'SystemContent'
    },
    Association: {
        title: 'Associated content',
        component: Association,
        name: 'Association'
    },
    Decision: {
        title: 'Yes & No Style',
        component: Decision,
        name: 'Decision'
    },
    NoteComponent: {
        title: 'Text field style',
        component: NoteComponent,
        name: 'NoteComponent'
    },
    FieldParameter: {
        title: 'Field Parameter style',
        component: FieldParameter,
        name: 'FieldParameter'
    },
    DynamicField: {
        title: 'Dynamic Field',
        component: DynamicField,
        name: 'DynamicField'
    },
    MetricValue: {
        title: 'Metric',
        component: MetricValue,
        name: 'MetricValue'
    },
    MetricDefinition: {
        title: 'Metric + Definition',
        component: MetricDefinition,
        name: 'MetricDefinition'
    },
}

export const getComponent = (name) => get(components, `${name}.component`)
export const getComponentNames = () => Object.keys(components).map(key => {
    return {
        title: components[key].title,
        name: components[key].name
    }
});