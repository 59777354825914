<template>
    <div
        :style="{backgroundColor: bgColor, color: textColor}"
        class="relative w-20 h-5 shadow-one rounded-sm font-poppins fw-400 fs-10 text-white flex justify-center items-center"
        :class="classes"
        @click="onClick()"
    >
        <p class="w-20 px-1 truncate text-center">{{ text }}</p>
        <div
            style="right:-7px"
            :style="{height: getHeight() }"
            class="absolute h-5 border-r border-white"
        >
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatusButton',
    props: {
        collectionItemIndex: Number,
        collectionLength: Number,
        text: {
            type: String,
            default: 'Started'
        },
        bgColor: {
            type: String,
            default: '#AD2B81'
        },
        textColor: {
            type: String,
            default: '#fff'
        },
        classes: Array,
        button: {
          type: Boolean,
          default: false
        }
    },
    methods: {
        getHeight() {
            if(this.collectionItemIndex+1 == this.collectionLength) return '20px'
            return '32px'
        },
        onClick() {
          if(this.button) {
            this.$emit('updateParamValue');
          }
        }
    }
}
</script>
