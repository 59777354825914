<template>
  <div>
    <DefinitionColorPicker 
      :widget="widget"
      :updateWidgetSettings="updateWidgetSettings"
    />
  </div>
</template>

<script>
import SettingsAccordion from "@/components/roadmap/canvas/widgets/SettingsAccordion";
import DefinitionColorPicker from '@/components/roadmap/DefinitionColorPicker.vue'
export default {
  name: "FiveLevelPeople",
  components: {
    SettingsAccordion,
    DefinitionColorPicker
  },
  props: ["widget", "layout", "module", "moduleHeight", "openColorPicker"],

  data: () => ({
    isOpen: true,
  }),

  methods: {
    updateWidgetSettings() {
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
  },
};
</script>
