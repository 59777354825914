<template>
  <div class="">
      <!-- <div class="flex i-mb-15">
        <h4 class="fs-10 fw-500 text-primary-one w-32">Levels</h4>
        <h4 class="fs-10 fw-500 text-primary-one px-1">Avg. Value</h4>
      </div> -->
      <div class="space-y-2 panzoom-exclude" v-click-outside="hidePicker">
        <div class="absolute color-picker-div" v-if="showPicker" style="left: -9px">
          <div class="flex flex-col items-center justify-center mt-3">
            <color-panel v-model="current.bg" @change="onColorChange"></color-panel> 
          </div>
        </div>
        <div v-for="(definitionStyle, index) in widget.definition_style" :key="index" class="flex justify-between items-center text-primary-one">
          <div 
            @click.prevent="openColorPicker(definitionStyle, index)" 
            class="h-5 w-20 fs-10 shadow-two rounded-sm flex justify-center items-center cursor-pointer border border-secondary-five"
            :style="{'background': definitionStyle.bg}"
          >{{ definitionStyle.range }}
          </div>
          <div 
            class="h-5 w-28 fs-10 shadow-two rounded-sm flex justify-center items-center border border-secondary-five" 
            :style="{'background': definitionStyle.bg}"
          >
            <input type="text" class="bg-transparent definition-section text-center w-full" v-model="definitionStyle.definition" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
export default {
    name: 'DefinitionColorPicker',
    components: {
      SettingsAccordion
    },
    data: () => ({
        isOpen: 'true',
        color: '',
        showPicker: false,
        current: {},
        currentIndex: 0,
    }),
    props: {
        widget: {
            required: true,
            type: Object
        }
    },
    methods: {
        onColorChange(color)  {
            this.current.bg = color
        },
        openColorPicker(obj, index) {
            this.currentIndex = index
            this.current = obj
            this.showPicker = ! this.showPicker
        },
        getMinRange(rangeText) {
            let rangeArray = rangeText.split("-");
            if(rangeArray[0]) {
                return rangeArray[0];
            }
            return 0;
        },
        hidePicker() {
            if(this.showPicker) {
                this.$emit('onChange');
            }
            this.showPicker = false;
        },
    }
}
</script>