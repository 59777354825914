<template>
  <div class="bg-primary-three shadow-one rounded-sm settings-widget py-4 px-5 font-poppins">
    <SettingsHeader
      class="mb-5"
      title="Metric Configuration"
    />

    <div class="flex flex-col gap-5">
      <InputSelect
          v-model="metric"
          :items="[{id: '', title: 'Metrics'}]"
      />
      <InputSelect
          v-model="compare"
          :items="[{type: 1, title: 'Default Style'}, {type: 2, title: 'Compare Parameter'}, {type: 3, title: 'Definition Parameter'}]"
          :value="widget.type"
          :value-key="'type'"
          @input="toggleWidgetType"
      />
    </div>

    <hr class="border-secondary-four mt-5">

    <div class="space-y-2 mt-5">
      <div v-for="(parameter, pIndex) in widget.parameters" :key="pIndex">
          <SettingsAccordion
              :title="`Parameter ${pIndex + 1}`"
              v-model="parameter.active"
          >
              <template v-slot:action>
                  <img src="/images/icons/brainstormV2/plus_circle_outline.svg" v-if="(pIndex + 1) === widget.parameters.length" alt="icon" class="cursor-pointer i-w-14 i-h-14" @click="newParameter" />
                  <img src="/images/icons/brainstormV2/close.svg" alt="icon" class="i-w-14 i-h-14 ml-2 cursor-pointer" @click="deleteParameter(pIndex)" />
              </template>
              <div class="flex flex-col gap-2">
                <InputSelect
                    v-model="parameter.property_id"
                    label="Select Property Set"
                    :items="module.properties"
                />
                <InputSelect
                    v-if="parameter.property_id"
                    v-model="parameter.id"
                    label="Select Parameter"
                    :items="getPropertyCollection(parameter)"
                    @change="updateWidgetSettings()"
                />
              </div>
          </SettingsAccordion>
      </div>
    </div>

    <div class="mt-6 hidden">
      <div class="flex justify-between">
        <h4 class="fs-10 fw-500 text-primary-one">Levels</h4>
        <h4 class="fs-10 fw-500 text-primary-one">Avg. Value</h4>
      </div>
      <div v-for="(definitionStyle, index) in widget.definition_style" :key="index" class="flex justify-between items-center mt-2" :class="{'mt-4': index > 0}">
        <div class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center mr-5 cursor-pointer"
        :style="{'background': definitionStyle.bg}">{{ definitionStyle.range }}</div>
        <div class="flex justify-between">
          <div class="value-identifier shadow-two" :style="{'background': definitionStyle.bg}"></div>
          <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 2 ? definitionStyle.bg : ''}"></div>
          <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 4 ? definitionStyle.bg : ''}"></div>
          <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 6 ? definitionStyle.bg : ''}"></div>
          <div class="value-identifier shadow-two ml-3" :style="{'background': getMinRange(definitionStyle.range) >= 8 ? definitionStyle.bg : ''}"></div>
        </div>
        <div class="range-section fs-8 text-primary-three shadow-two rounded-sm flex justify-center items-center ml-5" :style="{'background': definitionStyle.bg}">
          <input type="text" class="bg-transparent definition-section text-center" v-model="definitionStyle.definition" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteWidget } from '@/store/modules/roadmap/services';
import SettingsHeader from '@/components/roadmap/canvas/widgets/SettingsHeader';
import SettingsAccordion from '@/components/roadmap/canvas/widgets/SettingsAccordion';
import InputSelect from '@/elements/inputs/InputSelect';

export default {
  name: "CompareSettings",
  props: ['widget', 'layout', 'module', 'moduleHeight'],
  components: {
    InputSelect,
    SettingsHeader,
    SettingsAccordion
  },
  created() {
    if(this.widget.parameters.length === 0) {
      this.widget.parameters = [{ property_id: null, id: null }];
    }
    this.$emit('setSettingsTrue');
  },
  data() {
    return {
      metric: '',
      compare: 2,
      rangeDefinitions: ['0-2', '2-4', '4-6', '6-8', '8-10']
    }
  },
  methods: {
    getMinRange(rangeText) {
      let rangeArray = rangeText.split("-");
      if(rangeArray[0]) {
        return rangeArray[0];
      }
      return 0;
    },
    updateWidgetSettings() {
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    newParameter() {
      this.widget.parameters.push({ property_id: null, id: null });
    },
    deleteParameter(index) {
      this.widget.parameters.splice(index, 1);
      this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
      if(this.widget.parameters.length <= 0) {
        this.widget.parameters = [{ property_id: null, id: null }];
      }
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    toggleWidgetType(value) {
      this.widget.type = value;
      this.widget.settings = true;
      this.updateWidgetSettings();
    },
    getPropertyCollection(parameter) {
      let properties = this.module.properties.find(item => item.id === parameter.property_id)
      if(properties) {
        return properties.property_collections.filter(param => !param.option || param.option == 1)
      }
      return []
    }
  }
}
</script>

<style scoped>
.settings-widget {
  width: 250px;
  min-height: 624px;
  max-height: 724px;
  overflow-y: auto;
}
.settings-icon {
  width: 14px;
  height: 14px;
}
.range-section {
  width: 47px;
  height: 14px;
}
.definition-section {
  width: 47px;
}
.value-identifier {
  width: 6px;
  height: 6px;
  border-radius: 1px;
  background: #8D8D8D;
}
.param-dropdown:focus {
  border: 1px solid #C9C9C9;
}
.delete-icon {
  width: 17px;
  height: 15px;
}
</style>
