<template>
  <div>
    <div class="flex mt-9">
      <img src="/images/icons/build/navigation.svg" alt="icon"
           class="i-menu-icon cursor-pointer opacity-50 hover:bg-secondary-three"
           :class="{'opacity-100': activePanel === 1}"
           @click="clickNavigation($event), $emit('resetZoom')" />
<!--      <img src="/images/icons/build/global_library.svg" alt="icon"-->
<!--           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"-->
<!--           :class="{'opacity-100': activePanel === 2}"-->
<!--           @click="activePanel = 2, closeConcept($event), $emit('resetZoom')" />-->
      <img src="/images/icons/build/properties.svg" alt="icon"
           class="i-menu-icon cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': activePanel === 2}"
           @click="navigateToProperty(activeData)"/>

      <img src="/images/icons/brainstorm/TagIcon.svg" alt="icon"
           class="i-menu-icon w-6 cursor-pointer opacity-50 i-ml-30 hover:bg-secondary-three"
           :class="{'opacity-100': activePanel === 3}"
           @click="activePanel = 3"/>

    </div>

    <!--components-->
    <div>
      <item-list v-if="activePanel === 1" :isolatedModules="isolatedModules" :isolatedModuleId="isolatedModuleId"
                 @toggleIsolation="toggleIsolation"
                 @moduleIsolation="moduleIsolation" />
<!--      <global-list v-if="activePanel === 2" />-->
      <property v-if="activePanel === 2" @loadAModuleData="loadAModuleData" />
      <attached-tags class="overflow-auto mt-10" style="height: 900px" v-if="activePanel === 3" />

    </div>
  </div>
</template>

<script>
import ItemList from "./ItemList.vue";
// import GlobalList from "./GlobalList";
import Property from "./Property.vue";
import {mapGetters} from "vuex";
export default {
  name: "LeftPart",
  components: {ItemList, Property},
  props: ['navigationClicked', 'isolatedModules', 'isolatedModuleId'],
  data() {
    return {
      activePanel: 1,
    }
  },
  computed: {
    ...mapGetters({
      activeData: "programmatic/activeScenarioCollection",
    })
  },
  methods: {
    clickNavigation(event) {
      if(this.activePanel !== 1) {
        this.activePanel = 1;
      } else {
        this.$emit("clickNavigation");
      }
    },
    navigateToProperty(activeData) {
      if(this.activeData && this.activeData.id) {
        this.activePanel = 2;
      } else {
        alert('Please select an item or group');
      }
    },
    toggleIsolation(item) {
      this.$emit("toggleIsolation", item);
    },
    moduleIsolation(moduleId) {
      this.$emit("moduleIsolation", moduleId);
    },
    loadAModuleData(moduleId) {
      this.$emit("loadAModuleData", moduleId);
    }
  }
}
</script>

<style scoped>

</style>
