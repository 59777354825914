<template>
  <div class="relative font-poppins">
     <div class="h-6">
       <div class="flex" v-show="isExpanded">
        <h4
          v-for="(year, index) in year" :key="index"
          class="fs-10 fw-500 text-secondary-two"
          :class="{'i-ml-355': index !== 0}"
        >Timeline: <span class="text-primary-one">{{ index+1 }} Year View {{ firstYear ? (firstYear + index) : '' }}</span></h4>
      </div>
    </div>
    <div
      class="group-shadow-bg bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm"
      :class="{'w-12': !isExpanded}"
    >
      <CollapseHandle :isExpanded="isExpanded" class="absolute" :handler="handleCollapse"/>
      <div @click="toggleYear" class="group-setting flex justify-center items-center bg-primary-three shadow-one rounded-sm cursor-pointer i-border-1 border-secondary-five"></div>
    </div>
    <CollapsedBar
      :is-expanded="isExpanded"
      title="Timeline:"
      :widget="widget"
    />

    <!-- <div
      v-if="!isExpanded"
      class=" empty-widget-bar w-12 bg-primary-two i-pl-5 i-pr-5 i-pt-5 i-pb-5 rounded-sm mt-8 m-h-220 minimized-bar">
      <div class="h-full relative w-full bg-white shadow-two border-secondary-five border border-solid rounded-sm">
        <VerticalText
          title="Timeline:"
          :subTitle="widget.title"
        />
      </div>
    </div> -->
    <div
      v-if="!settings && isExpanded"
      class="relative rounded-sm default-style dashed-border mt-8"
      :class="{'no-border': widget.parameters.length > 0 && widget.parameters[0].id, 'bg-primary-two': activeBg}"
      :style="{'width': getWidth + 'px'}"
    >
        <GraphLayout :years="years">
            <div class="p-2">
                <div
                    class="flex flex-col progress"
                    v-for="(progress, progressIndex) in localFilteredCollections" :key="progress.id"
                    :class="[ progressIndex !== 0 ? getProgressGap(progress, progressIndex) : '' ]"
                >
                    <ProgressBar
                        :style="{visibility: _getItemVisibility(progress) ? 'visible' : 'hidden'}"
                        :progress-width="getProgressAmount(progress)"
                        :width="getProgressWidth(progress)"
                        :margin-left="getProgressMarginLeft(progress)"
                        :background="getProgressBackground(progress)"
                    />
                </div>
            </div>
        </GraphLayout>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import VerticalText from '@/components/roadmap/canvas/widgets/VerticalText'
import CollapsedBar from '@/components/roadmap/canvas/widgets/CollapsedBar'
import CollapseHandle from '@/components/roadmap/canvas/widgets/CollapseHandle'
import ProgressBar from '@/components/roadmap/canvas/widgets/timeline/component/ProgressBar.vue';
import GraphLayout from '@/components/roadmap/canvas/widgets/timeline/component/GraphLayout.vue';
import { getBackground, progress, getTheGap, deleteWidget, getItemVisibility} from '@/store/modules/roadmap/services';
import { startDateOfYear, endDateOfYear, differenceInCalendarYears, isDate} from '@/plugins/lodash.js';
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export default {
  name: "Timeline",
  components: {
    CollapseHandle,
    // VerticalText,
    CollapsedBar,
    ProgressBar,
    GraphLayout
},
  props: ['module', 'filteredCollections', 'layout', 'widget', 'flatData', 'isolatedModules'],
  async created() {
    this.systemData = this.module;
    this.systemData.child = this.systemData.collections;
    this.moduleHeight = document.getElementById(`div_content_${this.layout.id}_${this.module.id}`).clientHeight;
  },
  data() {
    return {
      currentPeopleIndex: 0,
      isExpanded: true,
      parentHeight: 0,
      settings: false,
      moduleHeight: 132,
      systemData: [],
      peoples: [],
      year: 1,
      quatre: ['Q1', 'Q2', 'Q3', 'Q4'],
      pixelWidth: 1.3
    };
  },
  watch: {
    layout: {
      handler: function() {
        this.moduleHeight = document.getElementById(`div_content_${this.layout.id}_${this.module.id}`).clientHeight;
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      activeRoadmapData: "roadmap/programmatic",
      modules: "programmatic/projectModules",
      colorCodes: "global/colorCodes",
      parameterId: 'roadmap/parameterId'
    }),
    years() {
        let data = [];
        for(let x = 0; x < this.year; x++) {
            months.forEach(month => data.push(month))
        }
        return data;
    },
    quatres() {
        let data = [];
        for(let x = 0; x < this.year; x++) {
          this.quatre.forEach(item => data.push(item))
        }
        return data;
    },
    firstYear() {
      let d = new Date(this.startDate);
      if(!isDate(d)) return;
      return d.getFullYear()
    },
    startDate() {
      return startDateOfYear(this.localFilteredCollections.map(i => i.start_date))
    },
    endDate() {
      return endDateOfYear(this.localFilteredCollections.map(i => i.end_date))
    },
    localFilteredCollections() {
      return this.getData(this.filteredCollections);
    },
    getWidth() {
      return '490' * this.year;
    },
    activeBg() {
      return !!(this.layout && this.layout.settings && this.layout.settings.color);
    },
    getModuleSettings() {
      let module = this.modules.find(item => parseInt(item.id) === parseInt(this.module.id));
      if (module) {
        return JSON.parse(module.collection_settings);
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
        getPeoples: "people/getPeoples"
    }),
    getProgressWidth(item) {
        if(item.end_date && item.start_date) {
            let days = this.$lodash.dateDefer(item.end_date, item.start_date);
            let startFrom = this.$lodash.dateDefer(item.start_date, this.startDate);
            return Math.round(days * this.pixelWidth);
        }
    },
    getProgressMarginLeft(item) {
        if(item.end_date && item.start_date) {
            let startFrom = this.$lodash.dateDefer(item.start_date, this.startDate);
           return Math.ceil(startFrom * this.pixelWidth);
        }
    },
    getProgressBackground(item) {
        let parameterId = this.widget.parameters[0].id;
        if(!parameterId) {
            return '';
        }
        let parameter = item.properties?.find(i => i.property_collection_id == parameterId);
        if(!parameter) {
            return '';
        }
        return parameter ? getBackground(this.widget, parameter.sample_value): ''
    },
    getProgressAmount(item) {
        let parameterId = this.widget.parameters[0].id;
        if(!parameterId) {
            return '0';
        }
        let parameter = item.properties?.find(i => i.property_collection_id == parameterId);
        if(!parameter) {
            return '0';
        }
        return progress(parameter)
    },
    getProgressGap(item, index) {
        return getTheGap(this.flatData, item, index);
    },
    toggleYear() {
      let totalYear = differenceInCalendarYears(this.startDate, this.endDate);
      if(!totalYear) return;
      if(this.year >= totalYear) {
        this.year = 0;
      }
      this.year++
    },
    getData(items) {
      let moduleInIsolation = this.isolatedModules.find(item => item && item.module_id === this.module.id);
      if(moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id));
      } else if(this.layout.isolated_ids && this.layout.isolated_ids.length > 0) {
        return items.filter(data => this.layout.isolated_ids.includes(data.id) && !data.root);
      }
      return items;
    },
    getCollectionIds() {
      return this.filteredCollections.map(item => item.id);
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
    },
    getBg(item) {
       if (item && item.background_color) {
        return item.background_color;
      }
      let collectionSettings = this.getModuleSettings;
      if(collectionSettings && collectionSettings.collections.length > 0) {
        let colorCodes = this.getLevelWiseColorCode(item.level);
        return colorCodes[collectionSettings.collections[item.level].color];
      }
      return null;
    },
    setRootHeight(height) {
      this.parentHeight = height - 28;
    },
    handleCollapse() {
      this.isExpanded = !this.isExpanded;
    },
    async updateWidgetSettings() {
      await this.$store.dispatch("roadmap/updateWidgetSettings", this.widget);
    },
    getParameters(parameters) {
      if(parameters && parameters.length > 0) {
        return parameters;
      }
      return [];
    },
    deleteWidget() {
      this.$Progress.start();
      this.$store.dispatch("roadmap/deleteWidget", this.widget).then(response => {
        this.$Progress.finish();
        deleteWidget(this.widget.id, this.layout);
      });
    },
    _getItemVisibility(item) {
      return getItemVisibility(this.layout, item)
    }
  }
};
</script>

<style>
.grid-child {
    min-width: 40px;
    text-align: center;
}
.progress {
    position: relative;
    min-height: 20px;
}
.progress::after {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: #fff;
}
:root {
    --people-element-with: 63px;
}
.people-element-with {
  min-width: var(--people-element-with);
}
.quatre-width {
  min-width: 117px;
}
.btn-area {
  width: 100px;
  height: 20px;
  border-radius: 4px;
}
.logic-parameter-outline {
  float: left;
  width: 80px;
  margin-left: 4px;
  height: 20px;
  border-radius: 2px;
}
.logic-box-container {
  float: left;
  width: 80px;
  height: 20px;
}
.box-container-operator {
  width: 96px;
}
.default-style {
  /*min-width: 250px;*/
  width: 440px;
}
.group-shadow-bg {
  height: 50px;
}
.group-setting {
  height: 40px;
}
.widget-setting-icon {
  width: 13px;
  height: 13px;
}
.value-text-pos {
  top: -18px;
  left: 4px;
}
.dashed-border {
  border: 1px dashed #C9C9C9;
}
.no-border {
  border: none;
}
.fs-6 {
  font-size: 6px;
}
.none-margin {
  margin: 0;
}
</style>
