import store from '@/store'

export default class resizeObserver {
    constructor(element, title) {
        this.element = element
        this.title = title.toLowerCase()
    }

    observer() {
        const resizeObserver = new ResizeObserver(entries => {
            if(entries && entries.length) {
                let element_width = entries[0].contentRect.height
                store.commit('setModuleHeight', {height: element_width, title: this.title})
            }
        })
        resizeObserver.observe(this.element);
    }

}