import store from '@/store';
import { debounce } from 'lodash'
import { computed } from 'vue'
import { deleteWidget, parameterNextValue } from '@/store/modules/roadmap/services';

export default function useSettings() {
    const rangeDefinitions = ['0-2', '2-4', '4-6', '6-8', '8-10']
    const minRange = [0, 2, 4, 6, 8]
    const maxRange = [2, 4, 6, 8, 10]

    const definitionSets = computed(() => store.getters['definition/definitionSets'])
    const activeRoadmapData = computed(() => store.getters['roadmap/programmatic'])
    const activeData = computed(() => store.getters['programmatic/activeScenarioCollection'])

    const updateWidgetSettings = debounce((widget) => {
        store.dispatch("roadmap/updateWidgetSettings", widget);
    }, 500)

    const pickerPositionOffset = (currentIndex) => {
        return 487 + (30 * currentIndex)
    }
    const getParameterBg = (value, definitionStyles, serialIndex) => {
        if (value > 0) {
            value = value - .000001;
        }
        let valueIndex = Math.floor(value / 2);
        if (valueIndex > 4) {
            valueIndex = 4;
        }

        if (minRange[serialIndex] < value) {
            return definitionStyles[valueIndex].bg;
        }
        return null;
    }

    const updateParameterValue = (item, parameterId, value, cb) => {
        let collectionParam = parameterNextValue(item, parameterId, parseInt(value), activeRoadmapData.value);
        let itemParameter = item.properties?.find(data => parseInt(data.property_collection_id) === parseInt(parameterId));
        if (itemParameter) {
            itemParameter.sample_value = collectionParam.value;
        } else {
            item.properties.push({
                scenario_collection_id: item.id,
                sample_value: collectionParam.value,
                property_collection_id: parameterId
            });
        }

        store.dispatch("programmatic/updatePropertyItem", collectionParam).then(response => {
            if (response && response.data && response.data.data && response.data.data.id) {
                if (!itemParameter) {
                    itemParameter = item.properties.find(data => parseInt(data.property_collection_id) === parseInt(parameterId));
                    if (itemParameter) {
                        itemParameter.id = response.data.data.id;
                    }
                }
                cb()
            }
        });

        if (activeData.value && activeData.value.id == item.id) {
            let dataObj = {
                property_collection_id: parameterId,
                sample_value: collectionParam.value
            };
            store.commit("programmatic/updateActiveItemParameterValue", dataObj);
        }
    }

    const getDataByIsolatedModules = (items, isolatedModules, module, layout) => {
        let moduleInIsolation = isolatedModules.find(item => item && item.module_id === module.id);
        if (moduleInIsolation && moduleInIsolation.isolate) {
            return items.filter(data => moduleInIsolation.isolatedIds.includes(data.id) && !data.root);
        } else if (layout.isolated_ids && layout.isolated_ids.length > 0) {
            return items.filter(data => layout.isolated_ids.includes(data.id) && !data.root);
        }
        return items;
    }

    return {
        definitionSets,
        rangeDefinitions,
        updateWidgetSettings,
        pickerPositionOffset,
        getParameterBg,
        updateParameterValue,
        getDataByIsolatedModules
    }
}