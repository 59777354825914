var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dots rounded-full cursor-pointer relative after:h-16",class:[
        {
            'bg-secondary-four border-secondary-four': !_vm.isSelected,
            'bl-after-none': _vm.config.borderLeft
        }
    ],style:({
        backgroundColor: _vm.isSelected ? _vm.background : null
    }),on:{"click":function($event){return _vm.$emit('onChange')}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.config.borderTopLeft && _vm.isSelected && _vm.config.hideBorder),expression:"config.borderTopLeft && isSelected && config.hideBorder"}],staticClass:"border-top-right"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:((_vm.config.isOpen  && _vm.isSelected)),expression:"(config.isOpen  && isSelected)"}],staticClass:"border-top-left"})])
}
var staticRenderFns = []

export { render, staticRenderFns }